import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import GlobalError from '@/components/system/error'
import { Layout } from 'antd'
import { CopyrightOutlined } from '@ant-design/icons'
import { setStore } from '@/utils/store'
import Header from './fullScreenHeader' // 头部导航栏
import { getStore } from '@/utils/store'
import { CopyrightTxt } from '@/config'
import PloyWs from '@/components/system/ployWs'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { pubCustomEvent } from '@/utils/tools'
import { useWidth } from '@/hooks'
import FullScreenRouter from './fullScreenRouter'
import './index.less' // 导入样式

const { Content } = Layout

// 递归路由
const getPageList = (list, path) => {
	let arr = []
	list.forEach(item => {
		let linItem = {
			name: item.name,
			label: item.label,
			path: item.path,
			component: item.component,
			type: item.type,
		}
		if (item.path === path) {
			arr.push(linItem)
		} else if (item.children && item.children.length !== 0) {
			let linArr = getPageList(item.children, path)
			if (linArr.length !== 0) {
				arr.push(linItem)
				arr.push(...linArr)
			}
		}
	})
	return arr
}

const LayoutPage = props => {
	const [collapsed, setCollapsed] = useState(false) // 菜单栏展开收起控制
	const [pageStyle, setPageStyle] = useState(getStore('pageStyle')) // 菜单栏展开收起控制
	const [pageData, setPageData] = useState([]) // 面包屑路由
	const history = useHistory()
	const { pathname } = useLocation()
	const [robotData, setRobotData] = useState({})
	const [isCheck, setIsCheck] = useState(false)
	const size = useWidth()
	// 监听路由变化，同步菜单栏选中项
	useEffect(() => {
		let routerData = JSON.parse(getStore('routerData') || JSON.stringify([]))
		let userInfo = JSON.parse(getStore('userInfo') || JSON.stringify({}))
		if (Number(userInfo.bind_robot) === 0) {
			setRobotData({
				url: userInfo.unbind_url,
				code: userInfo.robot_code,
				robot_code: userInfo.robot_code,
				robot_url: userInfo.robot_url,
			})
		} else {
			setRobotData({
				url: userInfo.unbind_url,
				robot_code: userInfo.robot_code,
				robot_url: userInfo.robot_url,
			})
		}
		// 菜单栏选中与展开项目
		const onSetMenu = (linPageData, e) => {
			let defaultOpenKeys = []
			if (linPageData.length > 1 && !collapsed) {
				linPageData.forEach((item, index) => {
					if (index !== linPageData.length - 1) {
						defaultOpenKeys.push(item.name)
					}
				})
			}
		}
		// 更新页面数据
		const onUpDataPageData = () => {
			let linPageData = getPageList(routerData, pathname)
			onSetMenu(linPageData, pathname)
			setPageData(linPageData)
		}
		onUpDataPageData()
	}, [pathname, collapsed])

	useEffect(() => {
		PloyWs(history.push)
		if (pageStyle == 'dark') {
			loadStyles()
		} else {
			removeStyles('/antd.dark.css')
			removeStyles('/dark.css')
		}
		return () => {}
	}, [])

	// 跳转
	const onNavgation = (e, label, component, item) => {
		let routerTabArr = JSON.parse(getStore('routerTabArr')) || []
		let flag = routerTabArr.every(v => v.component != component)
		// 首页不需要发生变化 需要一直保持第一位
		if (flag) {
			const home = routerTabArr[0]
			routerTabArr.splice(0, 1)
			routerTabArr.unshift({
				url: e.key,
				name: label,
				component: component,
				icon: item?.icon,
			})
			routerTabArr.unshift(home)
		}
		// 发送全部tab的数据
		pubCustomEvent({ routerTabArr }, 'routerTab')
		// 发送当前选中的数据key
		pubCustomEvent({ key: component, url: e.key }, 'routerTabKey')
		setStore('routerTabArr', JSON.stringify(routerTabArr))
		// history.push(e.key)
	}

	const onUbBind = () => {
		let userInfo = JSON.parse(getStore('userInfo') || JSON.stringify({}))
		userInfo.bind_robot = 0
		setStore('userInfo', JSON.stringify(userInfo))
		setRobotData({
			url: userInfo.unbind_url,
			code: userInfo.robot_code,
			robot_code: userInfo.robot_code,
			robot_url: userInfo.robot_url,
		})
	}
	const onCheckStyle = () => {
		let dets = pageStyle === 'dark' ? 'light' : 'dark'
		if (dets == 'dark') {
			loadStyles()
		} else {
			removeStyles('/antd.dark.css')
			removeStyles('/dark.css')
		}
		setStore('pageStyle', dets)
		setPageStyle(dets)
	}
	function loadStyles() {
		let link = document.createElement('link')
		link.rel = 'stylesheet'
		link.type = 'text/css'
		link.href = '/antd.dark.css' //引入的文件名
		document.getElementsByTagName('head')[0].appendChild(link)
		let link1 = document.createElement('link')
		link1.rel = 'stylesheet'
		link1.type = 'text/css'
		link1.href = '/dark.css' //引入的文件名
		document.getElementsByTagName('head')[0].appendChild(link1)
	}
	///移除平板样式文件
	function removeStyles(filename) {
		let targetelement = 'link'
		let targetattr = 'href'
		let allsuspects = document.getElementsByTagName(targetelement)
		for (let i = allsuspects.length; i >= 0; i--) {
			if (
				allsuspects[i] &&
				allsuspects[i].getAttribute(targetattr) != null &&
				allsuspects[i].getAttribute(targetattr).indexOf(filename) != -1
			) {
				allsuspects[i].parentNode.removeChild(allsuspects[i])
			}
		}
	}

	if (size == 'small') {
		document.body.removeAttribute('class', 'large_table_fixed')
		document.body.setAttribute('class', 'small_table_fixed')
	} else {
		document.body.removeAttribute('class', 'small_table_fixed')
		document.body.setAttribute('class', 'large_table_fixed')
	}
	return (
		<Layout className='layout_c_page card_background'>
			{/* 侧边菜单栏 */}
			{/* <Sider
				collapsed={collapsed}
				onBreakpoint={e => setCollapsed(e)}
				menuData={menuData}
				onOpenChange={onChangeOpenMenu}
				onNavgation={onNavgation}
				pageStyle={pageStyle}
			/> */}
			{/* 侧边菜单栏 end */}

			{/* 右边 */}
			<Layout className='site-layout card_background'>
				{/* 头部导航栏 */}
				{!isCheck && (
					<Header
						onUbBind={onUbBind}
						collapsed={collapsed}
						robotData={robotData}
						onSetCollapsed={e => setCollapsed(e)}
						pageData={pageData}
						onNavgation={onNavgation}
						onCheckStyle={onCheckStyle}
						pageStyle={pageStyle}
					/>
				)}
				{/* 头部导航栏 -end */}
				<Content
					id='main_canyao'
					className='site-layout-background card_background'
					style={{
						margin: '8px',
						padding: '16px 0px 16px 16px',
						minHeight: 280,
						marginBottom: 10,
						height: 'calc(100vh - 150px)',
						overflowY: 'auto',
						// overflow: 'hidden',
					}}
				>
					<GlobalError {...props}>
						<FullScreenRouter />
					</GlobalError>
				</Content>
				<div
					style={{
						textAlign: 'center',
						color: '#9195A3',
						fontSize: 12,
						paddingBottom: '5px',
					}}
				>
					<CopyrightOutlined />
					{CopyrightTxt}
				</div>
				<ToastContainer />
			</Layout>
		</Layout>
	)
}

export default LayoutPage
