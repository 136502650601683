import { useState, useEffect } from 'react'
import { Form, Input, Button, Row, Col, message, InputNumber } from 'antd'
import {
	UserOutlined,
	LockOutlined,
	CopyrightOutlined,
} from '@ant-design/icons'
import md5 from 'md5'
import { useHistory } from 'react-router-dom'
import { req } from '@/utils/request'
import { setStore } from '@/utils/store'
import { setRouter } from '@/utils/routerCode'
import { CopyrightTxt } from '@/config'
import './index.less'
// 表单栅格样式
const layoutsCss = {
	labelCol: { span: 6 },
	wrapperCol: { span: 17 },
}
// 账号密码登录
const UserLogin = ({ onSuccess, editPhone }) => {
	const [loading, setLoading] = useState(false)
	const [UserLoginForm] = Form.useForm()
	useEffect(() => {
		UserLoginForm &&
			UserLoginForm.setFieldsValue({
				name: editPhone,
			})
	}, [])
	const onFinish = values => {
		let sub = {
			pwd: md5(values.password),
			name: values.name,
		}
		if (loading) return
		setLoading(true)
		req('login', sub, (res, isFlag, msg) => {
			if (isFlag) {
				if ((res.result.menu || []).length === 0) {
					setLoading(false)
					message.warning('您的账号暂无权限，请联系管理员！')
					return
				}
				onSuccess(res)
			} else {
				setLoading(false)
				message.warn(msg)
			}
		})
	}

	const onFinishFailed = errorInfo => {}
	return (
		<Form
			form={UserLoginForm}
			name='basic'
			wrapperCol={{
				sm: {
					span: 22,
					offset: 1,
				},
			}}
			initialValues={{
				remember: true,
			}}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
		>
			<Form.Item
				name='name'
				rules={[
					{
						required: true,
						message: '请输入登录账号',
					},
				]}
			>
				<Input
					placeholder='请输入登录账号'
					style={{ height: 45 }}
					autoComplete='new-password'
					size='large'
					prefix={
						<UserOutlined
							style={{ color: 'rgb(204, 204, 204)', fontSize: 18 }}
						/>
					}
				/>
			</Form.Item>
			<Form.Item
				name='password'
				rules={[
					{
						required: true,
						message: '请输入登录密码',
					},
				]}
			>
				<Input.Password
					type='password'
					style={{ height: 45 }}
					name={editPhone ? 'ceshi' : ''}
					placeholder='请输入登录密码'
					autoComplete={editPhone ? 'new-password' : 'on'}
					size='large'
					prefix={
						<LockOutlined
							style={{ color: 'rgb(204, 204, 204)', fontSize: 18 }}
						/>
					}
				/>
			</Form.Item>

			<Form.Item
				wrapperCol={{
					sm: {
						span: 22,
						offset: 1,
					},
				}}
			>
				<Button
					loading={loading}
					size='large'
					style={{
						height: 45,
						backgroundColor: '#096dd9',
						borderColor: '#096dd9',
						letterSpacing: 8,
					}}
					type='primary'
					htmlType='submit'
					block
				>
					登录
				</Button>
			</Form.Item>
		</Form>
	)
}

// 找回密码（验证码登录）
const PhoneLogin = ({ onSuccess }) => {
	const [form] = Form.useForm()
	const [canSend, setCanSend] = useState(false)
	const [loading, setLoading] = useState(false)
	const [time, setTime] = useState(0)
	const onFinish = value => {
		if (loading) return

		if (value.newPwd !== value.pwd) {
			message.error('两次密码不一致！')
			return
		}
		setLoading(true)
		req(
			'forgetPass',
			{
				pwd: md5(value.pwd),
				code: value.code,
				phone: value.phone,
			},
			(res, isFlag, msg) => {
				if (isFlag) {
					message.success('密码修改成功，请登录! ')
					onSuccess(value.phone)
				} else {
					setLoading(false)
					message.error(msg)
				}
			}
		)
		// req('codeLogin', values, (res, isFlag, msg) => {
		// 	if (isFlag) {
		// 		if ((res.result.menu || []).length === 0) {
		// 			setLoading(false)
		// 			message.warning('您的账号暂无权限，请联系管理员！')
		// 			return
		// 		}
		// 		onSuccess(res)
		// 	} else {
		// 		setLoading(false)
		// 		message.warn(msg)
		// 	}
		// })
	}

	// 定时器
	useEffect(() => {
		if (time !== 0) {
			setTimeout(() => {
				setTime(time - 1)
			}, 1000)
		}
	}, [time])

	// 发送短信
	const onSend = () => {
		if (loading || time !== 0) return
		// if (!!!form?.getFieldValue('phone')) {
		// 	message.warning('请输入账号')
		// 	return
		// }
		setLoading(true)
		req(
			'sendCode',
			{
				phone: form.getFieldValue('phone'),
				type: 1,
			},
			(_, isFlag, msg) => {
				setLoading(false)
				if (isFlag) {
					setTime(60)
					message.success('短信发送成功，请注意查收')
				} else {
					message.warn(msg)
				}
			}
		)
	}
	const onChangeForm = e => {
		setCanSend(e.target.value.length === 11)
	}
	return (
		<Form name='editPassword' form={form} {...layoutsCss} onFinish={onFinish}>
			<Form.Item
				name='phone'
				label='账号'
				rules={[
					{
						required: true,
						message: '请输入登录账号',
					},
				]}
			>
				<Input
					placeholder='请输入登录账号'
					style={{ height: 45 }}
					size='large'
					onChange={onChangeForm}
				/>
			</Form.Item>
			<Form.Item
				name='code'
				rules={[
					{
						required: true,
						message: '请输入短信验证码',
					},
					{
						len: 6,
						message: '请输入六位数短信验证码',
					},
				]}
				wrapperCol={{ span: 18, offset: 6 }}
			>
				<Row>
					<Col span={13}>
						<InputNumber
							controls={false}
							placeholder='请输入短信验证码'
							style={{ height: 45, width: '100%' }}
							size='large'
							autoComplete='new-password'
						/>
					</Col>
					<Col span={9} style={{ marginLeft: 8 }}>
						<Button
							loading={loading}
							onClick={onSend}
							disabled={!canSend || time !== 0}
							style={{ height: 45, width: '100%' }}
						>
							{time === 0 ? `发送验证码` : `${time}秒后重发`}
						</Button>
					</Col>
				</Row>
			</Form.Item>
			<Form.Item
				label='新密码'
				name='newPwd'
				rules={[{ required: true, message: '请输入新密码' }]}
			>
				<Input.Password
					style={{ height: 45 }}
					placeholder='请输入新密码'
					size='large'
					autoComplete='new-password'
					prefix={
						<LockOutlined
							style={{ color: 'rgb(204, 204, 204)', fontSize: 18 }}
						/>
					}
				/>
			</Form.Item>
			<Form.Item
				label='确认密码'
				name='pwd'
				rules={[{ required: true, message: '请输入密码' }]}
			>
				<Input.Password
					style={{ height: 45 }}
					placeholder='请输入密码'
					size='large'
					prefix={
						<LockOutlined
							style={{ color: 'rgb(204, 204, 204)', fontSize: 18 }}
						/>
					}
				/>
			</Form.Item>
			<Form.Item
				wrapperCol={{
					sm: {
						span: 22,
						offset: 1,
					},
				}}
			>
				<Button
					loading={loading}
					size='large'
					style={{
						height: 45,
						backgroundColor: '#096dd9',
						borderColor: '#096dd9',
						letterSpacing: 8,
					}}
					type='primary'
					htmlType='submit'
					block
				>
					登录
				</Button>
			</Form.Item>
		</Form>
	)
}

// 登录界面
const LoginPage = () => {
	const [type, setType] = useState(true)
	const [phone, setPhone] = useState(null)
	const history = useHistory()
	// 成功
	const onSuccess = value => {
		let data = setRouter(value.result.menu || [])
		if (data.length === 0) {
			message.warning('您的账号暂无权限，请联系管理员！')
			return
		}
		message.success('登录成功!')
		let cpResult = JSON.parse(JSON.stringify(value.result || {}))
		// 如果有不想放在本地存储的 提前删除即可
		// delete cpResult.xx
		let newObj = {}
		for (let key in cpResult) {
			if (typeof cpResult[key] !== 'object') {
				newObj[key] = cpResult[key]
			}
		}
		let user = JSON.stringify(newObj)
		setStore('userInfo', user)
		setStore('access_token', value.result.access_token)
		setStore('roles', JSON.stringify(value.result.role))
		setTimeout(() => {
			history.replace(getPath(JSON.parse(JSON.stringify(data))))
		}, 1000)
		// history.replace('/home');
	}
	const getPath = data => {
		let path = ''
		let item = data[0]
		if (item.children && item.children.length !== 0) {
			path = getPath(item.children)
		} else {
			path = item.path
		}
		return path
	}

	return (
		<div className='login_pages'>
			<div className='login_page_img' />
			<div
				className='login_page_contain'
				style={{ height: !type ? 550 : 420, width: !type ? 400 : 350 }}
			>
				<div className='login_page_logo'></div>
				<div className='login_page_title'>灿耀易客运营平台</div>
				{type ? (
					<UserLogin onSuccess={onSuccess} editPhone={phone} />
				) : (
					<PhoneLogin
						onSuccess={data => {
							setType(true)
							setPhone(data)
						}}
					/>
				)}
				<Row>
					<Col span={22}>
						<div onClick={() => setType(!type)} className='login_page_checkout'>
							{type ? '修改密码' : '使用账号密码登录'}
						</div>
					</Col>
				</Row>
			</div>
			<div
				style={{
					textAlign: 'center',
					color: '#9195A3',
					fontSize: 12,
					position: 'fixed',
					bottom: 0,
					width: '100%',
				}}
			>
				<CopyrightOutlined />
				{CopyrightTxt}
			</div>
		</div>
	)
}

export default LoginPage
