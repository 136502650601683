import { useEffect, useRef } from 'react'
const create = hook => (effect, dep) => {
	let isMounted = useRef(false)
	hook(() => () => (isMounted.current = false), [])
	hook(() => {
		if (!isMounted.current) {
			isMounted.current = true
		} else {
			return effect()
		}
	}, dep)
}
export default create(useEffect)
