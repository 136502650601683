// 兼容IE9
import 'core-js/es'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
// 兼容IE9 end
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'

const atndWarnings = [
	'Warning: [antd: Menu] `children` will be removed in next major version. Please use `items` instead.',
	'Warning: [antd: Modal] `visible` will be removed in next major version, please use `open` instead.',
	'Warning: [antd: Tabs] Tabs.TabPane is deprecated. Please use `items` directly.',
	'Warning: [antd: Drawer] `visible` is deprecated which will be removed in next major version, please use `open` instead.',
	'Warning: [antd: Steps] Step is deprecated. Please use `items` directly.',
]

const logErrors = console.error
console.error = function(...args) {
	const msg = args.join(' ')
	if (typeof msg === 'string' && atndWarnings.includes(msg)) return
	logErrors.apply(console, args)
}

ReactDOM.render(<App />, document.getElementById('root'))
