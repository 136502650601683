import React, { useMemo } from 'react'
import { noShowMenu } from './index'
import { useHistory } from 'react-router-dom'
import { getStore, isArr } from '@/utils'

const FullScreenTitle = () => {
	const history = useHistory()
	const titleCode = useMemo(() => {
		let RouterConfig = JSON.parse(getStore('routerData') || JSON.stringify([]))
		RouterConfig = RouterConfig.filter(v => noShowMenu.includes(v.name))
		let name
		RouterConfig.find(v => {
			if (isArr(v.children)) {
				v.children.forEach(k => {
					const _name = String(k.name).replace('/home', ''),
						name1 = history.location.pathname
					if (_name === name1 || String(k.path).replace('/home', '') === name1) {
						name = k.label
					}
				})
			}
		})
		document.title = name
		return name
	}, [history])
	return <div style={{ fontWeight: 600, fontSize: 20, marginLeft: 24 }}>{titleCode}</div>
}

export default FullScreenTitle
