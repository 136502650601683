/**
 * @module 全局消息拦截
 */
import React, { useState, useMemo, useEffect, useRef } from 'react'
import { Modal, message, Spin } from 'antd'
import { req, isArr } from '@/utils'
import { getStore, setStore } from '@/utils/store'
import MsgRight from './lookContent'
import { isDevOrProEnv } from '@/config'
import './index.less'

type dataItemType = {
	title: string
	notify_id: string
	add_time_str: string
}

const MsgModal = () => {
	const [dataList, setDataList] = useState<dataItemType[]>([])
	const [visible, setVisible] = useState(false)
	const [thatId, setThatId] = useState<string | null>(null)
	const [info, setInfo] = useState<any>(null)
	const [loading, setLoading] = useState(false)
	const msgRef: any = useRef(null)
	useEffect(() => {
		//从新加载之后 重新取出数据放入 数据源
		// init()
		isDevOrProEnv() === 'pro' && init()
		window.addEventListener('msgRemind', msgRemindChange)
		return () => {
			window.removeEventListener('msgRemind', () => {})
		}
	}, [])

	const init = () => {
		const _list = JSON.parse(getStore('msgReminds') ?? '[]')
		if (!isArr(_list)) return false
		setDataList(_list)
		_list[0] && reqInfo(_list[0]?.['notify_id'], _list)
		return true
	}

	const reqInfo = (id: string, arr: any[] = [], type = false) => {
		setLoading(true)
		const index = arr.findIndex(v => v?.['notify_id'] == id)

		const code = () => {
			const objs = arr[index + 1] ?? {}
			if (typeof objs === 'undefined' || !isArr(Object.keys(objs as {}))) {
				setVisible(false)
				setDataList([])
				setStore('msgReminds', '[]')
			} else {
				const __dataList = arr.filter(v => v.notify_id != id)
				setDataList(__dataList)
				reqInfo(objs?.['notify_id'], __dataList, type)
				!type && setVisible(true)
			}
		}

		req('businessNotifyInfo', { notify_id: id }, (res, isFlag, msg) => {
			setLoading(false)
			if (isFlag) {
				const _res = res?.result ?? {}
				setInfo(_res)
				// setVisible(true)
				setThatId(id)
				msgRef?.current?.clearIntervalTime()
				!type && msgRef?.current?.openTimes()
				!type && setTimeout(() => msgRef?.current?.reqSetRead(), 3000)
				// 已确认机制
				if (Array.isArray(_res) || _res?.confirm_state) {
					code()
				} else {
					setVisible(true)
				}
				// 下一条机制
				if (type) {
					// 如果遇到失败的 跳过
					// 如果遇到 已确认的跳过
					setStore('msgReminds', JSON.stringify(arr))
					msgRef?.current?.openTimes()
					msgRef?.current?.checkTime()
					setTimeout(() => msgRef?.current?.reqSetRead(), 3000)
				}
			} else {
				message.error(msg)
				type && code()
			}
		})
	}

	const msgRemindChange = e => {
		// 测试环境关闭
		if (isDevOrProEnv() !== 'pro') return
		const { detail } = e
		if (!isArr(Object.keys(detail))) return
		const { type, data } = detail
		// 接受三种状态 type
		// 1. 从概览传入的数据 直接存储 并唤起modal
		// 2. ws 传入的数据 重新获取接口 然后 获取数据 替换数据 并存储
		// 3. 切换 change tab 之后 唤起 modal
		if (type === 1) {
			setDataList(data ?? [])
			setStore('msgReminds', JSON.stringify(data ?? []))
			data[0] && reqInfo(data[0]?.['notify_id'], data)
			// isArr(data) && setVisible(true)
		} else if (type === 2) {
			reqList()
		} else if (type === 3) {
			init()
		}
	}

	const reqList = () => {
		req(
			'businessNotifyList',
			{ confirm_state: 0, num: 999, project_id: -1, level: 1 },
			(res, isFlag) => {
				if (isFlag) {
					setDataList(res?.result?.data ?? [])
					// 放入本地存储
					setStore('msgReminds', JSON.stringify(res?.result?.data ?? []))
				}
			}
		)
	}

	//  已读回调
	const throwChange = () => {
		// 计算下一条 然后销毁当前的这条数据 并获取数据详情
		const _dataList = JSON.parse(JSON.stringify(dataList)) as dataItemType[]
		const index = _dataList.findIndex(v => v?.['notify_id'] == thatId)
		if (!~index || index == _dataList?.length - 1) {
			setStore('msgReminds', '[]')
			setVisible(false)
			setDataList([])
		} else {
			const objs = _dataList?.[index + 1]
			const __dataList = _dataList.filter(v => v.notify_id != thatId)
			setDataList(__dataList)
			reqInfo(objs?.notify_id, __dataList, true)
			// setThatId(objs?.notify_id)
			// setStore('msgReminds', JSON.stringify(__dataList))
			// msgRef?.current?.openTimes()
			// setTimeout(() => msgRef?.current?.reqSetRead(), 3000)
		}
	}

	const Modals = useMemo(() => {
		const onCancel = () => {
			setVisible(false)
		}
		const ModalProps = {
			visible,
			title: '未读通知提醒',
			onCancel,
			footer: null,
			closable: false,
			maskClosable: false,
			keyboard: false,
		}
		return (
			<Modal {...ModalProps} width={1100}>
				<div className='lookMsg_box msgModal_box'>
					<div className='lookMsg_box_r msgModal_b_r' style={{ width: '100%' }}>
						{!!info && (
							<Spin spinning={loading}>
								<MsgRight
									ref={msgRef}
									info={info}
									type='modal'
									btnStr={`确认已读${
										// thatId == dataList.at(-1)?.['notify_id']
										thatId == dataList?.[dataList.length - 1]?.['notify_id']
											? ''
											: '，并打开下一条'
									}`}
									throwChange={throwChange}
								>
									{
										(!!dataList?.length &&
											`共 ${dataList.length} 条未确认通知`) as any
									}
								</MsgRight>
							</Spin>
						)}
					</div>
				</div>
			</Modal>
		)
	}, [dataList, visible, thatId, loading, info])

	return <>{Modals}</>
}
export default MsgModal
