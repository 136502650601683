/**
 * @module 路由tab的切换
 */

import React, { useState, useEffect, Suspense } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Tabs, Spin } from 'antd'
import TabMenu from './menu'
import TabMove from './tabMove'
import { getStore, setStore, removeStore } from '@/utils/store'
import { useUpdateEeffect, useStateBandCallback } from '@/hooks'
import RouteIcon from '@/components/system/RouteIcon'
import {
	getRouterInfo,
	defaultRouterTab,
	getUrlRouter,
	getDefaultOneOBJ,
	isArr,
	cloneDeep,
	pubCustomEvent,
} from '@/utils'
import { deepRouter } from '@/utils/routerPush'
import './index.less'
const { TabPane } = Tabs

export default ({ onUpdate }) => {
	const history = useHistory()
	const [data, setData] = useState([]) // tab的数据源
	const [tabKey, setTabKey] = useState('/Index') // 当前正在使用的组件的key
	const [refreshUrl, setRefreshUrl] = useState('') // 需要销毁组件的key 销毁下面的组件 不会销毁上边的tab栏
	const [, setRouterParams, getRouterParams] = useStateBandCallback() // 全部的路由参数 进行切换时 会取出对应的路由参数 并下发到路由state
	// const [, setParams, getParams] = useStateBandCallback() // 当前页面的路由参数 会通过全局state的方式进行下发到当前渲染的组件
	const { pathname } = useLocation() // search state
	// 在100ms后开始渲染组件 只有在强刷时会执行或登录过后第一次会触发 在这100ms中开始获取并分发路由参数
	// useEffect(() => {
	// 	let _timeout = setTimeout(() => {
	// 		setLoading(false)
	// 		console.log('执行')
	// 	}, 100)
	// }, [])
	useEffect(() => {
		// 监听页面刷新
		window.addEventListener('beforeunload', event => {
			removeStore('reqData')
		})

		// 监听需要删除的路由 并跳转到指定页面
		// window.addEventListener('delRouter', ({ detail }) => {
		// 	if (detail) {
		// 		onEdit(detail?.url, true, detail?.newUrl, data)
		// 	}
		// })
		// 获取本地储存的路由参数(10.24新增)
		try {
			let _rp = getStore('routerparams')
				? JSON.parse(getStore('routerparams'))
				: {}
			// let storeTabKey = getStore('routerTabKey')
			setRouterParams(_rp)
			// setParams(_rp[storeTabKey] ?? {})
		} catch (error) {
			console.log('routerparams', error)
		}

		// 获取本地的tab数组
		let arr = JSON.parse(getStore('routerTabArr') ?? '[]')
		init(defaultRouterTab(arr ?? []))
		// 获取本地存储的tabkey 并且在不等于的去判断 赋值
		let storeTabKey = getStore('routerTabKey')
		if (storeTabKey != tabKey) {
			setTabKey(storeTabKey ?? getDefaultOneOBJ()?.component ?? '/Index')
		}
		// 监听需要渲染的data和tabkey
		window.addEventListener('routerTab', routerTabArrChange)
		window.addEventListener('routerTabKey', tabKeyChange)
		return () => {
			window.removeEventListener('routerTab', () => {})
			window.removeEventListener('routerTabKey', () => {})
		}
	}, [])
	// 监听当前的tabkey进行本地存储
	useUpdateEeffect(() => {
		setStore('routerTabKey', tabKey)
		// (10.24新增)
		// setParams(getRouterParams()[tabKey] ? getRouterParams()[tabKey] : {})
		// window.r_state = getRouterParams()[tabKey] ?? {}
	}, [tabKey])
	// 监听data的数据
	useUpdateEeffect(() => {
		// 更新到本地
		setStore('routerTabArr', JSON.stringify(data))
	}, [data])
	// 监听头部url的变化 并且做生成新的tab  主要作为二级页面的处理
	useEffect(() => {
		const cp = JSON.parse(getStore('routerTabArr'))
		// 从接口返回的数据
		const info = getUrlRouter(pathname)
		// 如果找不到
		if (info == '/404') {
			history.push('/404')
			return
		}
		// 如果找的到
		// 如果和当前的数组的key进行 重复
		if (isArr(cp.filter(v => v.component == info?.component))) {
			setTabKey(info.component)
			return
		} else {
			// 如果和当前的不重复 跳转以及进行参数的调整
			let cpData = cloneDeep(cp)
			let home = cpData[0]
			// 获取本地的一些数据
			const newInfo = getRouterInfo(info.component)
			cpData.splice(0, 1)
			cpData.unshift({
				component: newInfo?.component,
				// name: newInfo.name,
				name: info?.label,
			})
			cpData.unshift(home)
			pubCustomEvent({ routerTabArr: cpData }, 'routerTab')
			setTabKey(newInfo?.component)
		}
		// window.r_state = getRouterParams()[tabKey] ?? {}
	}, [pathname])

	/**
	 * @function          routerTabArrChange   监听当前最新的tab数组的数据
	 * @param {e|object}  nodeEvent
	 */
	const routerTabArrChange = ({ detail }) => {
		init(detail?.routerTabArr ?? [])
	}
	/**
	 * @function          tabKeyChange   监听当前选中的tab数据发送
	 * @param {e|object}  nodeEvent
	 */
	const tabKeyChange = ({ detail }) => {
		// (10.24新增)
		if (!!detail?.state && Object.keys(detail?.state)?.length > 0) {
			let _routerParams = cloneDeep(getRouterParams()) ?? {}
			_routerParams[detail?.key] = detail?.state
			setRouterParams(_routerParams)
			setStore('routerparams', JSON.stringify(_routerParams ?? {}))
			// setParams(detail?.state)
			setTabKey(detail?.key ?? '')
			history.push({
				pathname: detail?.url,
				state: detail?.state,
			})
			// 跳转时如果发现跳转的页面存在 属于更换参数，那么就销毁当前的组件 重新挂载组件
			// 这样就不需要在跳转的页面监听参数了
			if (typeof detail?.flag == 'boolean' && !detail?.flag) {
				setRefreshUrl(detail?.key)
				setTimeout(() => setRefreshUrl(''), 50)
			}
		} else {
			setTabKey(detail?.key ?? '')
			history.push(detail?.url)
			// window.r_state = null
		}
	}
	/**
	 * @function          tabChange   监听当前选中的tab数据发送
	 * @param {string}    e           最新的tab选中key
	 */
	const tabChange = e => {
		setTabKey(e)
		pubCustomEvent({ type: 3 }, 'msgRemind')
		// todo 可能需要在此 增加在切换路由时 获取参数
		// setParams(getRouterParams()[e] ? getRouterParams()[e] : {})
		history.push({
			pathname: data.find(v => v.component == e)['url'],
			state: getRouterParams()[e] ? getRouterParams()[e] : {},
		})
	}
	/**
	 * @function          init        数据的清洗 获取对应的组件
	 * @param {Array}     arr         需要清洗的数据
	 * @param {boolean}   isTabKey    清洗之后 是否将第一条数据 变成选中的数据
	 */
	const init = (arr = [], isTabKey = true) => {
		setData([])
		let newArr = []
		if (isArr(arr)) {
			arr.forEach(v => {
				const info = !!v ? getRouterInfo(v?.component) : {}
				newArr.push({
					...info,
					...v,
				})
			})
		}
		// 清除在删除路由时或者路由不存在时 删除多余的路由参数(11.14)
		if (isArr(newArr)) {
			let _rp = getStore('routerparams')
				? JSON.parse(getStore('routerparams'))
				: {}
			//
			let new_cp = {}
			newArr = newArr.map(v => {
				for (let key in _rp) {
					if (v.component == key) {
						new_cp[key] = _rp[key]
					}
				}
				return v
			})
			setStore('routerparams', JSON.stringify(new_cp))
		}
		setData(newArr)
		setStore('routerTabArr', JSON.stringify(newArr))
		isTabKey && setTabKey(newArr[0]?.component ?? '')
	}
	/**
	 * @function          onEdit      删除tab
	 * @param {e|object}  nodeEvent
	 */
	const onEdit = e => {
		let cp = cloneDeep(data)
		let index = cp.findIndex(v => v.component == e)
		cp.splice(index, 1)
		setData(cp)
		setStore('routerTabArr', JSON.stringify(cp))
		// 进行tab的递减操作
		// 如果不需要递减 自定义跳转到某些页面
		const key = data[index - 1]['component']
		// const key = pushUrl ? pushUrl : data[index - 1]['component']
		// 需要获取需要跳转的 url
		history.push({
			pathname: data[index - 1]['url'],
			// pathname: pushUrl ? deepRouter(e)?.['path'] : data[index - 1]['url'],
			state: getRouterParams()[key] ? getRouterParams()[key] : {},
		})
		setTabKey(key)
		// 在删除某一个路由卡片时 看是否有路由参数 如果有就删除
		try {
			if (!!getRouterParams() && !!getRouterParams()[e]) {
				let _routerParams = cloneDeep(getRouterParams())
				delete _routerParams[e]
				setRouterParams(_routerParams)
				setStore('routerparams', JSON.stringify(_routerParams ?? {}))
			}
		} catch (error) {
			console.log('清除参数', error)
		}
	}

	// 删除当前的 tab 并跳转到新的 tab
	// url string  需要删除的路由 newUrl  需要跳转的 url isRefresh 跳转之后是否刷新
	const delClick = (url, newUrl, isRefresh = true) => {
		try {
			let cp = cloneDeep(data)
			let index = cp.findIndex(v => v.component == url)
			cp.splice(index, 1)
			let _objs = getRouterInfo(newUrl)
			let __objs = deepRouter(newUrl)
			if (cp.find(v => v.component === newUrl)) {
				// 如果有的话
				let _index = cp.findIndex(v => v.component == newUrl)
				cp.splice(_index, 1)
			}
			cp.push(_objs)
			setData(cp)
			setStore('routerTabArr', JSON.stringify(cp))
			history.push({
				pathname: __objs?.path,
			})
			setTabKey(_objs?.component)
			if (isRefresh) {
				setRefreshUrl(_objs.component)
				setTimeout(() => {
					setRefreshUrl('')
				}, 100)
			}
		} catch (error) {
			console.log('error', error)
		}
	}

	/**
	 * @function        throwChange   menu右键的操作回调
	 * @param {string}  type          取出对象的对应的操作
	 * @param {object}  obj           操作参数
	 */
	const throwChange = (type, obj) => {
		let TYPEOBJ = {
			key: () => setTabKey(obj?.key),
			data: () => init(obj?.data, false),
			url: () => setRefreshUrl(obj?.url),
		}
		TYPEOBJ[type] && TYPEOBJ[type]()
	}
	return (
		<Suspense fallback={null}>
			{/* <Suspense fallback={<Spin spinning={loading} />}> */}
			<TabMove tabKey={tabKey} onChange={tabChange} onEdit={e => onEdit(e)}>
				{data.map(
					(
						{ name, Component, component, closable = false, url, icon },
						index
					) => (
						<TabPane
							tab={
								index == 0 ? (
									<>
										<RouteIcon type={icon} /> <span>{name}</span>
									</>
								) : (
									<TabMenu
										item={{ url, component, name, icon }}
										tabKey={tabKey}
										data={data}
										locationName={pathname}
										throwChange={throwChange}
									/>
								)
							}
							key={component}
							closable={!closable}
						>
							{component != refreshUrl && (
								<Component
									// state={getParams() ?? {}}
									onUpdate={onUpdate}
									component={component}
									delClick={delClick}
								/>
							)}
						</TabPane>
					)
				)}
			</TabMove>
		</Suspense>
	)
}
