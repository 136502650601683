/**
 * @module 处理路由相关的函数
 */
import routers from '@/config/routerConfig'
import { isArr, cloneDeep } from '@/utils/tools'
import { getStore, setStore } from '@/utils/store'
import SysteamAdminRoute from '@/pages/Systeam/AdminRoute'

interface IDasItem {
	path: string
	children: Array<any>
	[s: string]: any
}

const getRouters = (data: IDasItem[], path: string) => {
	let das: Array<IDasItem> = []
	data.forEach((item: IDasItem) => {
		let items = item
		items.path = `${path}${item.path}`
		items.children = getRouters(items.children || [], items.path)
		das.push(items)
	})
	return das
}

export const setRouter = (e: IDasItem[]) => {
	let routerData = getRouters(e, '/home')
	setStore('routerData', JSON.stringify(routerData))
	return routerData
}

export const getRouterInfo = (key = '/Index') => {
	let _routers: IRouterItem[] = [
		...routers,
		{
			path: '/route',
			redirect: '',
			component: '/Systeam/AdminRoute',
			name: '权限页面管理',
			Component: SysteamAdminRoute,
		},
	]
	const data = _routers.find((v: { component: string }) => v.component == key)
	return data
}
// 获取一个默认的路由
export const defaultRouterTab = (arr = []) => {
	if (isArr(arr)) {
		return arr
	} else {
		let arr1 = cloneDeep(arr)
		let store = {}
		let newStore = JSON.parse(getStore('routerData') || '')[0]
		// 如果当前没有第一个或者没有路由
		if (Object.keys(newStore).length == 0) {
			store = {
				name: '概览',
				component: '/Index',
				url: '/home/home',
				closable: true,
			}
		} else {
			let newDefaultOBJ: any = deepDefaultOne(newStore)
			store = {
				name: newDefaultOBJ?.label ?? '概览',
				component: newDefaultOBJ?.component ?? '/Index',
				url: newDefaultOBJ?.path ?? '/home/home',
				closable: true,
				icon: newDefaultOBJ?.icon,
			}
		}
		arr1.unshift(store)
		setStore('routerTabArr', JSON.stringify(arr1))
		return arr1
	}
}
//获取第一个的数据
export const getDefaultOneOBJ = () => {
	let newStore = JSON.parse(getStore('routerData') || '')[0] ?? {}
	let newDefultOBJ = deepDefaultOne(newStore)
	return newDefultOBJ
}

// 递归获取默认的第一个
const deepDefaultOne = (data: IDasItem) => {
	let obj = {}
	if (isArr(data?.children)) {
		data.children.forEach(item => {
			if (isArr(item.children)) {
				obj == null && deepDefaultOne(item)
			} else {
				obj = data?.children[0] ?? data
			}
		})
	} else {
		obj = data
	}
	return obj || {}
}
// 递归 获取输入的url的值 并且获取组件的key
const deepForeach = (key: string) => {
	const stroeRouter = JSON.parse(getStore('routerData') || '') ?? []
	let info = null
	const Foreachs = (arr = []) => {
		arr.forEach((item: any) => {
			// 如果url和key相等 获取
			if (item?.path == key) {
				info = item
			} else {
				isArr(item?.children) && info == null && Foreachs(item?.children)
			}
		})
		return info
	}
	return Foreachs(stroeRouter) ?? '/404'
}
// 获取url的路由
export const getUrlRouter = (key = '/404') => {
	let data = deepForeach(key)
	return data
}
/**
 * @function                       删除路由
 * @param   {Array}         data   数组对象
 * @param   {number|string} val    需要删除的路由id或者路由名字
 * @param   {boolean}       radio  是否删除隐藏路由
 * @returns {Array}                新的路由数组
 */
export const delRouter = (
	data: any[],
	val: string,
	radio: boolean = false
): Array<any> | undefined => {
	if (!!val) {
		const isCode = (key: any) => {
			let cpData = JSON.parse(JSON.stringify(data))
			let cp = cpData.map((v: { id: any; children: any[] }) => {
				if (v.id == key) {
					v.children = !radio
						? v.children
								.map((k: { type: number }) => {
									if (k.type == 2) {
										return k
									}
								})
								.filter((j: any) => !!j && j)
						: []
				}
				return v
			})
			return cp
		}
		// 如果是数字
		if (Number(val) && !isNaN(Number(val))) {
			data = isCode(val)
		} else {
			// 如果是字符串
			// 获取父级
			let obj = data.find(
				(v: { label: any }) => String(v.label).indexOf(val) > -1
			)
			if (obj) {
				// 如果删除的是父级
				data = isArr(obj.children)
					? isCode(obj.id)
					: data.filter((v: { id: any }) => v.id != obj.id)
			} else {
				// 如果是删除的一个子级
				data = data.map((v: { children: any[] }) => {
					// 因为只有二级的菜单 那么只使用循环 三级使用递归
					if (isArr(v.children)) {
						v.children.map((pk: { label: string | any[] }, i: any) => {
							if (pk.label.indexOf(val) > -1) {
								v.children.splice(i, 1)
							}
							return pk
						})
					}
					return v
				})
			}
		}
		return data
	}
}
