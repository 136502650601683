import { useState, useEffect } from 'react'
import { req } from '@/utils/request'
import { getStore, setStore } from '@/utils/store'
import { deepCompare } from '@/utils/clone'

export default (api: string, params?: any, fn?: Function) => {
	const [data, setData] = useState<any>([])
	useEffect(() => {
		var _store: any = null
		let store = getStore('reqData') ? JSON.parse(getStore('reqData') || '') : []
		// 如果有当前的参数
		if (!!store) {
			// 找到对应的
			let obj = store?.find(v => v.api === api)
			let index = store?.findIndex(v => v.api === api)
			// 如果 请求参数或api 一样并data不等于null 可以直接替换之前的data
			if (!!obj && index != -1) {
				let isParams = deepCompare(obj?.params, params)
				// 如果一样直接替换data
				if (isParams) {
					setData(obj?.data)

					typeof fn == 'function' && fn(obj?.data)
				} else {
					// 不一样 重新请求
					req(api, params ?? {}, (res, isFlag, msg) => {
						if (isFlag) {
							obj = {
								api,
								params,
								data: res?.result ?? res?.result?.data,
							}
							setData(res?.result ?? res?.result?.data)
							typeof fn == 'function' && fn(res?.result?.data ?? res?.result)
							setStore(
								'reqData',
								JSON.stringify(
									store?.map((v, k) => {
										if (k === index) v = obj
										return v
									})
								)
							)
						} else {
							setData([])
							setStore(
								'reqData',
								store?.filter((_, k) => k != index)
							)
						}
					})
				}
			} else {
				// 如果找不到 那么的就直接请求接口
				req(api, params ?? {}, (res, isFlag, msg) => {
					if (isFlag) {
						obj = {
							api,
							params,
							data: res?.result ?? res?.result?.data,
						}
						setData(res?.result ?? res?.result?.data)
						typeof fn == 'function' && fn(res?.result?.data ?? res?.result)
						setStore(
							'reqData',
							JSON.stringify(
								index == -1
									? [...store, obj]
									: store?.map((v, k) => {
											if (k === index) v = obj
											return v
									  })
							)
						)
					}
				})
			}
		} else {
			// 如果没有该对象 进行数据存储
			req(api, params ?? {}, (res, isFlag, msg) => {
				if (isFlag) {
					_store = {
						api,
						params,
						data: res?.result ?? res?.result?.data,
					}
					setData(res?.result ?? res?.result?.data)
					typeof fn == 'function' && fn(res?.result?.data ?? res?.result)
					setStore('reqData', JSON.stringify([_store]))
				}
			})
		}
	}, [])
	return data
}
