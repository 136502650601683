import React, { useState, useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { getStore } from '@/utils/store'

export default (Component: any) => (props: any) => {
	const { location } = useHistory()
	const [isShow, setIsShow] = useState(true)
	const [data, setData] = useState<any>(location)
	useLayoutEffect(() => {
		// 获取路由参数  劫持当前的props 混入路由参数 然后下发！
		let arr = JSON.parse(getStore('routerTabArr') || '') ?? []
		setIsShow(!!arr.find((v: any) => v.component == props?.component))
		setData(location)
		return () => {
			// 在这里 清除 和当前组件相关的本地存储
			setIsShow(false)
		}
	}, [])

	return isShow ? <Component {...props} {...data} /> : ''
	// return <Component {...props} {...data} />
}
