/**
 * @module 路由tab的移动
 */

import { Tabs } from 'antd'
import React, { useRef, useState } from 'react'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { getStore, setStore } from '@/utils/store'
import { getDefaultOneOBJ } from '@/utils/routerCode'
import './index.less'
const type = 'DraggableTabNode'

// 插件默认的配置
const DraggableTabNode = ({ index, children, moveNode }) => {
	const ref = useRef(null)
	const [{ isOver, dropClassName }, drop] = useDrop({
		accept: type,
		collect: monitor => {
			const { index: dragIndex } = monitor.getItem() || {}
			if (dragIndex === index) {
				return {}
			}
			return {
				isOver: monitor.isOver(),
				dropClassName: 'dropping',
			}
		},
		drop: item => {
			moveNode(item.index, index)
		},
	})
	const [, drag] = useDrag({
		type,
		item: {
			index,
		},
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
	})
	drop(drag(ref))
	return (
		<div
			ref={ref}
			style={{
				marginRight: 24,
			}}
			className={isOver ? dropClassName : ''}
		>
			{children}
		</div>
	)
}
// 插件默认的配置
const DraggableTabs = props => {
	const { children } = props
	const [order, setOrder] = useState([])

	const moveTabNode = (dragKey, hoverKey) => {
		// 第一个不能拖动
		let newDefaultOBJ = getDefaultOneOBJ()
		if (
			hoverKey != newDefaultOBJ?.component &&
			dragKey != newDefaultOBJ?.component
		) {
			const newOrder = order.slice()
			React.Children.forEach(children, c => {
				if (c.key && newOrder.indexOf(c.key) === -1) {
					newOrder.push(c.key)
				}
			})
			const dragIndex = newOrder.indexOf(dragKey)
			const hoverIndex = newOrder.indexOf(hoverKey)
			newOrder.splice(dragIndex, 1)
			newOrder.splice(hoverIndex, 0, dragKey)
			setOrder(newOrder)
			try {
				// 算出拖动和被拖动的是那两个组件 进行数据互换 并存到本地存储
				let data = JSON.parse(getStore('routerTabArr'))
				let Dkey = data.findIndex(v => v.component == dragKey)
				let HKey = data.findIndex(v => v.component == hoverKey)
				let DOBJ = data.find(v => v.component == dragKey)
				data[Dkey] = data[HKey]
				data[HKey] = DOBJ
				setStore('routerTabArr', JSON.stringify(data))
			} catch (error) {
				console.log('er', error)
			}
		}
	}

	const renderTabBar = (tabBarProps, DefaultTabBar) => (
		<DefaultTabBar {...tabBarProps}>
			{node => (
				<DraggableTabNode
					key={node.key}
					index={node.key}
					moveNode={moveTabNode}
				>
					{node}
				</DraggableTabNode>
			)}
		</DefaultTabBar>
	)

	const tabs = []
	React.Children.forEach(children, c => {
		tabs.push(c)
	})
	const orderTabs = tabs.slice().sort((a, b) => {
		const orderA = order.indexOf(a.key)
		const orderB = order.indexOf(b.key)
		if (orderA !== -1 && orderB !== -1) {
			return orderA - orderB
		}
		if (orderA !== -1) {
			return -1
		}
		if (orderB !== -1) {
			return 1
		}
		const ia = tabs.indexOf(a)
		const ib = tabs.indexOf(b)
		return ia - ib
	})
	return (
		<DndProvider backend={HTML5Backend}>
			<Tabs
				className='tabs_router'
				renderTabBar={renderTabBar}
				type='editable-card'
				hideAdd
				tabBarGutter={-10}
				activeKey={props.tabKey}
				onChange={props.onChange}
				onEdit={props.onEdit}
				size='small'
			>
				{orderTabs}
			</Tabs>
		</DndProvider>
	)
}
// 主模块
export default props => (
	<DraggableTabs
		tabKey={props.tabKey}
		onChange={props.onChange}
		onEdit={props.onEdit}
	>
		{props.children}
	</DraggableTabs>
)
