import { useMemo } from 'react'
import { Menu, Layout } from 'antd'
import { getStore } from '@/utils/store'
import Icon from '@/components/system/RouteIcon'
import LogoIcons from '@/static/LOGO.svg'
import { delRouter } from '@/utils/routerCode'
const { Sider } = Layout
// 部分不需要 左边menu的路由

const SiderLayoutComponent = ({
	collapsed, // 判断菜单栏收起展开
	onBreakpoint, // 响应菜单栏展开收起
	menuData, // 菜单栏选中与展开的数据 defaultSelectedKeys defaultOpenKeys
	onOpenChange, // 菜单栏展开与收起事件
	onNavgation, // 跳转事件
	pageStyle = 'light',
}) => {
	// 菜单栏渲染
	const renderMenu = useMemo(() => {
		let RouterConfig = JSON.parse(getStore('routerData') || JSON.stringify([]))
		// 删除一些路由
		let userInfo = JSON.parse(getStore('userInfo') || JSON.stringify([]))
		if (!!!userInfo?.is_mcn_succ) {
			RouterConfig = delRouter(RouterConfig, 'vip达人')
		}
		// 删除部分不需要展示在sider上的路由
		// try {
		// 	RouterConfig = RouterConfig.filter(v => !noShowMenu.includes(v.name))
		// } catch (error) {
		// 	RouterConfig = JSON.parse(getStore('routerData') || JSON.stringify([]))
		// }
		// 菜单栏递归
		const renderMenuMap = data => {
			let ds = []
			data.forEach(item => {
				if (Number(item.type) === 1) {
					if (item.children && item.children.length !== 0) {
						if (!item.children.every(v => v.type == 2)) {
							ds.push(
								<Menu.SubMenu
									key={item.name}
									title={item.label}
									icon={<Icon type={item.icon} />}
								>
									{renderMenuMap(item.children)}
								</Menu.SubMenu>
							)
						}
					} else {
						// 清除如果父级下面没有权限看子集的页面时 不显示父级
						if (item.component !== '1') {
							ds.push(
								<Menu.Item
									id={item.name}
									key={item.path}
									icon={<Icon type={item.icon} />}
									onClick={e =>
										onNavgation(e, item.label, item.component, item)
									}
								>
									{item.label}
								</Menu.Item>
							)
						}
					}
				}
			})
			return ds
		}
		return renderMenuMap(RouterConfig)
	}, [])

	return (
		<Sider
			theme={pageStyle == 'dark' ? 'dark' : 'light'}
			breakpoint='lg'
			collapsed={collapsed}
			onBreakpoint={onBreakpoint}
			width={220}
			style={{
				overflowY: 'auto',
				height: '100vh',
			}}
		>
			{/* logo */}
			<div className='layoutLogo'>
				{(!collapsed && <img src={LogoIcons} style={{ width: '63%' }} />) || (
					<img
						alt=''
						src={require('@/static/logo.png')}
						className='layoutLogo_img'
					/>
				)}
				{/* <img
					alt=''
					src={require('@/static/logo.png')}
					className='layoutLogo_img'
				/>
				{!collapsed && <div className='layoutLogo_name'>灿耀易客</div>} */}
			</div>

			{/* 菜单栏 */}
			<Menu
				theme={pageStyle == 'dark' ? 'dark' : 'light'}
				mode='inline'
				selectedKeys={menuData.defaultSelectedKeys}
				openKeys={menuData.defaultOpenKeys}
				onOpenChange={onOpenChange}
			>
				{renderMenu}
			</Menu>
		</Sider>
	)
}

export default SiderLayoutComponent
