import React, { useMemo, useEffect, useState } from 'react'
import {
	LockOutlined,
	EllipsisOutlined,
	LogoutOutlined,
	SwapOutlined,
} from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { IconFont } from '@/config/settting'
import {
	Layout,
	Menu,
	Modal,
	Dropdown,
	message,
	Button,
	Form,
	Input,
	Row,
	Col,
	Popover,
	InputNumber,
} from 'antd'
import { clearStore, getStore, setStore } from '@/utils/store'
import phoneIcon from '@/static/passicon/phone.png'
import guluguluIcon from '@/static/gulugulu.svg'
import { copyTextFun } from '@/utils/tools'
import { reqConfirm, req } from '@/utils/request'
import Robot from '@/components/system/Robot'
import DownloadQueue from '@/components/system/DownloadQueue'
import md5 from 'md5'
import { useWidth } from '@/hooks'
import FullScreenTitle from './fullScreenTitle'
// 表单栅格样式
const layoutsCss = {
	labelCol: { span: 6 },
	wrapperCol: { span: 18 },
}

const EditPassword = ({ visible, onChange }) => {
	const [form] = Form.useForm()
	const [canSend, setCanSend] = useState(true)
	const [loading, setLoading] = useState(false)
	const [time, setTime] = useState(0)
	const [userInfo, setUSerInfo] = useState({})
	useEffect(() => {
		let userInfos = JSON.parse(getStore('userInfo') || JSON.stringify({}))
		setUSerInfo(userInfos)
	}, [])
	// 定时器
	useEffect(() => {
		if (time !== 0) {
			setTimeout(() => {
				setTime(time - 1)
			}, 1000)
		}
	}, [time])

	// 发送短信
	const onSend = () => {
		if (loading || time !== 0) return
		setLoading(true)
		req(
			'sendCode',
			{
				phone: userInfo.phone,
				type: 1,
			},
			(_, isFlag, msg) => {
				setLoading(false)
				if (isFlag) {
					setTime(60)
					message.success('短信发送成功，请注意查收')
				} else {
					message.warn(msg)
				}
			}
		)
	}

	const onCancel = () => {
		form.resetFields()
		onChange()
	}

	const onOk = () => {
		if (loading) return
		form.validateFields().then(value => {
			if (value.newPwd !== value.pwd) {
				message.error('两次密码不一致！')
				return
			}
			setLoading(true)
			req(
				'updatePass',
				{
					pwd: md5(value.pwd),
					code: value.code,
				},
				(res, isFlag, msg) => {
					if (isFlag) {
						message.success('密码修改成功，请重新登录! ')
						clearStore()
						setTimeout(() => {
							window.location.replace('/login')
						}, 1500)
					} else {
						setLoading(false)
						message.error(msg)
					}
				}
			)
		})
	}

	return (
		<Modal title='修改密码' visible={visible} onCancel={onCancel} onOk={onOk}>
			<Form name='editPassword' form={form} {...layoutsCss}>
				<Form.Item label='手机号'>{userInfo.phone}</Form.Item>
				<Form.Item
					name='code'
					rules={[
						{
							required: true,
							message: '请输入短信验证码',
						},
						{
							len: 6,
							message: '请输入六位数短信验证码',
						},
					]}
					wrapperCol={{ span: 18, offset: 6 }}
				>
					<Row>
						<Col span={14}>
							<InputNumber
								controls={false}
								placeholder='请输入短信验证码'
								style={{ height: 45, width: '100%' }}
								size='large'
								autoComplete='new-password'
							/>
						</Col>
						<Col span={1}></Col>
						<Col span={9}>
							<Button
								loading={loading}
								onClick={onSend}
								disabled={!canSend || time !== 0}
								size='large'
								style={{ height: 45, width: '100%' }}
							>
								{time === 0 ? `发送验证码` : `${time}秒后重发`}
							</Button>
						</Col>
					</Row>
				</Form.Item>
				<Form.Item
					label='新密码'
					name='newPwd'
					rules={[{ required: true, message: '请输入新密码' }]}
				>
					<Input.Password
						style={{ height: 45 }}
						placeholder='请输入新密码'
						size='large'
						autoComplete='new-password'
						prefix={
							<LockOutlined
								style={{ color: 'rgb(204, 204, 204)', fontSize: 18 }}
							/>
						}
					/>
				</Form.Item>
				<Form.Item
					label='确认密码'
					name='pwd'
					rules={[{ required: true, message: '请输入密码' }]}
				>
					<Input.Password
						style={{ height: 45 }}
						placeholder='请输入密码'
						size='large'
						prefix={
							<LockOutlined
								style={{ color: 'rgb(204, 204, 204)', fontSize: 18 }}
							/>
						}
					/>
				</Form.Item>
			</Form>
		</Modal>
	)
}

// 解绑机器人弹框
const RobotModal = ({ url, visible, onChange }) => {
	const [loading, setLoading] = useState(false)
	const onUnBind = () => {
		setLoading(true)
		reqConfirm('staffUnbind', {}, (res, isFlag, msg) => {
			if (isFlag) {
				message.success('解绑成功')
				onChange(true)
			} else {
				setLoading(false)
				if (msg !== '已取消') {
					message.warning(msg)
				}
			}
		})
	}
	return (
		<Modal
			title='微信机器人'
			visible={visible}
			width={250}
			footer={null}
			onCancel={() => onChange(false)}
		>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
				}}
			>
				<img
					src={url}
					style={{ width: 160, height: 160, borderRadius: '50%' }}
				/>
				<div style={{ margin: 20, marginTop: 10 }}>已绑定机器人</div>
				<div style={{ display: 'flex' }}>
					<Button loading={loading} onClick={onUnBind}>
						立即解绑
					</Button>
					<Button
						loading={loading}
						type='primary'
						style={{ marginLeft: 30 }}
						onClick={() => {
							onChange()
							document.getElementById('robot_modal_header').click()
						}}
					>
						增加绑定
					</Button>
				</div>
			</div>
		</Modal>
	)
}

const { Header } = Layout
const HeaderLayoutPage = ({ robotData, onUbBind, onCheckStyle, pageStyle }) => {
	const [userInfo, setUserInfo] = useState({})
	const [token, setToken] = useState('')
	const [showRobot, setShowRobot] = useState(false)
	const [showEditPwd, setShowEditPwd] = useState(false)
	const history = useHistory()
	const size = useWidth()
	useEffect(() => {
		let ds = getStore('userInfo')
		if (ds) {
			ds = JSON.parse(getStore('userInfo') || '')
			setUserInfo(ds)
		}
		let tokens = getStore('access_token')
		if (tokens) setToken(tokens)
		window.addEventListener('contarct', e => contarctChange(e))
	}, [])
	// 监听
	const contarctChange = e => {
		if (e.detail.flag) {
			req('check', {}, (res, isFlag) => {
				if (isFlag) {
					setStore(
						'userInfo',
						JSON.stringify({
							...res.result,
						})
					)
					setUserInfo(res.result)
				}
			})
		}
	}
	const onclicks = e => {
		if (e.key === '1') {
			clearStore()
			message.success('已退出')
			setTimeout(() => {
				window.location.replace('/login')
			}, 1000)
		} else if (e.key === '0') {
			setShowEditPwd(true)
		} else {
			onCheckStyle()
		}
	}

	const menus = [
		{
			label: `	切换${pageStyle === 'dark' ? '亮色' : '暗色'}风格`,
			key: '2',
			icon: <SwapOutlined />,
		},
		{ label: '修改密码', key: '0', icon: <LockOutlined /> },
		{ label: '退出登录', key: '1', icon: <LogoutOutlined /> },
	]

	// 我的账号菜单
	const menu = (
		<Menu onClick={onclicks}>
			<Menu.Item key='2' icon={<SwapOutlined />}>
				切换{pageStyle === 'dark' ? '亮色' : '暗色'}风格
			</Menu.Item>
			<Menu.Item key='0' icon={<LockOutlined />}>
				修改密码
			</Menu.Item>
			<Menu.Item key='1' icon={<LogoutOutlined />}>
				退出登录
			</Menu.Item>
		</Menu>
	)
	const onChangeRobot = type => {
		setShowRobot(false)
		if (type) {
			onUbBind()
		}
	}

	return (
		<Header
			className='site-layout-background dark_card_header'
			style={{
				paddingLeft: 20,
				boxShadow: '0 1px 4px rgb(0 21 41 / 8%)',
				zIndex: 1,
				display: 'flex',
				flexWrap: 'wrap',
				minWidth: 120,
				boxSizing: 'border-box',
				alignItems: 'center',
				paddingRight: 20,
				height: 'auto',
				overflowX: 'auto',
				overflow: 'hidden',
			}}
		>
			<EditPassword
				visible={showEditPwd}
				onChange={() => setShowEditPwd(false)}
			/>
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'space-between',
					alignItems: 'center',
					flexWrap: 'wrap',
					width: '100%',
				}}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<FullScreenTitle />
				</div>
				{size == 'large' && (
					<div
						style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
					>
						<DownloadQueue />
						<div style={{ whiteSpace: 'nowrap' }}>
							{userInfo.dep_name || '---'} {'>'} {userInfo.name || '----'}
							<span style={{ fontSize: 12, color: '#ccc' }}>
								（工号：{userInfo.work_num}）
							</span>
							<span
								className='dark_font_white'
								style={{ fontSize: 12, color: '#333', cursor: 'pointer' }}
								onClick={() => history.push('/home/work/Integral/detail')}
							>
								积分：{userInfo?.integral ?? 0}
							</span>
						</div>
						<span
							className='bscsfcx'
							style={{ whiteSpace: 'nowrap' }}
							onClick={() => copyTextFun(userInfo.phone)}
						>
							<img
								src={phoneIcon}
								style={{ width: 15, height: 15, marginRight: 5 }}
							/>
							{userInfo.phone}
						</span>
						{!robotData.code ? (
							<IconFont
								onClick={() => setShowRobot(true)}
								type='icona-jiqirenzhineng-04-copy'
								style={{ fontSize: 16 }}
							/>
						) : (
							<></>
						)}
						<div style={{ display: robotData.code ? 'flex' : 'none' }}>
							<Robot data={robotData} />
						</div>
						<Popover content='数据统计'>
							<IconFont
								type='iconlinear_linechart'
								onClick={() =>
									window.open(
										`https://static.oa.cyek.com/bdstat?access_token=${token}`
									)
								}
								style={{ fontSize: 16, marginLeft: 8, cursor: 'pointer' }}
							/>
						</Popover>
						<Popover content='浏览器助手'>
							<img
								onClick={() =>
									window.open('https://www.cyek.com/tools/cyektools/')
								}
								src={guluguluIcon}
								style={{
									width: 16,
									height: 16,
									cursor: 'pointer',
									marginLeft: 10,
								}}
							/>
						</Popover>
						<Popover content='帮助文档'>
							<IconFont
								type='iconhelp'
								onClick={() => window.open('https://www.cyek.com/doc/')}
								style={{ fontSize: 16, marginLeft: 8, cursor: 'pointer' }}
							/>
						</Popover>
						<Popover content='工单'>
							<IconFont
								type='iconbanshou'
								onClick={() => window.open(userInfo?.work_os_link)}
								style={{ fontSize: 16, marginLeft: 8, cursor: 'pointer' }}
							/>
						</Popover>
						<RobotModal
							visible={showRobot}
							url={robotData.url}
							onChange={onChangeRobot}
						/>
						<Dropdown menu={{ items: menus, onClick: onclicks }}>
							<div
								className='ant-dropdown-link'
								onClick={e => e.preventDefault()}
							>
								<EllipsisOutlined />
							</div>
						</Dropdown>
					</div>
				)}
			</div>
		</Header>
	)
}

export default HeaderLayoutPage
