import WS from './ws'
import APIDMIAN from '@/config/urlConfig'
import { notification, Tag } from 'antd'
import { clearStore, getStore } from '@/utils/store'
import { isDevOrProEnv } from '@/config'
import { pubCustomEvent, req } from '@/utils'
import { toast } from 'react-toastify'
import _goPush from '@/utils/routerPush'
import 'react-toastify/dist/ReactToastify.css'
// 需要使用新式弹窗的 添加参数
const newToast = [300, 400]

// 跳转函数
const linkGo = url => window.location.replace(url)
/**
 * 返回状态的隐射
 * @param {string} url-> 需要跳转的url可以不填 可选
 * @param {string} title->提示的title 必选
 * @param {boolean} isLink-> 是否需要跳转  也是查看详情按钮的显示 可选
 * @param {Function} clickCode->如果要跳转或者不跳转进行的自定义操作 可选
 */
let socketOBJ = {
	100: goPush => {
		return {
			url: '/home/work/my/contentDetails',
			title: '进度提醒',
			isLink: true,
			duration: 10,
			clickCode(data) {
				goPush({
					pathname: this.url,
					state: {
						id: data.d.id,
					},
				})
			},
		}
	},
	101: () => {
		return {
			url: '/home/work/my/contentDetails',
			title: '进度提醒',
			isLink: true,
			duration: 10,
		}
	},
	//  导出
	300: () => {
		return {
			title: '导出进度提醒',
			duration: 3,
			clickCode: data => {
				pubCustomEvent(data)
			},
		}
	},
	// 通知
	400: () => {},
	// 点淘进度提醒
	200: goPush => {
		return {
			url: '/home/work/my/contentDetails',
			title: '进度提醒',
			isLink: false,
			duration: 10,
			// clickCode(data) {
			// 	// goPush({
			// 	// 	pathname: this.url,
			// 	// 	state: {
			// 	// 		id: data.d.id,
			// 	// 		type: 10,
			// 	// 	},
			// 	// })
			// },
		}
	},
}

/**
 * @function             提示卡片的ui的渲染
 * @param {object} data  服务端返回的数据
 * @param {object} obj   当前状态隐射的数据
 * @returns
 */
const renderInfo = (data, obj) => {
	return (
		<>
			<p>{data.co}</p>
			<div
				style={{
					display: 'flex',
					justifyContent: 'flex-end',
				}}
			>
				{obj.isLink && (
					<Tag color='blue' style={{ cursor: 'pointer' }}>
						查看详情
					</Tag>
				)}
			</div>
		</>
	)
}
/**
 * @function             服务端返回结果的回调
 * @param {string} data  返回的数据 默认为json
 */
const msgCallback = (data, goPush) => {
	let pr = JSON.parse(data)
	if (pr && typeof pr.t == 'number' && socketOBJ[pr.t]) {
		let mappingData = socketOBJ[pr.t](goPush)
		// 使用老式的弹窗
		if (!newToast.includes(pr.t)) {
			notification.open({
				message: mappingData?.title,
				description: renderInfo(pr, mappingData),
				duration: mappingData.duration,
				onClick: () => {
					// 是否需要跳转
					if (mappingData.isLink) {
						// 如果有函数
						if (typeof mappingData.clickCode === 'function') {
							mappingData.clickCode(pr)
						} else {
							// 如果没有操作函数 默认跳转
							linkGo(mappingData.url)
						}
					} else {
						// 如果不需要跳转
						// 如果有操作函数
						if (typeof mappingData.clickCode === 'function') {
							mappingData.clickCode(pr)
						}
					}
				},
			})
		} else {
			const _setting = {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			}
			// 消息通知
			if (pr.t == 400) {
				req(
					'businessNotifyInfo',
					{ notify_id: pr?.d?.notify_id },
					(res, isFlag, msg) => {
						if (isFlag && !!res?.result) {
							pubCustomEvent({ type: 2 }, 'msgRemind')
							const testDiv = (
								<div
									style={{
										height: 70,
										display: 'flex',
										flexDirection: 'column',
									}}
									onClick={() =>
										_goPush('/businessMsg/lookMsg', {
											id: pr?.d?.notify_id,
										})
									}
								>
									<div style={{ fontWeight: 600 }}>
										您有新的通知，请点击查看！
									</div>
									<div style={{ width: 240 }} className='msg_s_h_2'>
										{res?.result?.title}
									</div>
								</div>
							)
							toast(testDiv, {
								..._setting,
								autoClose: false,
								type: 'info',
								theme: 'colored',
								// icon: false,
								// onOpen: val => {},
							})
						}
					}
				)
			} else {
				// 使用新式的弹窗（只提示）
				//  0 = 待定 1 = 成功 2= 失败
				let statusArr = ['default', 'success', 'error']
				toast(pr.co, {
					..._setting,
					type: statusArr[pr?.d?.state] ? statusArr[pr?.d?.state] : 'default',
					theme: statusArr[pr?.d?.state] ? 'colored' : 'light',
					onOpen: () =>
						typeof mappingData.clickCode == 'function' &&
						mappingData.clickCode(pr),
				})
			}
		}
	}
}

/**
 * @function            开始webSocket
 * @param {number} t    type 默认枚举
 * @returns
 */
const IndexDefultWSSetting = (goPush, t = 0) => {
	let url = APIDMIAN[isDevOrProEnv()]['websoketUrl']
	let user = JSON.parse(getStore('userInfo') || JSON.stringify({})) || true
	if (!user && typeof uesr != 'object') {
		clearStore()
		window.location.replace('/login')
		return
	}
	let ws = new WS(`wss://${url}`, user.uid, data => msgCallback(data, goPush))
	let jsonStr = JSON.stringify({ t, i: user.uid })
	ws.connect(jsonStr)
}

export default IndexDefultWSSetting
