/**
 * @module 查看通知
 */
import React, {
	useEffect,
	useMemo,
	useState,
	forwardRef,
	useImperativeHandle,
} from 'react'
import { req, isArr } from '@/utils'
import { message, Spin, Button } from 'antd'
import './index.less'
let _time: any = null
interface MsgRightProps {
	info: any
	throwChange: Function
	type?: 'modal' | 'default'
	btnStr?: string
	children?: React.ReactChildren
}

const MsgRight = forwardRef((props: MsgRightProps, ref) => {
	const { info, throwChange, type = 'default', btnStr = '确认已读' } = props
	const [readList, setReadList] = useState<any[]>([])
	const [unreadList, setUnreadList] = useState<any[]>([])
	const [check, setCheck] = useState([0, 0])
	const [times, setTimes] = useState(10) // 动态更新

	useImperativeHandle(ref, () => ({
		openTimes,
		reqSetRead,
		clearIntervalTime: () => clearInterval(_time),
		checkTime: () => setTimes(10),
	}))

	const openTimes = () => {
		let index = 10
		_time = setInterval(() => {
			index -= 1
			setTimes(index)
			if (index == 0) {
				clearInterval(_time)
			}
		}, 1000)
	}

	useEffect(() => {
		if (info) {
			reqSetRead()

			// 需要确认
			if (info?.level) {
				type === 'default' && reqRead(1)
				type === 'default' && reqRead(2)
				//
				let index = 10
				_time = setInterval(() => {
					index -= 1
					setTimes(index)
					if (index == 0) {
						clearInterval(_time)
					}
				}, 1000)
			}
		}
		return () => {
			clearInterval(_time)
		}
	}, [])

	// 阅读
	const reqSetRead = () => {
		req(
			'businessNotifyRead',
			{ notify_id: info?.notify_id },
			(res, isFlag, msg) => {}
		)
	}

	// 获取已读和未读
	// type  1 = 已读 2 = 未读
	const reqRead = (type: number) => {
		req(
			'businessConfirmList',
			{ notify_id: info?.notify_id, type },
			(res, isFlag, msg) => {
				if (isFlag) {
					const _res = res?.result
					type == 1 ? setReadList(_res) : setUnreadList(_res)
				}
			}
		)
	}

	const readClick = () => {
		req(
			'businessConfirm',
			{ notify_id: info?.notify_id },
			(res, isFlag, msg) => {
				if (isFlag) {
					// 写入内存
					message.success('确认成功')
					throwChange()
					setTimes(10)
					type === 'default' && reqRead(1)
					type === 'default' && reqRead(2)
				} else {
					message.error(msg)
				}
			}
		)
	}

	// 下载
	const downLoad = (url: string) => {
		const link = document.createElement('a')
		document.body.appendChild(link)
		link.href = url
		link.download = url
		link.click()
		document.body.removeChild(link)
	}

	// 确认和未确认
	const lists = useMemo(() => {
		const arrs = [readList, unreadList]
		return (
			<>
				{arrs.map(
					(j, k) =>
						isArr(j) && (
							<div
								key={k}
								className={`msg_read_box  dark_card_bg ${
									check?.[k] ? 'msg_read_hidden' : ''
								}`}
								id={`${k ? 'readList' : 'unreadList'}_id`}
							>
								<div className='msg_read_box_l'>
									<div className='msg_read_box_l_item'>
										{k ? '未确认' : '已确认'}：
									</div>
									{j.map((v, i) => (
										<div
											className='msg_read_box_l_item'
											key={i}
											title={`工号 ${v?.work_num}`}
										>
											{v?.full_name} - {v?.name}
										</div>
									))}
								</div>
								<div
									className='msg_read_box_r '
									onClick={() => {
										setCheck(
											!k
												? [Number(!!!check?.[0]), 0]
												: [0, Number(!!!check?.[1])]
										)
										const node = document.querySelector(
											`#${k ? 'unreadList' : 'readList'}_id`
										)
										if (node) {
											node.scrollTop = 0
										}
									}}
								>
									{check?.[k] ? '收起' : '展开'}
								</div>
							</div>
						)
				)}
			</>
		)
	}, [check, unreadList, readList])

	return (
		<>
			<p className='msg_r_title'>{info?.title}</p>
			<div className='msg_r_top'>
				<div className='msg_r_t_1'>
					发布人：{info?.publisher_info?.dep}-{info?.publisher_info?.name}
					<div style={{ marginLeft: 12 }}>发布时间：{info?.add_time_str}</div>
				</div>
				<div className='msg_r_t_2'>所属业务：{info?.project_str}</div>
				<div className='msg_r_t_3'>
					生效时间：{info?.effect_started_str} / {info?.effect_ended_str}
				</div>
			</div>
			<div
				className='msg_r_content dark_card_bg'
				dangerouslySetInnerHTML={{ __html: info?.content }}
			/>
			{/* 附件 */}
			{isArr(info?.files) && (
				<div className='msg_r_fj dark_card_bg'>
					{info?.files.map(v => (
						<div
							className='msg_r_fj_item'
							onClick={() => downLoad(v.url)}
							key={v.url}
						>
							{v.fileName}
						</div>
					))}
				</div>
			)}
			{/* 确认和未确认 */}
			{lists}
			{!!info?.level && info?.confirm_state == 0 && (
				<div className='msg_btn'>
					<Button type='primary' disabled={times !== 0} onClick={readClick}>
						{btnStr} {!!times && `${times}S`}
					</Button>
					{props.children && (
						<div className='msg_btn_div'>{props.children}</div>
					)}
				</div>
			)}
		</>
	)
})

export default MsgRight
