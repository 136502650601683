import { useMemo } from 'react'
import { Modal, Input, message } from 'antd'
import Icon from '@/components/system/RouteIcon'
import IconList from '@/components/system/RouteIcon/icon.js'
import IconData from '@/components/system/RouteIcon/iconfont.js'
import { IconFont } from '@/config/settting'
import './index.less'
const iconListData = Object.keys(IconList)
const IconDataKeys = Object.keys(IconData)

// 图标选择弹框
const SelectIconModal = ({ visible, onChange }) => {
	const rendericonListData = useMemo(() => {
		return (
			<>
				{iconListData.map(item => (
					<div
						className='arp_s_icon_modal_item'
						key={item}
						onClick={() => onChange(item)}
					>
						<Icon type={item} style={{ fontSize: 24 }} />
					</div>
				))}
				{IconDataKeys.map(item => (
					<div
						className='arp_s_icon_modal_item'
						key={item}
						onClick={() => onChange(item)}
					>
						<IconFont type={item} style={{ fontSize: 24 }} />
					</div>
				))}
			</>
		)
	}, [])
	return (
		<Modal
			title='图标选择'
			width={600}
			visible={visible}
			footer={null}
			destroyOnClose={true}
			onCancel={() => onChange(false)}
		>
			<Input.Search
				onSearch={e =>
					!!e ? onChange(e) : message.warning('请输入新的图标名')
				}
				placeholder='使用新的iconfont图标'
				enterButton='添加'
				style={{ marginBottom: 10 }}
			/>
			<div className='arp_s_icon_modal'>{rendericonListData}</div>
		</Modal>
	)
}

export default SelectIconModal
