/**
 * @module tab的右键操作
 */
import React from 'react'
import { Dropdown, Menu } from 'antd'
import { cloneDeep } from '@/utils/tools'
import RouteIcon from '@/components/system/RouteIcon'

const menus = [
	{ label: '刷新', key: '0' },
	{ label: '关闭其他', key: '1' },
	{ label: '全部关闭', key: '2' },
]

/**
 * @param {object}  item          当前选中的数据对象
 * @param {Array}   data          整个tabs的数据
 * @param {string}  locationName  当前路由url
 * @param {string}  tabKey        当前选中的tabs
 * @function        throwChange   操作之后的回调
 */
export default ({ item, data, locationName, throwChange, tabKey }) => {
	// 刷新操作
	const refreshTab = () => {
		// 如果刷新不是当前的页面时 页面切换到刷新的页面
		if (item.url != locationName) {
			throwChange('key', { key: item?.component })
		}
		// 回调回去 销毁当前刷新的组件
		throwChange('url', { url: item?.component })
		// 并在100ms 后重新挂载当前组件
		setTimeout(() => {
			throwChange('url', { url: '' })
		}, 100)
	}
	// 关闭所有 or 关闭其他
	/**
	 * @function        removeAll     关闭所有和关闭其他的操作
	 * @param {boolean} type          true->关闭所以 false关闭其他
	 */
	const removeAll = type => {
		const cpData = cloneDeep(data)
		let obj = cpData[0]
		const arr = [obj]
		// 关闭其他
		if (!type) {
			// 算出当前的页面
			let obj1 = cpData.find(v => v.component == item.component)
			arr.push(obj1)
			if (tabKey != obj1.component) {
				throwChange('key', { key: obj1.component })
			}
		} else {
			throwChange('key', { key: obj.component })
		}
		throwChange('data', { data: arr })
	}

	const menuClick = e => {
		const arrs = [
			refreshTab,
			event => {
				event?.domEvent?.stopPropagation()
				removeAll(false)
			},
			event => {
				event?.domEvent?.stopPropagation()
				removeAll(true)
			},
		]
		typeof arrs?.[Number(e?.key)] === 'function' && arrs?.[Number(e?.key)]?.()
	}

	// 清除默认行为，防止事件冒泡
	const preventDefault = e => {
		e.preventDefault()
	}
	return (
		<Dropdown
			menu={{ items: menus, onClick: menuClick }}
			placement='bottom'
			trigger={['contextMenu']}
			arrow
		>
			<span onContextMenu={e => preventDefault(e)}>
				<RouteIcon type={item?.icon} />
				<span style={{ fontSize: 14 }}>{item.name}</span>
			</span>
		</Dropdown>
	)
}
