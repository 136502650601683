import { useState, useEffect, useImperativeHandle, forwardRef,useMemo } from 'react';
import { Checkbox } from 'antd'
import { req } from '@/utils/request'
const AuthTreeSelect = forwardRef((_, ref) => {
    const [treeData, setTreeData] = useState([]);
    const [selectData, setSelectData] = useState({});
    const [selectId, setSelectId] = useState([]);
    useEffect(() => {
        getAuth();
    }, []);

    useImperativeHandle(
        ref,
        () => ({
            onRest: () => {
                setSelectData({});
                setSelectId([]);
            },
            onGet: () => {
                return selectData
            },
            onSet: (e) => {
                let arr = e.split(',');
                let das = {}
                let arIds = []
                treeData.forEach(item => {
                    if (arr.indexOf(item.value.toString()) !== -1) {
                        let arrs = []
                        item.children.forEach(items => {
                            arrs.push(items.value);
                        });
                        das[item.value] = arrs;
                        arIds.push(...[...arrs, item.value]);
                    } else {
                        let arrs = []
                        item.children.forEach(items => {
                            if (arr.indexOf(items.value.toString()) !== -1) arrs.push(items.value);
                        });

                        if (arrs.length !== 0) {
                            if (arrs.length === item.children.length) {
                                arIds.push(item.value);
                            }
                            das[item.value] = arrs;
                            arIds.push(...arrs);
                        }

                    }
                });
                setSelectData(das);
                setSelectId(arIds)
            }
        }),
    )
    const getAuth = () => {
        req('authListCommon', {}, (res, isFlag, msg) => {
            if (isFlag) {
                setTreeData(res.result)
            }
        })
    }
    const renderTreeData = useMemo(() => {
        // 
        const onChangeChecked = (e) => {
            const { value, checked, pid, index } = e.target;
            let data = JSON.parse(JSON.stringify(selectData));
            if (checked) {
                if (pid) {
                    if (data[pid]) data[pid].push(value);
                    else data[pid] = [value];
                    let dataIds = treeData[index].children
                    if (dataIds.length === data[pid].length) {
                        setSelectId(e => [...e, value, pid])
                    } else {
                        setSelectId(e => [...e, value])
                    }
                } else {
                    let dataIds = treeData[index].children
                    data[value] = dataIds.map(item => item.value);
                    setSelectId(e => [...e, value, ...data[value]])
                }

            } else {
                if (pid) {
                    let nes = []
                    data[pid].forEach(e => {
                        if (e !== value) nes.push(e)
                    });
                    let dasIdse = [];
                    selectId.forEach(item => {
                        if (item !== value && item !== pid) dasIdse.push(item);
                    });

                    if (nes.length === 0) {
                        data[pid] = undefined
                    } else {
                        data[pid] = nes
                    }
                    setSelectId(dasIdse)
                } else {
                    let dataIss = JSON.parse(JSON.stringify(data[value]));
                    let dasIdse = [];
                    selectId.forEach(item => {
                        if (item !== value && dataIss.indexOf(item) === -1) dasIdse.push(item);
                    })
                    setSelectId(dasIdse);
                    data[value] = undefined;
                }
            }
            setSelectData(data);
        }

        return treeData.map((item, index) => (
            <div key={item.value} style={{ paddingBottom: 10, borderBottom: '1px solid #ccc', paddingTop: 10 }}>
                <Checkbox checked={selectId.indexOf(item.value) !== -1} indeterminate={selectData[item.value] && selectId.indexOf(item.value) === -1} index={index} onChange={onChangeChecked} value={item.value} style={{ marginBottom: 5 }}>{item.label}</Checkbox>
                <div>
                    {item.children.map(items => <Checkbox index={index} style={{ marginLeft: 20 }} checked={selectId.indexOf(items.value) !== -1} onChange={onChangeChecked} pid={item.value} key={items.value} value={items.value}>{items.label}</Checkbox>)}
                </div>
            </div>
        ))
    }, [treeData, selectData, selectId])
    return (
        <div style={{ height: 400, width: "100%", overflowY: 'auto' }}>
            {renderTreeData}
        </div>
    )
})

export default AuthTreeSelect;