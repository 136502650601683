/**
 * @module 全局响应式需要做的处理
 */

import { useState, useEffect } from 'react'

export default () => {
	const getSize = () => {
		return window.innerWidth > 750 ? 'large' : 'small'
	}
	const useWindowSize = () => {
		const [size, setSize] = useState(getSize())
		useEffect(() => {
			const handler = () => {
				setSize(getSize())
			}
			window.addEventListener('resize', handler)
			return () => {
				window.removeEventListener('resize', handler)
			}
		}, [])
		return size
	}
	return useWindowSize()
}
