// 底部文字
export const CopyrightTxt = ' 2024 灿耀文化版权所有 运营管理平台V6.8.3'

/**
 * @function  判断当前是什么环境
 * @returns  pro-> 生产环境 dev->测试环境 uat->预发环境
 */
export const isDevOrProEnv = () => {
	let url = window.location.origin
	if (~url.indexOf('http://localhost') || ~url.indexOf('https://oa.test.cyek.com')) {
		return 'dev'
	} else if (url.indexOf('https://pre.cy.oa.cyek.com') == 0) {
		return 'uat'
	} else {
		return 'pro'
	}
}
