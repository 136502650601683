/**
 * @module 本地存储相关的函数
 */

export const setStore = (key: string, value: any) => {
	window.localStorage.setItem(key, value)
}
export const getStore = (key: string) => {
	return window.localStorage.getItem(key)
}
export const removeStore = (key: string) => {
	window.localStorage.removeItem(key)
}
export const clearStore = () => {
	window.localStorage.removeItem('userInfo')
	window.localStorage.removeItem('roles')
	window.localStorage.removeItem('access_token')
	window.localStorage.removeItem('routerData')
	window.localStorage.removeItem('routerTabArr')
	window.localStorage.removeItem('routerTabKey')
	window.localStorage.removeItem('codeList')
	window.localStorage.removeItem('msgReminds')
}
