/**
 * @module 判断是否打开f12或者控制台
 */
import devTools from 'devtools-detect'
import { isDevOrProEnv } from '@/config'
import { getStore } from '@/utils/store'
const pro_dev = isDevOrProEnv()
const dep_name = '技术部'

const useIsControl = () => {
	let userInfo = JSON.parse(getStore('userInfo') || JSON.stringify({}))
	const isCode = pro_dev === 'pro' && userInfo?.dep_name != dep_name
	if (isCode) {
		document.onkeydown = function() {
			var e = window.event || arguments[0]
			//屏蔽F12
			if (e.keyCode == 123) {
				return false
				//屏蔽Ctrl+Shift+I
			} else if (e.ctrlKey && e.shiftKey && e.keyCode == 73) {
				return false
				//屏蔽Shift+F10
			} else if (e.shiftKey && e.keyCode == 121) {
				return false
			}
		}
		if (devTools.isOpen) {
			setInterval(() => {
				debugger
			}, 50)
		}
		window.addEventListener('devtoolschange', event => {
			if (event.detail.isOpen) {
				setInterval(() => {
					debugger
				}, 50)
			}
		})
	}
}

export default useIsControl
