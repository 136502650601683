export default {
	iconlinear_bell: 'iconlinear_bell',
	iconline_supplier_1: 'iconline_supplier_1',
	iconline_order: 'iconline_order',
	iconlinear_badge: 'iconlinear_badge',
	iconlinear_basket: 'iconlinear_basket',
	iconline_money: 'iconline_money',
	iconlinear_box: 'iconlinear_box',
	iconline_data: 'iconline_data',
	iconlinear_camera: 'iconlinear_camera',
	iconline_goods: 'iconline_goods',
	iconlinear_bubble: 'iconlinear_bubble',
	iconlinear_bulb: 'iconlinear_bulb',
	iconline_financial: 'iconline_financial',
	iconlinear_cart: 'iconlinear_cart',
	iconline_operating: 'iconline_operating',
	iconlinear_checkbox: 'iconlinear_checkbox',
	iconline_audit: 'iconline_audit',
	iconlinear_businesscard: 'iconlinear_businesscard',
	iconline_pic: 'iconline_pic',
	iconlinear_block: 'iconlinear_block',
	iconline_set: 'iconline_set',
	iconlinear_cloud: 'iconlinear_cloud',
	iconline_assistant: 'iconline_assistant',
	iconlinear_calendar: 'iconlinear_calendar',
	iconline_supplier: 'iconline_supplier',
	iconlinear_category: 'iconlinear_category',
	iconlinear_circle: 'iconlinear_circle',
	iconline_thm: 'iconline_thm',
	iconlinear_cake: 'iconlinear_cake',
	iconqiyeweixin_1: 'iconqiyeweixin_1',
	iconlinear_compass: 'iconlinear_compass',
	iconline_pic1: 'iconline_pic1',
	iconlinear_dashboard: 'iconlinear_dashboard',
	iconline_text: 'iconline_text',
	iconlinear_coupon: 'iconlinear_coupon',
	iconline_book: 'iconline_book',
	iconlinear_card: 'iconlinear_card',
	iconclose: 'iconclose',
	iconlinear_category1: 'iconlinear_category1',
	iconopen: 'iconopen',
	iconlinear_comment: 'iconlinear_comment',
	iconjiqiren: 'iconjiqiren',
	iconlinear_double_arrow: 'iconlinear_double_arrow',
	iconlinear_edit: 'iconlinear_edit',
	iconlinear_diamond: 'iconlinear_diamond',
	iconlinear_envelope: 'iconlinear_envelope',
	iconlinear_download: 'iconlinear_download',
	iconlinear_flag: 'iconlinear_flag',
	iconlinear_giftcard: 'iconlinear_giftcard',
	iconlinear_gift: 'iconlinear_gift',
	iconlinear_gps: 'iconlinear_gps',
	iconlinear_heart: 'iconlinear_heart',
	iconlinear_help: 'iconlinear_help',
	iconlinear_hide: 'iconlinear_hide',
	iconlinear_cart1: 'iconlinear_cart1',
	iconlinear_home_o: 'iconlinear_home_o',
	iconlinear_history: 'iconlinear_history',
	iconlinear_infor: 'iconlinear_infor',
	iconlinear_image: 'iconlinear_image',
	iconlinear_lightning: 'iconlinear_lightning',
	iconlinear_clock: 'iconlinear_clock',
	iconlinear_linechart: 'iconlinear_linechart',
	iconlinear_drawer: 'iconlinear_drawer',
	iconlinear_list: 'iconlinear_list',
	iconlinear_invite: 'iconlinear_invite',
	iconlinear_location: 'iconlinear_location',
	iconlinear_loading_dark: 'iconlinear_loading_dark',
	iconlinear_headphone: 'iconlinear_headphone',
	iconlinear_member: 'iconlinear_member',
	iconlinear_mobile: 'iconlinear_mobile',
	iconlinear_locate: 'iconlinear_locate',
	iconlinear_navgation: 'iconlinear_navgation',
	iconlinear_on: 'iconlinear_on',
	iconlinear_moneyback: 'iconlinear_moneyback',
	iconlinear_lock: 'iconlinear_lock',
	iconlinear_moneybag: 'iconlinear_moneybag',
	iconlinear_miniprogram: 'iconlinear_miniprogram',
	iconlinear_phone: 'iconlinear_phone',
	iconlinear_piechart: 'iconlinear_piechart',
	iconlinear_package: 'iconlinear_package',
	iconlinear_pricetag: 'iconlinear_pricetag',
	iconlinear_orders: 'iconlinear_orders',
	iconlinear_order: 'iconlinear_order',
	iconlinear_package1: 'iconlinear_package1',
	iconlinear_link: 'iconlinear_link',
	iconlinear_query: 'iconlinear_query',
	iconlinear_cross: 'iconlinear_cross',
	iconlinear_redpacket: 'iconlinear_redpacket',
	iconlinear_scan: 'iconlinear_scan',
	iconlinear_poweroff: 'iconlinear_poweroff',
	iconlinear_medal: 'iconlinear_medal',
	iconlinear_monitor: 'iconlinear_monitor',
	iconlinear_cart2: 'iconlinear_cart2',
	iconlinear_qrcode: 'iconlinear_qrcode',
	iconlinear_checkcenter: 'iconlinear_checkcenter',
	iconlinear_more: 'iconlinear_more',
	iconlinear_receipt: 'iconlinear_receipt',
	iconlinear_show: 'iconlinear_show',
	iconlinear_setting: 'iconlinear_setting',
	iconlinear_mike: 'iconlinear_mike',
	iconlinear_discount: 'iconlinear_discount',
	iconlinear_play: 'iconlinear_play',
	iconlinear_star: 'iconlinear_star',
	iconlinear_conversation: 'iconlinear_conversation',
	iconlinear_filter: 'iconlinear_filter',
	iconlinear_speaker: 'iconlinear_speaker',
	iconlinear_tag: 'iconlinear_tag',
	iconlinear_rocket: 'iconlinear_rocket',
	iconlinear_time: 'iconlinear_time',
	iconlinear_top: 'iconlinear_top',
	iconlinear_shield: 'iconlinear_shield',
	iconlinear_service: 'iconlinear_service',
	iconlinear_store: 'iconlinear_store',
	iconlinear_off: 'iconlinear_off',
	iconlinear_money: 'iconlinear_money',
	iconlinear_search: 'iconlinear_search',
	iconlinear_printer: 'iconlinear_printer',
	iconlinear_question: 'iconlinear_question',
	iconlinear_key: 'iconlinear_key',
	iconlinear_people: 'iconlinear_people',
	iconlinear_refresh: 'iconlinear_refresh',
	iconlinear_share: 'iconlinear_share',
	iconlinear_stock: 'iconlinear_stock',
	iconlinear_smileface: 'iconlinear_smileface',
	iconlinear_thumbsup: 'iconlinear_thumbsup',
	iconlinear_map: 'iconlinear_map',
	iconlinear_warning: 'iconlinear_warning',
	iconlinear_wallet: 'iconlinear_wallet',
	iconlinear_wepay: 'iconlinear_wepay',
	iconsolid_bell: 'iconsolid_bell',
	iconlinear_truck: 'iconlinear_truck',
	iconsolid_alipay: 'iconsolid_alipay',
	iconsolid_badge: 'iconsolid_badge',
	iconsolid_award: 'iconsolid_award',
	iconlinear_trash: 'iconlinear_trash',
	iconsolid_basket: 'iconsolid_basket',
	iconsolid_card: 'iconsolid_card',
	iconsolid_businesscard: 'iconsolid_businesscard',
	iconsolid_cake: 'iconsolid_cake',
	iconlinear_treestyle: 'iconlinear_treestyle',
	iconsolid_bulb: 'iconsolid_bulb',
	iconsolid_camera: 'iconsolid_camera',
	iconsolid_block: 'iconsolid_block',
	iconsolid_bag: 'iconsolid_bag',
	iconsolid_bean: 'iconsolid_bean',
	iconsolid_category: 'iconsolid_category',
	iconsolid_calendar: 'iconsolid_calendar',
	iconsolid_compass: 'iconsolid_compass',
	iconsolid_checkbox: 'iconsolid_checkbox',
	iconsolid_cart: 'iconsolid_cart',
	iconsolid_box: 'iconsolid_box',
	iconsolid_bubble: 'iconsolid_bubble',
	iconsolid_cart1: 'iconsolid_cart1',
	iconsolid_cloud: 'iconsolid_cloud',
	iconsolid_category1: 'iconsolid_category1',
	iconsolid_conversation: 'iconsolid_conversation',
	iconsolid_coupon: 'iconsolid_coupon',
	iconsolid_download: 'iconsolid_download',
	iconsolid_diamond: 'iconsolid_diamond',
	iconsolid_drawer: 'iconsolid_drawer',
	iconsolid_checkcenter: 'iconsolid_checkcenter',
	iconsolid_flag: 'iconsolid_flag',
	iconsolid_envelope: 'iconsolid_envelope',
	iconsolid_filter: 'iconsolid_filter',
	iconsolid_fire: 'iconsolid_fire',
	iconsolid_giftcard: 'iconsolid_giftcard',
	iconsolid_comment: 'iconsolid_comment',
	iconsolid_dashboard: 'iconsolid_dashboard',
	iconsolid_discount: 'iconsolid_discount',
	iconsolid_gps: 'iconsolid_gps',
	iconsolid_edit: 'iconsolid_edit',
	iconsolid_infor: 'iconsolid_infor',
	iconsolid_home_o: 'iconsolid_home_o',
	iconsolid_heart: 'iconsolid_heart',
	iconsolid_help: 'iconsolid_help',
	iconsolid_key: 'iconsolid_key',
	iconsolid_headphone: 'iconsolid_headphone',
	iconsolid_invite: 'iconsolid_invite',
	iconsolid_hide: 'iconsolid_hide',
	iconsolid_lightning: 'iconsolid_lightning',
	iconsolid_lock: 'iconsolid_lock',
	iconsolid_list: 'iconsolid_list',
	iconsolid_member: 'iconsolid_member',
	iconsolid_location: 'iconsolid_location',
	iconsolid_locate: 'iconsolid_locate',
	iconsolid_money: 'iconsolid_money',
	iconsolid_more: 'iconsolid_more',
	iconsolid_monitor: 'iconsolid_monitor',
	iconsolid_mike: 'iconsolid_mike',
	iconsolid_map: 'iconsolid_map',
	iconsolid_medal: 'iconsolid_medal',
	iconsolid_navgation: 'iconsolid_navgation',
	iconsolid_mobile: 'iconsolid_mobile',
	iconsolid_moneybag: 'iconsolid_moneybag',
	iconsolid_play: 'iconsolid_play',
	iconsolid_off: 'iconsolid_off',
	iconsolid_on: 'iconsolid_on',
	iconsolid_phone: 'iconsolid_phone',
	iconsolid_pricetag: 'iconsolid_pricetag',
	iconsolid_package: 'iconsolid_package',
	iconsolid_piechart: 'iconsolid_piechart',
	iconsolid_piechart: 'iconsolid_piechart',
	iconsolid_orders: 'iconsolid_orders',
	iconsolid_query: 'iconsolid_query',
	iconsolid_order: 'iconsolid_order',
	iconsolid_image: 'iconsolid_image',
	iconsolid_question: 'iconsolid_question',
	iconsolid_redpacket: 'iconsolid_redpacket',
	iconsolid_rocket: 'iconsolid_rocket',
	iconsolid_search: 'iconsolid_search',
	iconsolid_retail: 'iconsolid_retail',
	iconsolid_receipt: 'iconsolid_receipt',
	iconsolid_people: 'iconsolid_people',
	iconsolid_service: 'iconsolid_service',
	iconsolid_package1: 'iconsolid_package1',
	iconsolid_setting: 'iconsolid_setting',
	iconsolid_smileface: 'iconsolid_smileface',
	iconsolid_shield: 'iconsolid_shield',
	iconsolid_printer: 'iconsolid_printer',
	iconsolid_show: 'iconsolid_show',
	iconsolid_trash: 'iconsolid_trash',
	iconsolid_thumbsup: 'iconsolid_thumbsup',
	iconsolid_stock: 'iconsolid_stock',
	iconsolid_store: 'iconsolid_store',
	iconsolid_time: 'iconsolid_time',
	iconsolid_wallet: 'iconsolid_wallet',
	iconsolid_warning: 'iconsolid_warning',
	iconsolid_tag: 'iconsolid_tag',
	iconsolid_wepay: 'iconsolid_wepay',
	iconsolid_star: 'iconsolid_star',
	iconsolid_speaker: 'iconsolid_speaker',
	iconsolid_truck: 'iconsolid_truck',
	iconsolid_notice: 'iconsolid_notice',
	iconlinear_notice: 'iconlinear_notice',
	iconsolid_clock: 'iconsolid_clock',
	iconlinear_radiobox_null: 'iconlinear_radiobox_null',
	linear_checkbox_null: 'linear_checkbox_null',
	iconsolid_minus: 'iconsolid_minus',
	iconlinear_minus: 'iconlinear_minus',
	iconlinear_plus: 'iconlinear_plus',
	iconsolid_plus: 'iconsolid_plus',
	iconlinear_menu: 'iconlinear_menu',
	iconlinear_radiobox_cell: 'iconlinear_radiobox_cell',
	iconsolid_delete: 'iconsolid_delete',
	iconlinear_delete: 'iconlinear_delete',
	iconlinear_required: 'iconlinear_required',
	iconlinear_double_arrow_up: 'iconlinear_double_arrow_up',
	iconlinear_edit1: 'iconlinear_edit1',
	iconsteper_plus: 'iconsteper_plus',
	iconsteper_minus: 'iconsteper_minus',
	iconshare: 'iconshare',
	iconweixin: 'iconweixin',
	iconreturn: 'iconreturn',
	iconicon_enlarge: 'iconicon_enlarge',
	iconicon_key: 'iconicon_key',
	iconicon_lock: 'iconicon_lock',
	iconpaintroller: 'iconpaintroller',
	iconlinear_flash: 'iconlinear_flash',
	iconlinear_zoom: 'iconlinear_zoom',
	iconlinear_soundoff: 'iconlinear_soundoff',
	iconlinear_beauty: 'iconlinear_beauty',
	iconlinear_flip: 'iconlinear_flip',
	iconlinear_focus1: 'iconlinear_focus1',
	iconlinear_video: 'iconlinear_video',
	iconlinear_historylive1: 'iconlinear_historylive1',
	iconlinear_record: 'iconlinear_record',
	iconsolid_poster: 'iconsolid_poster',
	iconsolid_circle: 'iconsolid_circle',
	iconlinear_accessory: 'iconlinear_accessory',
	iconsolid_imperial: 'iconsolid_imperial',
	iconlinear_arrow_left: 'iconlinear_arrow_left',
	iconreplay: 'iconreplay',
	iconlinear_arrow_right: 'iconlinear_arrow_right',
	iconreplay_full: 'iconreplay_full',
	iconlinear_arrow_down: 'iconlinear_arrow_down',
	iconlinear_video1: 'iconlinear_video1',
	iconlinear_arrow_up: 'iconlinear_arrow_up',
	iconradiobox_section: 'iconradiobox_section',
	iconlinear_award: 'iconlinear_award',
	iconline_promote: 'iconline_promote',
	iconlinear_bag: 'iconlinear_bag',
	iconline_income: 'iconline_income',
	iconlinear_bean: 'iconlinear_bean',
	iconline_subordinate: 'iconline_subordinate',
	iconline_material: 'iconline_material',
	iconxingxing: 'iconxingxing',
	icontianjiajilu: 'icontianjiajilu',
	iconchoujian: 'iconchoujian',
	iconjiucuo: 'iconjiucuo',
	iconcaozuoyuanfeiyongjiesuanshenhe: 'iconcaozuoyuanfeiyongjiesuanshenhe',
	iconerror: 'iconerror',
	iconvip: 'iconvip',
	iconqiangdan1: 'iconqiangdan1',
	iconzhijiesongjian: 'iconzhijiesongjian',
	icondisanfangsongjian: 'icondisanfangsongjian',
	iconqiangdan: 'iconqiangdan',
	iconQQ: 'iconQQ',
	iconqiehao: 'iconqiehao',
	iconerror: 'iconerror',
	iconvip: 'iconvip',
	iconp1: 'iconp1',
	iconp2: 'iconp2',
	iconp3: 'iconp3',
	iconp4: 'iconp4',
	iconp21: 'iconp21',
	iconp20: 'iconp20',
	iconp18: 'iconp18',
	iconp19: 'iconp19',
	iconp17: 'iconp17',
	iconp15: 'iconp15',
	iconp13: 'iconp13',
	iconp12: 'iconp12',
	iconp16: 'iconp16',
	iconp14: 'iconp14',
	iconp11: 'iconp11',
	iconp10: 'iconp10',
	iconp8: 'iconp8',
	iconp7: 'iconp7',
	iconp9: 'iconp9',
	iconVIP: 'iconVIP',
}
