import { useCallback, useEffect, useRef, useState } from 'react'

const useLatest = value => {
	const lRef = useRef(value)
	lRef.current = value
	return lRef
}

export const useUnmount = fn => {
	const fnRef = useLatest(fn)
	useEffect(() => {
		return () => {
			fnRef.current()
		}
	}, [])
}

const useRafState = init => {
	let ref = useRef(0)
	const [state, setState] = useState(init)

	const refState = useCallback(value => {
		cancelAnimationFrame(ref.current)
		ref.current = requestAnimationFrame(() => setState(value))
	}, [])
	useUnmount(() => {
		cancelAnimationFrame(ref.current)
	})
	return [state, refState]
}
export default useRafState
