import { Result, Button, message } from 'antd';
import { getStore, clearStore } from '@/utils/store';
import { useEffect } from 'react'
const getPath = (data) => {
  let path = '';
  let item = data[0];
  if (item.children && item.children.length !== 0) {
    path = getPath(item.children);
  } else {
    path = item.path;
  }
  return path;
}
const Index = () => {

  useEffect(() => {
    setTimeout(() => {
      backHome()
    }, 3000)
  }, [])
  const backHome = () => {
    try {
      let routerDatas = JSON.parse(getStore('routerData'));
      let path = getPath(routerDatas);
      if (!path) throw '失败';
      window.location.replace(path)
    } catch (error) {
      message.warning("登录已失效，请重新登录！");
      clearStore()
      setTimeout(() => {
        window.location.replace('/login');
      }, 1500);
    }
  }
  return (<Result
    status="404"
    title="404"
    subTitle="抱歉, 没有你找的界面"
    extra={<Button type="primary" onClick={backHome}>回到首页</Button>}
  />)
}

export default Index;