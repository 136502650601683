import IconData from './icon'
import { IconFont } from '@/config/settting'

const RouteIcon = ({
	type = 'ZoomOutOutlined',
	style = {},
	onClick = () => {},
	className = '',
}) => {
	// 表示原有的 true 原有的
	let flag = /^[A-Z]/.test(String(type)) || String(type).indexOf('icon') == -1
	// 如果在antd 默认图标中找不到 那么默认指向 阿里iconfont

	const renderIcon = () => {
		if (~type.indexOf('img__')) {
			return (
				<img
					src={IconData[type]}
					style={{ ...style, width: 24, height: 24 }}
					className={className}
					onClick={onClick}
				/>
			)
		} else {
			let Dom = IconData[type] ? IconData[type] : (flag = false)
			return flag ? (
				<Dom style={style} className={className} onClick={onClick} />
			) : (
				<IconFont
					type={String(type)}
					style={style}
					className={className}
					onClick={onClick}
				/>
			)
		}
	}
	return renderIcon()
}

export default RouteIcon
