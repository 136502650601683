export function formLayoutCode(labelCol: number, wrapperCol: number) {
	return {
		labelCol: {
			span: labelCol,
		},
		wrapperCol: {
			span: wrapperCol,
		},
	}
}
