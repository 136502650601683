import { Spin } from 'antd';;

const Loading = ({
    type
}) => {
    return (
        <div className='card_background' style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: type === 'layout' ? '' : 'fixed',
            top: 0,
            left: 0,
            zIndex: 99999,
            background: "#fff"
        }}>
            <Spin size="large" />
        </div >
    )
}

export default Loading;