/**
 * @module 各种的下载的函数(导出导入)
 */
import { req } from '@/utils/request'
import { message } from 'antd'
import { setStore } from '@/utils/store'
import { pubCustomEvent } from '@/utils/tools'

interface IExportParams {
	/** 请求接口名 */
	url: string
	/** 请求参数 */
	params?: {}
	/** 关闭loading 函数 */
	fn?: Function
	/** 成功或失败之后 执行的函数 */
	cb?: Function
}

/**
 * @descriptions  导出走队列的
 */
export const exportTaskRequest: (pro: IExportParams) => void = ({ url, params = {}, fn }) => {
	typeof fn == 'function' && fn(true)
	req(url, params, (res: any, isFlag: boolean, msg: any) => {
		if (isFlag) {
			message.success(msg)
			pubCustomEvent()
			typeof fn == 'function' && fn(false)
		} else {
			message.error(msg)
			typeof fn == 'function' && fn(false)
		}
	})
}
/**
 * @descriptions  导出 不走队列的
 */
export const exportRequest: (pro: IExportParams) => void = ({ url, params = {}, fn, cb }) => {
	typeof fn == 'function' && fn(true)
	req(url, params, (res: any, isFlag: boolean, msg: any) => {
		if (isFlag) {
			typeof fn == 'function' && fn(false)
			typeof cb == 'function' && cb()
			message.success('导出成功')
			setTimeout(() => {
				let link = document.createElement('a')
				link.href = res.result?.url ?? res.result
				link.click()
			}, 1000)
		} else {
			message.error(msg)
			typeof fn == 'function' && fn(false)
		}
	})
}

/**
 * @descriptions 导入 如果导入失败下载返回回来的文件
 */
export const exportRequestAndDownload: (pro: IExportParams) => void = ({
	url,
	params = {},
	fn,
	cb,
}) => {
	req(url, params, (res, isFlag, msg) => {
		typeof fn === 'function' && fn(false)
		if (isFlag) {
			if (!!res?.result?.url) {
				let link = document.createElement('a')
				link.href = res.result?.url
				link.click()
				message.error(msg)
			} else {
				message.success(msg)
			}
			typeof cb === 'function' && cb()
		} else {
			message.error(msg)
		}
	})
}
export const refreshCheck = fn => {
	req('check', {}, (res: any, isFlag: boolean, msg: any) => {
		if (isFlag) {
			setStore(
				'userInfo',
				JSON.stringify({
					...res.result,
				})
			)
			if (typeof fn === 'function') {
				setTimeout(() => {
					fn(false)
				}, 1000)
			}
		} else {
			if (typeof fn === 'function') {
				setTimeout(() => {
					fn(false)
				}, 1000)
			}
			message.warning(msg)
		}
	})
}

/**
 * @descriptions 下载png海报事件
 * @param {string} link 图片链接
 * @param {string} picName 图片名称
 */
export const downloadPng = (link: string, picName?: string, callback?: () => any) => {
	let img = new Image()
	img.setAttribute('crossOrigin', 'Anonymous')
	img.onload = function() {
		let canvas = document.createElement('canvas')
		let context = canvas.getContext('2d')
		canvas.width = img.width
		canvas.height = img.height
		context?.drawImage(img, 0, 0, img.width, img.height)
		// 这里暂时先使用any类型
		canvas.toBlob((blob: any) => {
			let url = URL.createObjectURL(blob)
			let a = document.createElement('a')
			let event = new MouseEvent('click')
			a.download = picName || 'default.png'
			a.href = url
			a.dispatchEvent(event)
			URL.revokeObjectURL(url) // 内存管理,将这句代码注释掉,则将以 blob:http 开头的url复制到浏览器地址栏有效,否则无效.
			a.remove()
			typeof callback == 'function' && callback()
		})
	}
	img.src = link + '?v=' + Date.now()
}
/**
 * @title  下载图片
 *
 * @param {string} url 图片链接
 * @param {string} name 图片名字
 */
export const downloadImg = (url: string, name: any) => {
	const link = document.createElement('a')
	link.href = url
	link.setAttribute('download', name || url)
	document.body.appendChild(link)
	link.click()
	document.body.removeChild(link)
}
