import React, { useState, useEffect } from 'react'
import Router from './router/router'
import { setRouter } from '@/utils/routerCode'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN'
// import LoadingPage from '@/components/system/Loading'
import { getStore } from '@/utils/store'
import moment from 'moment'
import { refreshCheck } from '@/utils/download'
import 'moment/locale/zh-cn'
import 'antd/dist/antd.min.css'
import './App.less'

const App = () => {
	const [loading, setLoading] = useState(true)
	let token = getStore('access_token')

	useEffect(() => {
		document.body.classList.add('dark-mode')
		moment.locale('zh-cn')
		let pathname = window.location.pathname
		const getPath = (data: Array<{ children: Array<any>; path: string }>) => {
			// const getPath = (data: any[]) => {
			let path = ''
			let item = data[0]
			if (item.children && item.children.length !== 0) {
				path = getPath(item.children)
			} else {
				path = item.path
			}
			return path
		}
		// 校验
		const checked = () => {
			setLoading(true)
			let routerDatas = JSON.parse(getStore('routerData') || JSON.stringify([]))
			if (
				(pathname === '/' || pathname === '/login') &&
				routerDatas &&
				routerDatas.length !== 0
			) {
				window.location.pathname = getPath(routerDatas)
			} else {
				refreshCheck(setLoading)
			}
		}
		if (token) {
			checked()
		} else if (pathname !== '/login') {
			setRouter([])
			window.location.replace('/login')
			setTimeout(() => {
				setLoading(false)
			}, 1000)
		} else {
			setLoading(false)
		}
	}, [])
	return (
		<div className='App'>
			<ConfigProvider locale={zhCN}>
				{/* {loading ? <LoadingPage /> : <Router />} */}
				<Router />
			</ConfigProvider>
		</div>
	)
}

export default App
