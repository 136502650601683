import React from 'react'
import Eor from '@/static/assets/err.png'
import './index.less'

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		const {
			location: { pathname },
		} = this.props
		this.state = {
			error: null,
			errorInfo: null,
			currentPage: pathname,
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.location.pathname !== prevState.currentPage) {
			return {
				error: null,
				errorInfo: null,
				currentPage: nextProps.location.pathname,
			}
		}
		return null
	}
	static getDerivedStateFromError(error) {
		return { errorInfo: error.toString(), error: true }
	}
	componentDidCatch(error, info) {
		this.setState({
			error: true,
		})
	}
	render() {
		if (this.state.error) {
			return (
				<div className='content'>
					<div>
						<img src={Eor} width={430} height={360} />
					</div>
					<div>
						<div className='top'>哎呀，出错了！</div>
						<div className='bottom'>页面崩溃了，请检查您的浏览器或刷新页面</div>
						<div className='bottom'>如长时间解决不了，请全屏截图联系技术部</div>
						<p style={{ fontSize: 12, color: '#999' }}>
							{this.state.errorInfo}
						</p>
					</div>
				</div>
			)
		}
		return this.props.children
	}
}
export default ErrorBoundary
