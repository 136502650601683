import md5 from 'md5'
import urlConfig from './urlConfig'
import { isDevOrProEnv } from '@/config'

interface Iparams {
	app_id: number
	[x: string]: any
}

const sign = (params: Iparams, type = 'key') => {
	params.app_id = 1
	let str = ''
	const newkey = Object.keys(params).sort()
	const data = {}
	for (let i = 0; i < newkey.length; i++) {
		data[newkey[i]] = params[newkey[i]] ?? ''
	}
	for (const k in data) {
		if (Object.prototype.hasOwnProperty.call(data, k)) {
			if (!Array.isArray(data[k])) {
				str += `${k}=${data[k]}&`
			}
		}
	}

	str += `key=${urlConfig[isDevOrProEnv()][type]}`
	data['sign'] = md5(str)
	return data
}

export default sign
