import { isArr } from './tools'

export const initSearch = {
	keyword: ['star', 'keyword'],
	platform_id: ['star', 'platform_id'],
	keyword_staff: ['staff', 'keyword'],
	dep_id: ['staff', 'dep_id'],
	team_id: ['staff', 'team_id'],
}

type seachCodeType = { [x: string]: string[] }

export const seachCode = (objs: seachCodeType, params = {}, addParams = {}) => {
	if (!isArr(Object.keys(params)) || !isArr(Object.keys(objs))) return '{}'
	let listObj = {}
	Object.keys(params).forEach(v => {
		if (typeof params[v] != 'undefined' || params[v] != null) {
			const datas = Object.keys(objs).find(k => k == v)
			if (datas && objs[datas]) {
				const [key, value] = objs[datas]
				key in listObj
					? (listObj[key][value] = params[v])
					: ((listObj[key] = {}), (listObj[key][value] = params[v]))
			} else {
				listObj[v] = params[v]
			}
		}
	})
	if (isArr(Object.keys(addParams))) {
		Object.keys(addParams).forEach(v => {
			v in listObj
				? (listObj[v] = { ...listObj[v], ...addParams[v] })
				: (listObj = { ...listObj, [v]: addParams[v] })
		})
	}
	return listObj
}
