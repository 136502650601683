import {
	Route,
	BrowserRouter as Router,
	Switch,
	Redirect,
} from 'react-router-dom'
import Layout from '@/layouts' // layout
import fullScreen from '@/layouts/fullScreen' // layout
import Login from '@/pages/Login' // login
import NoPage from '@/pages/404.js'

const RenderRouter = () => {
	return (
		<Router>
			<Switch>
				<Route path='/home' component={Layout} />
				<Route path='/fullScreen' component={fullScreen} />
				<Route path='/login' component={Login} />
				<Route path='/404' component={NoPage} />
				{/* <Redirect form="/login/*" to="/login" /> */}
				<Redirect to='/404' />
			</Switch>
		</Router>
	)
}

export default RenderRouter
