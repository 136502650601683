import React, { useMemo, Suspense } from 'react'
import { getStore } from '@/utils'
import { Route, Switch } from 'react-router-dom'
import LoadingPage from '@/components/system/Loading'
import { noShowMenu } from './index'

const FullScreenRouter = () => {
	const renderRouterDom = useMemo(() => {
		let RouterConfig = JSON.parse(getStore('routerData') || JSON.stringify([]))
		RouterConfig = RouterConfig.filter(v => noShowMenu.includes(v.name))
		const nameCode = (j, k) => {
			return ~j.name.indexOf(k.name) ? j.name : `${k.name}${j.name}`
		}
		return RouterConfig.map(v => {
			return (
				<Switch key={v.name} path={v.name}>
					{v.children.map(k => {
						const Componentsds = React.lazy(() =>
							import(`@/pages${k.component}`)
						)
						return (
							<Route path={nameCode(k, v)} key={nameCode(k, v)}>
								<Componentsds />
							</Route>
						)
					})}
				</Switch>
			)
		})
		let removeHome = path => String(path).replace('/home', '')

		// 路由递归渲染
		const renderRoute = (data = []) => {
			let ds = []
			data.forEach(item => {
				if (Number(item.type) !== 3) {
					let renderDom = {}
					if (item.children && item.children.length !== 0) {
						renderDom = {
							render: () => {
								return <Switch>{renderRoute(item.children)}</Switch>
							},
						}
						ds.push(<Route key={item.name} path={item.name} {...renderDom} />)
					} else {
						const Componentsds = React.lazy(() =>
							import(`@/pages${item.component}`)
						)
						ds.push(
							<Route
								key={item.name}
								path={item.name}
								component={Componentsds}
							/>
						)
					}
				}
			})
			return ds
		}

		const dom = renderRoute(RouterConfig)
		return dom
	}, [])
	return (
		<>
			<Suspense fallback={<LoadingPage type='layout' />}>
				{renderRouterDom}
				{/* <FullScreen /> */}
				{/* <Route path='/home/404' component={NoPage} /> */}
			</Suspense>
		</>
	)
}

export default FullScreenRouter
