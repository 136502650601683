/**
 * @module 请求的域名和各种的接口
 */

export default {
	pro: {
		//  正式环境
		websoketUrl: '//wscy.oa.cyek.com/ws',
		requestUrl: 'https://api.oa.cyek.com',
		vipUrl: 'https://vip.api.oa.cyek.com',
		key: 'c0f08c7828fa10f067f1870a9cf95b77',
		vipKey: 'c0f08c7828fa10f067f1870a9cf93b76',
	},
	test: {
		// 测试环境
		websoketUrl: '//api.test.cyek.com/ws',
		requestUrl: 'https://dev.test.cyek.com',
		key: '159e34224c00ead2d3a039d32083e111',
	},
	dev: {
		// 测试环境
		websoketUrl: '//api.test.cyek.com/ws',
		// requestUrl: 'https://api.oa.cyek.com',
		requestUrl: 'https://api.test.cyek.com',
		vipUrl: ' https://test.vip.cyek.com',
		// vipUrl: 'https://vip.api.oa.cyek.com',
		key: '159e34224c00ead2d3a039d32083e111',
		vipKey: 'c0f08c7828fa10f067f1870a9cf93b76',
	},
	uat: {
		// 测试环境
		websoketUrl: '//wscy.oa.cyek.com/ws',
		requestUrl: 'https://pre.api.oa.cyek.com',
		vipUrl: 'https://vip.api.oa.cyek.com',
		key: 'c0f08c7828fa10f067f1870a9cf95b77',
		vipKey: 'c0f08c7828fa10f067f1870a9cf93b76',
	},
	apiUrl: {
		// 工作台首页
		workIntegralRank: '/admin/charts/integralRank', // 积分排行榜
		workTaskNum: '/admin/charts/taskNum', // 任务数量图表
		workIntegralLine: '/admin/charts/integralLine', // 积分折线图图表
		workAllIntegralLine: '/admin/charts/allIntegralLine', // 积分折线图图表
		chartsintergaLine: '/admin/charts/allOperateLine',
		chartsStaffHistoryLine: '/admin/charts/staffHistoryLine', // 员工图标
		chartsProjectIncomeList: '/admin/charts/projectIncomeList',//预估收入报表
		// 公共
		authListCommon: '/admin/auth/getSelectList', // 权限列表公共
		deptListCommon: '/admin/dep/getSelectList', // 部门列表公共
		getPassListCommon: '/admin/platform/getSelectList', // 获取平台列表公共
		getRolesCommon: '/admin/group/getSelectList', // 获取角色列表
		getProjectCommon: '/admin/project/getSelectList', // 获取项目列表公共
		OssPostPolicy: '/admin/public/upload/OssPostPolicy', // oss
		depSellist: '/admin/dep/sellist', // 获取团队和部门
		chartsAllTeamInviteRank: '/admin/charts/allTeamInviteRank', // 团队邀请排行榜
		phoneCountryCode: '/admin/public/phoneCountryCode', //手机区号
		projectFollowUpOptions: '/admin/project/followUpOptions', //获取达人跟进状态选项列表
		projectUpdateFollowUp: '/admin/project/updateFollowUp', //更新对应业务的参与项的达人跟进状态
		// 业务配置
		serviceSave: '/admin/service/save', // 保存配置
		serviceGet: '/admin/service/get', //获取配置
		platformsList: '/admin/project/tbzbp/select/platforms/list', // 平台列表
		platformGetCool: '/admin/platform/getCool', // 检测达人

		// 分类
		cateTreeList: '/admin/star/cateTreeList', // 分类列表

		// 达人结算审核
		opGetSettleList: '/admin/op/getSettleList', // 达人结算列表
		opGetSettleOrderList: '/admin/op/getSettleOrderList', // 达人结算单列表
		opHandleSettleOrder: '/admin/op/handleSettleOrder', // 处理结算单
		opSendSettlePayment: '/admin/op/sendSettlePayment ', // 提交付款单
		// 运营权限
		staffSetDepOpStaff: '/admin/staff/setDepOpStaff', // 设置业务部门 业务运营负责人接口
		depGetSelectList: '/admin/dep/getSelectList', // 获取运营权限树列表
		staffGetSelectList: '/admin/staff/getSelectList ', // 获取员工下拉
		verifytaskGet: '/admin/verifytask/get', // 联系方式审核列表
		verifytaskHandle: '/admin/verifytask/handle', // 联系方式审核
		verifytaskSKip: '/admin/verifytask/skip', // 跳过联系方式审核
		verifytaskRecord: '/admin/verifytask/record', // 审核记录
		// 达人信息
		starInfoUserInfoUpdate: '/admin/starInfo/updateUserData', // 达人详情更新用户信息
		starInfoUserInfo: '/admin/starInfo/userInfo', // 达人详情用户信息
		starInfoInfo: '/admin/starInfo/info', // 达人详情详情
		// 标签
		tagList: '/admin/star/tag/list', // 达人标签列表
		tagSet: '/admin/star/customerSetTag', // 设置达人标签
		tagAdd: '/admin/star/tag/add', // 新增达人标签列表
		tagUpdate: '/admin/pact/update', // 更新达人标签列表
		tagRemove: '/admin/star/tag/remove', // 移除达人标签
		tagMerge: '/admin/star/tag/merge', // 标签合并
		pactAdd: '/admin/pact/add', // 签约
		pactUpdate: '/admin/pact/update', // 签约
		tagDel: '/admin/star/tag/del', // d删除标签

		pactInfo: '/admin/pact/info', // 资源详情
		srcContact: '/admin/star/src/contact', // contact
		srcFollow: '/admin/star/src/follow', // follow
		mcnRefresh: '/admin/pact/mcn/refresh', // mcnRefresh
		resourceRefresh: '/admin/star/resourceRefresh', // refreshInfo
		starBindList: '/admin/star/src/staff/bind/list', // staffBindList
		pactStarList: '/admin/pact/staff/list', // 已签合同列表
		starUpdateRemark: '/admin/star/customerUpdateRemark', // 更新备注
		pactInviteCode: '/admin/pact/generate/inviteCode', // 复制注册地址
		getNewStar: '/admin/star/src/getNew', // 获取达人
		srcMakeOver: '/admin/star/src/makeOver', // 达人转让
		resourceHandover: '/admin/star/resourceHandover', // 转让
		srcUnbind: '/admin/star/src/unbind', // 达人解除
		starList: '/admin/star/resourceList', // 达人列表
		srcStarRemove: '/admin/star/src/staff/remove', // 删除达人
		srcStarAdd: '/admin/star/src/staff/add', // 删除达人
		srcStarList: '/admin/star/customerAddRecord', // 待绑达人列表篇
		supplyStarContactList: '/admin/star/supplyStarContactList ', //查看完善资料记录
		supplyStarContact: '/admin/star/supplyStarContact ', //增加达人联系方式
		supplyStarContactDel: '/admin/star/supplyStarContactDel ', //删除达人联系方式
		supplyStarContactReTry: '/admin/star/supplyStarContactReTry', //重新检测
		secInfo: '/admin/star/secInfo', //重新检测

		workExport: '/admin/business/work/export', // 导出
		exportSumBusinessWork: '/admin/business/work/exportCollect', // 汇总导出
		workList: '/admin/business/work/list', // 列表

		// 业务
		businessList: '/admin/business/list', // 列表
		qmCreate: '/admin/project/qm/create', // 创建业务项目
		qmStepLogs: '/admin/project/qm/step/logs', // 业务记录
		qmInfo: '/admin/project/qm/info', // 业务详情
		qmUpdate: '/admin/project/qm/info/update', // 业务详情更新
		qmStepChange: '/admin/project/qm/step/change', //
		qmMcnlog: '/admin/project/qm/mcnlog',
		getSettleStatus: '/admin/project/qm/getSettleStatus', // 结算单状态

		// 员工
		staffList: '/admin/staff/list', // 员工列表
		staffOptions: '/admin/staff/options', // 员工选项列表
		// operManageStaffList: '/admin/operManage/staffList', // 运营员工列表
		staffUpdate: '/admin/staff/update', // 更新
		staffAdd: '/admin/staff/add', // 新增
		staffDel: '/admin/staff/del', // 删除
		staffExportList: '/admin/staff/exportList', // 导出
		StaffImportJoinStaff: '/admin/staff/importJoinStaff', //导入入职员工
		StaffImportLeaveStaff: '/admin/staff/importLeaveStaff', //导入离职员工

		// 部门
		depList: '/admin/dep/list', // 列表
		depUpdate: '/admin/dep/update', // 更新
		depAdd: '/admin/dep/add', //新增
		depDel: '/admin/dep/del', // 删除

		// 角色
		rolesList: '/admin/group/list', // 列表
		rolesAdd: '/admin/group/add', // 新增
		rolesUpdate: '/admin/group/update', // 更新
		rolesDel: '/admin/group/del', // 删除

		// mcn
		mcnApply: '/admin/project/qm/applyBindList', // mcn申请列表
		macApplySuccessOrErr: '/admin/project/qm/auditApplyBind', // mac申请关联
		macAgainApply: '/admin/project/qm/reAuditApplyBind', // mac重新关联
		macExportApply: '/admin/project/qm/exportApplyBind', // mac导出
		//积分
		getIntegralRule: '/admin/public/getIntegralRule', // 获取积分规则
		getIntegralRecord: '/admin/staff/getIntegralRecord', // 获取积分明细
		// 财务
		getSettleAuditList: '/admin/op/getSettleAuditList', // 结算申请
		checkSettle: '/admin/op/checkSettle', // 开始结算单个
		checkAllSettle: '/admin/op/checkAllSettle', // 开始结算多个
		exportSettle: '/admin/op/exportSettle', // 导出
		getPaymentOrderList: '/admin/op/getPaymentOrderList', // 付款处理列表
		updatePaymentOrder: '/admin/op/updatePaymentOrder', // 更新税点
		handlePaymentOrder: '/admin/op/handlePaymentOrder', // 提交付款处理
		exportPaymentOrder: '/admin/op/exportPaymentOrder', // 导出申请处理
		importPaymentOrder: '/admin/op/importPaymentOrder', // 导入处理
		// 纠错
		correctAdd: '/admin/correct/add', // 纠错
		correctList: '/admin/correct/list', // 纠错(商务)
		auditList: '/admin/correct/auditList', // 纠错(运营)
		reportAuditList: '/admin/correct/reportAuditList', // 纠错导出
		audit: '/admin/correct/audit', // 纠错审核
		defendantList: '/admin/correct/defendantList', //被纠错记录
		// 菜单
		menuList: '/admin/menu/list', // 列表
		menuAdd: '/admin/menu/add', // 新增
		menuUpdate: '/admin/menu/update', // 更新
		menuDel: '/admin/menu/del', // 删除

		// 新的接口
		verifytaskStaffLog: '/admin/verifytask/staffLog',
		customerUpdateRemark: '/admin/star/customerUpdateRemark', // 备注
		customerAddContact: '/admin/star/customerAddContact', // 添加联系方式
		customerDetail: '/admin/star/customerDetail', // 达人详情
		customerList: '/admin/star/customerList', // 达人列表
		customerUpdate: '/admin/star/customerUpdate', // 达人列表
		customerStarDetail: '/admin/star/customerDetail', // 达人详情
		starLogs: '/admin/star/logs', // 达人日志
		starLogSelectList: '/admin/star/logSelectList', // 达人频道分类选项
		customerJoinQmList: '/admin/star/customerJoinQmList', // 内容开放平台-参与列表
		customerJoinQmDetail: '/admin/star/customerJoinQmDetail', // 内容开放平台-参与详情
		customerAdd: '/admin/star/customerAdd', // 添加达人
		customerGain: '/admin/star/customerGain', // 领取达人
		resourceRefreshMcn: '/admin/star/resourceRefreshMcn',
		allIntegralRank: '/admin/charts/allIntegralRank', // 积分排行榜超管
		customerExportContact: '/admin/star/customerExportContact', // 导出通讯簿
		// 广告合同
		pactList: '/admin/pact/list',
		// 质检
		qualityList: '/admin/project/qm/qualityList', // 列表
		qualityAdd: '/admin/project/qm/qualityAdd', // 提交
		qualitySubmit: '/admin/project/qm/qualitySubmit', // 送检
		qualityAuditFirst: '/admin/project/qm/qualityAuditFirst', // 一检
		qualityAuditSecond: '/admin/project/qm/qualityAuditSecond', // 二检
		qualityAuditSpotCheck: '/admin/project/qm/qualityAuditSpotCheck', //  抽查
		// qm
		customerJoinQmStepSelect: '/admin/project/ocp/selectListByStep', // qm状态选项列表
		ocpList: '/admin/project/ocp/list', // 内容开放平台列表
		ocpCheck: '/admin/project/ocp/check', // 检测链接
		ocpInvite: '/admin/project/ocp/invite', // 邀请
		ocpCreate: '/admin/project/ocp/create', // 创建
		ocpUpdate: '/admin/project/ocp/update', // 提交上传凭证
		ocpSubmit: '/admin/project/ocp/submit', //提交到mcn
		ocpGain: '/admin/project/ocp/gain', //领取
		ocpInfo: '/admin/project/ocp/info', //详情
		ocpEntering: '/admin/project/ocp/entering', // 录入
		ocpAuditList: '/admin/project/ocp/auditList', // mcn列表
		ocpAuditSubmit: '/admin/project/ocp/auditSubmit', // 审核
		ocpManageList: '/admin/project/ocp/manageList', // 管理列表
		ocpReInviteLink: '/admin/project/ocp/reInviteLink', // 重新发送

		//  运营在职人数
		staffSetcount: '/admin/staff/setCount', // 设置人数
		staffCountList: '/admin/staff/countList', // 列表

		// 运营（内容开放平台）
		ocpExportManageList: '/admin/project/ocp/exportManageList', // 导出
		ocpAuditSecondSubmit: '/admin/project/ocp/auditSecondSubmit', // 二次审计操作
		ocpSecondAuditList: '/admin/project/ocp/secondAuditList', // 二次审计列表
		ocpOperList: '/admin/project/ocp/oper/list', // 列表
		ocpOperSearchLink: '/admin/project/ocp/oper/searchLink', // 列表
		ocpChangeResource: '/admin/project/ocp/change/resource', // 更换链接操作
		ocpChangeStaff: '/admin/project/ocp/change/staff', // 更换员工
		staffSellist: '/admin/staff/sellist', // 选择员工
		ocpOperExport: '/admin/project/ocp/oper/export', // 选择员工
		ocpSecondAuditLog: '/admin/project/ocp/secondAuditLog', // 选择员工
		ocpExportSecondAuditList: '/admin/project/ocp/exportSecondAuditList', // 选择员工
		ocpManageVipList: '/admin/project/ocp/manageVipList', //vip
		ocpExportManageVipList: '/admin/project/ocp/exportManageVipList', //vip 导出
		ocpUpdate1: '/admin/project/ocp/update', //抖音订单号
		ocpArticleList: '/admin/project/ocp/articleList', // 内容数据
		ocpOperDoUnbind: '/admin/project/ocp/oper/doUnbind', // 解绑

		// 快手造星运营
		kszxOperList: '/admin/project/kszx/oper/list',
		exporKszxPoer: '/admin/project/kszx/exportAuditSuccess', // 快手造星运营导出
		importKszx: '/admin/project/kszx/importReport', // 导入
		kszxOperSubmit: '/admin/project/kszx/oper/submit', // 审核
		kszxResubmit: '/admin/project/kszx/resubmit',
		kszxExportReportSuccess: '/admin/project/kszx/exportReportSuccess',
		kszxOperExport: '/admin/project/kszx/oper/export',
		kszxInfo: '/admin/project/kszx/info', // 详情
		kszxSteplogs: '/admin/project/kszx/steplogs', // 业务的进度日志
		kszxManageList: '/admin/project/kszx/manageList', // 业务的进度日志
		// vip达人“
		starConfig: '/star/config', //vip达人数据
		starGet: '/star/get', //vip达人 抢
		vipStarList: '/star/list', //vip达人 列表
		staRefreshMcnState: '/star/refreshMcnState', //vip达人 刷新进度状态
		staRefreshResource: '/star/refreshResource', //vip达人 刷新抖音数据
		ocpCreateInvite: '/admin/project/ocp/createInvite', //vip达人 创建并邀请

		// 点淘
		dtpSelectListByStep: '/admin/project/dtp/selectListByStep', //进度选项列表
		dtpList: '/admin/project/dtp/list', //点淘列表
		dtpManageList: '/admin/project/dtp/manageList', //商务负责管理列表
		dtpOperList: '/admin/project/dtp/oper/list', //运营负责管理列表
		dtpSteplogs: '/admin/project/dtp/steplogs', //进度记录列表-点淘项目
		dtpExportManageList: '/admin/project/dtp/exportManageList', //商务负责管理列表导出数据
		dtpOperExport: '/admin/project/dtp/oper/export', //商务负责管理列表导出数据
		dtpMatrixList: '/admin/project/dtp/matrixList', //  矩阵

		// 点淘 商务
		dtpInvite: '/admin/project/dtp/invite', //邀请/重新邀请
		dtpUpdate: '/admin/project/dtp/update', // 提交上传凭证
		dtpGain: '/admin/project/dtp/gain', // 领取
		starGainOptions: '/admin/project/star/gainOptions', // 项目领取选项列表
		starGain: '/admin/project/star/gain', // 领取Vip达人
		// 点淘运营开放平台
		dtpInfo: '/admin/project/dtp/info', //详情
		operSearchLink: '/admin/project/dtp/oper/searchLink', //检测链接
		dtpChangeResource: '/admin/project/dtp/change/resource', // 更换链接
		dtpChangeStaff: '/admin/project/dtp/change/staff', // 转让达人
		dtpOperSearchLink: '/admin/project/dtp/oper/searchLink', // 查询达人信息

		// 点淘 VIP达人
		dtstarConfig: '/dtstar/config', // config
		dtstarGet: '/dtstar/get', // 抢
		dtstarList: '/dtstar/list', // 列表
		dtstarRefreshResource: '/dtstar/refreshResource', // 刷新抖音数据
		// dtpCreateInvite: '/admin/project/dtp/createInvite', //vip达人 创建并邀请
		dtpCreateInvite: '/admin/project/dtp/vipInvite', //vip达人 创建并邀请
		// 导出
		exportTaskList: '/admin/export/taskList', //导出列表
		exportDelTask: '/admin/export/delTask', // 删除
		dtpCheck: '/admin/project/dtp/check', // 检测资源
		dtpCheckAccount: '/admin/project/dtp/checkAccount', // 检测淘宝
		dtpResInvite: '/admin/project/dtp/resInvite', // 邀请达人
		dtpUnBind: '/admin/project/dtp/unBind', // 解绑
		dtpInviteCancel: '/admin/project/dtp/inviteCancel', // 取消邀请

		// 海报素材
		posterCateList: '/admin/poster/cateList', // 海报分类列表
		posterList: '/admin/poster/list', // 海报列表
		posterAdd: '/admin/poster/add', // 添加海报
		posterDel: '/admin/poster/del', // 删除海报
		posterEdit: '/admin/poster/edit', // 删除海报,
		posterGenerate: '/admin/poster/generate', //生成海报
		posterExport: '/admin/poster/export', //海报导出
		// pdd
		pddpGain: '/admin/project/pddp/gain', //领取
		pddpList: '/admin/project/pddp/list', //海报导出
		pddpCheck: '/admin/project/pddp/check', //海报导出
		pddpCheckAccount: '/admin/project/pddp/checkAccount', //海报导出
		pddpResInvite: '/admin/project/pddp/resInvite', //海报导出
		pddpInvite: '/admin/project/pddp/invite', //邀请
		pddpSelectListByStep: '/admin/project/pddp/selectListByStep', // 统计
		pddpInfo: '/admin/project/pddp/info', // 详情
		pddpSteplogs: '/admin/project/pddp/steplogs', //日志
		pddpOperList: '/admin/project/pddp/oper/list', // 运营列表
		pddpManageList: '/admin/project/pddp/manageList', // 管理列表
		pddpExportManageList: '/admin/project/pddp/exportManageList', // 导出拼多多商务管理
		pddpOperExport: '/admin/project/pddp/oper/export', // 导出拼多多运营
		pddpChangeStaff: '/admin/project/pddp/change/staff', // 转让
		pddpOperSearchLink: '/admin/project/pddp/oper/searchLink', // 查询链接
		pddpChangeResource: '/admin/project/pddp/change/resource', // 修改链接
		pddpCheckStarVPlanInfo: '/admin/project/pddp/checkStarVPlanInfo', // 查询达人计划
		// 惩罚机制
		getPuniTypeList: '/admin/puni/typeList',
		setPuni: '/admin/puni/set',
		delPuni: '/admin/puni/del',
		getPuniList: '/admin/puni/get',
		getPuniLog: '/admin/puni/logs',
		// 快手造星
		kszxCheckAccount: '/admin/project/kszx/checkAccount', //检测快手账号数据
		kszxCheck: '/admin/project/kszx/check', //检测-达人资源
		kszxInvite: '/admin/project/kszx/invite', //邀请-领取达人后邀请
		kszxResInvite: '/admin/project/kszx/resInvite', //邀请-手动邀请达人
		kszxList: '/admin/project/kszx/list', //列表
		kszxSelectListByStep: '/admin/project/kszx/selectListByStep', //进度选项列表
		kszxGain: '/admin/project/kszx/gain', // 领取
		kszxPostList: '/admin/project/kszx/postList', // 查看发文
		kszxImportInitial: '/admin/project/kszx/importInitial', // 导入预审数据
		kszxImportPosts: '/admin/project/kszx/importPosts', // 导入发文数据
		kszxResInviteLink: '/admin/project/kszx/resInviteLink', //快手重新发送邀请
		kszxOperOfficialExport: '/admin/project/kszx/oper/officialExport', //待审导出
		kszxUnbind: '/admin/project/kszx/unbind', //解绑
		kszxReInvite: '/admin/project/kszx/reInvite', //邀请-领取达人后邀请
		kszxOperImportOfficial: '/admin/project/kszx/oper/importOfficial', //邀请-领取达人后邀请
		// 微信视频号运营
		wxvpOperList: '/admin/project/wxvp/oper/list', // 列表
		wxvpCheckAccount: '/admin/project/wxvp/checkAccount', //检测快手账号数据
		wxvpCheck: '/admin/project/wxvp/check', //检测-达人资源
		wxvpInvite: '/admin/project/wxvp/invite', //邀请-领取达人后邀请
		wxvpResInvite: '/admin/project/wxvp/resInvite', //邀请-手动邀请达人
		wxvpList: '/admin/project/wxvp/list', //列表
		wxvpSelectListByStep: '/admin/project/wxvp/selectListByStep', //进度选项列表
		wxvpGain: '/admin/project/wxvp/gain', // 领取
		wxvpAuthen: '/admin/project/wxvp/authen', // 邀请材料
		wxvpManageList: '/admin/project/wxvp/manageList', // 邀请材料
		wxvpExportManageList: '/admin/project/wxvp/exportManageList', // 邀请材料
		wxvpOperExportList: '/admin/project/wxvp/oper/exportList', // 邀请材料
		wxvpInviteCancel: '/admin/project/wxvp/inviteCancel', // 取消成功
		wxvpSelectByStop: '/admin/project/wxvp/selectByStop', // 下拉列表-终止理由
		wxvpSetStopState: '/admin/project/wxvp/setStopState', // -任务终止状态
		wxvpRevokeStopState: '/admin/project/wxvp/revokeStopState', // -取消任务终止状态
		wxvpQualityStatusList: '/admin/project/wxvp/qualityStatusList', //质检-状态选项列表
		wxvpQualitySubmit: '/admin/project/wxvp/qualitySubmit', //质检-提交
		wxvpQuality: '/admin/project/wxvp/quality', //质检-详情
		wxvpQualityAudit: '/admin/project/wxvp/qualityAudit', //质检-审核
		wxvpChangeStaff: '/admin/project/wxvp/change/staff', //转让
		wxvpChangeResource: '/admin/project/wxvp/change/resource', // 更换链接
		wxvpChangeIAccount: '/admin/project/wxvp/change/iAccount', // 更换账号
		wxvpChangeSearchLink: '/admin/project/wxvp/oper/searchLink', // 查询达人链接
		wxvpInfo: '/admin/project/wxvp/info', // 查询达人链接
		wxvpMatrixList: '/admin/project/wxvp/matrixList', // 查询达人链接
		wxvpsteplogs: '/admin/project/wxvp/steplogs', // 日志列表
		wxvpOperPostList: '/admin/project/wxvp/oper/postList', // 发文列表
		wxvpOperExportPostList: '/admin/project/wxvp/oper/exportPostList', // 发文列表导出
		wxvpUpdateCate: '/admin/project/wxvp/updateCate', // 发文列表导出
		wxvpCateList: '/admin/project/wxvp/cateList', // 发文列表导出
		wxvpUpdatePost: '/admin/project/wxvp/updatePost', //修改发文
		wxvpStartPost: '/admin/project/wxvp/startPost', //启动发文
		wxvpEditorManageList: '/admin/project/wxvp/editor/manageList', // 品类列表
		wxvpClipList: '/admin/project/wxvp/editor/list', // 列表
		wxvpEditorTransfer: '/admin/project/wxvp/editor/transfer', // 品类列表
		wxvpReInvite: '/admin/project/wxvp/reInvite', //邀请-手动邀请达人
		wxvpOpenReplacement: '/admin/project/wxvp/openReplacement', //邀请-手动邀请达人
		wxvpAuthManage: '/admin/project/wxvp/authManage', //邀请-手动邀请达人
		wxvpGetWxvStarInfo: '/admin/project/wxvp/getWxvStarInfo', //邀请-手动邀请达人
		// 数据分析
		wvxStepLine: '/admin/charts/wvxStepLine', // 查询达人链接
		wvxStepLineInfo: '/admin/charts/wvxStepLineInfo', // 查询达人链接
		ocpMatrixList: '/admin/project/ocp/matrixList', // 矩阵列表

		// 商务列表
		operManageStaffList: '/admin/operManage/staffList', // 列表
		operManageBusinessAuthInfo: '/admin/operManage/businessAuthInfo', // 列表
		operManageBusinessAuthUpdate: '/admin/operManage/businessAuthUpdate', // 权限更新

		// qq短视频
		xsjpSelectListByStep: '/admin/project/xsjp/selectListByStep', // 进度统计下拉列表
		xsjpList: '/admin/project/xsjp/list', // 商务列表
		xsjpInfo: '/admin/project/xsjp/info', // 详情
		xsjpGain: '/admin/project/xsjp/gain', // 领取
		xsjpOperList: '/admin/project/xsjp/oper/list', // 运营列表
		xsjpSteplogs: '/admin/project/xsjp/steplogs', // 日志
		xsjpManageList: '/admin/project/xsjp/manageList', // 管理列表
		xsjpCheck: '/admin/project/xsjp/check', // 检测外站链接
		xsjpCheckAccount: '/admin/project/xsjp/checkAccount', // 检测参数数据
		xsjpResInvite: '/admin/project/xsjp/resInvite', // 邀请达人
		xsjpInvite: '/admin/project/xsjp/invite', // 领取之后邀请达人
		xsjpUnbind: '/admin/project/xsjp/unbind', // 解约
		xsjpOperExport: '/admin/project/xsjp/oper/export', // 运营导出
		xsjpExportManageList: '/admin/project/xsjp/exportManageList', // 商务管理导出
		xsjpExport: '/admin/project/xsjp/export', // 商务导出
		xsjMatrixList: '/admin/project/xsjp/matrixList', //矩阵列表
		xsjCateList: '/admin/project/xsjp/cateList', //品类列表
		xsjpUpdateCate: '/admin/project/xsjp/updateCate', //品类修改
		// 达人库
		starlibLogSelectList: '/admin/starlib/logSelectList', // 业务日志类别接口
		starlibLogs: '/admin/starlib/logs', // 业务日志列表
		starlibInfo: '/admin/starlib/info', // 达人详情
		starlibCheck: '/admin/starlib/check', // 校验达人
		starlibMySubmit: '/admin/starlib/mySubmit', // 提交绑定申请
		starlibMyReSubmit: '/admin/starlib/myReSubmit', // 重新提交申请
		starlibGroupList: '/admin/starlib/groupList', // 团队达人-列表
		starlibAduitList: '/admin/starlib/aduitList', // 审核达人-列表
		starlibGroupAudit: '/admin/starlib/groupAudit', // 审核达人
		starlibMyList: '/admin/starlib/myList', // 审核达人
		starlibMyDel: '/admin/starlib/myDel', // 商务工作台-审核失败删除
		starlibUpdateCate: '/admin/starlib/updateCate', // 修改达人的分类信息
		starlibupdateContact: '/admin/starlib/updateContact', // 修改达人的联系方式
		starlibUpdate: '/admin/starlib/update', // 修改达人信息
		starlibUpdateRemark: '/admin/starlib/updateRemark', // 备注
		starlibTransfer: '/admin/starlib/transfer', // 转让
		starlibConfig: '/admin/starlib/config', // 本周进度
		// 逛逛
		ggpInvite: '/admin/project/ggp/invite', //邀请/重新邀请
		ggpGain: '/admin/project/ggp/gain', // 领取
		ggpCheckAccount: '/admin/project/ggp/checkAccount', // 检测淘宝
		// 点淘运营开放平台
		ggpInfo: '/admin/project/ggp/info', //详情
		ggpOperSearchLink: '/admin/project/ggp/oper/searchLink', //检测链接
		ggpChangeResource: '/admin/project/ggp/change/resource', // 更换链接

		ggpSelectListByStep: '/admin/project/ggp/selectListByStep', //进度选项列表
		ggpList: '/admin/project/ggp/list', //点淘列表
		ggpManageList: '/admin/project/ggp/manageList', //商务负责管理列表
		ggpOperList: '/admin/project/ggp/oper/list', //运营负责管理列表
		ggpSteplogs: '/admin/project/ggp/steplogs', //进度记录列表-点淘项目
		ggpExportManageList: '/admin/project/ggp/exportManageList', //商务负责管理列表导出数据
		ggpOperExport: '/admin/project/ggp/oper/export', //商务负责管理列表导出数据
		ggpUntagLife: '/admin/project/ggp/untagLife', // 解除
		ggpCheck: '/admin/project/ggp/check', // 检测资源
		ggpResInvite: '/admin/project/ggp/resInvite', // 邀请达人
		ggpSubmit: '/admin/project/ggp/submit', // 提报
		ggpSubmitAudit: '/admin/project/ggp/submitAudit', // 提报运营审核
		ggpImportInvite: '/admin/project/ggp/importInvite', // 导入官方审核结果
		ggpSelectByReason: '/admin/project/ggp/selectByCate', // 导入官方审核结果
		ggpSelectSubmitFailReason: '/admin/project/ggp/select/submitFailReason', // 导入官方审核结果
		ggpUpdate: '/admin/project/ggp/update', // 导入官方审核结果
		ggpOfficialExport: '/admin/project/ggp/officialExport', // 官方导出
		ggpVipInvite: '/admin/project/ggp/vipInvite', // 官方导出
		ggpInviteCancel: '/admin/project/ggp/inviteCancel', // 取消邀请
		ggpChangeStaff: '/admin/project/ggp/change/staff', // 转让达人
		ggpReInvite: '/admin/project/ggp/reInvite', // 重新邀请
		ggpSubmitBack: '/admin/project/ggp/submitBack', // 撤回审核
		ggpImportPublic: '/admin/project/ggp/importPublic', // 导入公示结果
		ggpUnbind: '/admin/project/ggp/unbind', // 解绑
		ggpSavePvtInfo: '/admin/project/ggp/savePvtInfo', // 私信
		ggpSetJianlian: '/admin/project/ggp/setJianlian', //  建联
		ggpSetOpJianlian: '/admin/project/ggp/setOpJianlian', // 运营 建联
		ggpVideoPostsList: '/admin/project/ggp/video/postsList', //  视频发文列表
		ggpVideoExportPostsList: '/admin/project/ggp/video/exportPostsList', //  导出视频发文列表
		chartsGgpFunnelPlot: '/admin/charts/ggpFunnelPlot', //  逛逛漏斗图
		chartsGgpFunnelPlotInfo: '/admin/charts/ggpFunnelPlotInfo', //  逛逛漏斗图详情
		ggpCopyMessage: '/admin/project/ggp/copyMessage', //  复制私信
		ggpSetModify: '/admin/project/ggp/setModify', //  设置整改
		ggpExport: '/admin/project/ggp/export', //  批量导出
		ggpImportMcn: '/admin/project/ggp/importMcn', //  批量导出
		ggpMcnBack: '/admin/project/ggp/mcnBack', //  批量导出
		ggpGetValueByCate: '/admin/project/ggp/getValueByCate', //  获取品类提报|补充信息时需要填写的选项
		// ggpSubmitBack: '/admin/project/ggp/submitBack', //  逛逛-运营审核回滚操作
		ggpSettleList: '/admin/project/ggp/settleList', //  批量导出
		ggpExportSettleList: '/admin/project/ggp/exportSettleList', //  批量导出
		ggpStartPost: '/admin/project/ggp/startPost', //启动发文
		ggpUpdatePost: '/admin/project/ggp/updatePost', //修改发文
		ggGainAuditRank: '/admin/charts/ggGainAuditRank', //逛逛审核数据排行榜
		ggpOperBusStaffList: '/admin/project/ggp/oper/busStaffList', //逛逛审核数据排行榜
		ggpOperSetBusStaff: '/admin/project/ggp/oper/setBusStaff', //逛逛审核数据排行榜
		exportProjectContact: '/admin/project/exportProjectContact', //项目导出联系方式
		ggpStarIncomeList: '/admin/project/ggp/starIncomeList', //项目导出联系方式
		ggpExportStarIncomeList: '/admin/project/ggp/exportStarIncomeList', //项目导出联系方式
		ggpStarLayerList: '/admin/project/ggp/starLayerList', //逛逛达人分层筛选
		// 审核
		ggpGainAuditGain: '/admin/project/ggp/gainAuditGain', //领取
		ggpGainAuditGainOptions: '/admin/project/ggp/gainAuditGainOptions', // 领取选项
		ggpGainAuditManageList: '/admin/project/ggp/gainAuditManageList', // 领取审核管理列表
		ggpGainAuditList: '/admin/project/ggp/gainAuditList', // 领取审核列表
		ggpGainAuditUpdate: '/admin/project/ggp/gainAuditUpdate', // 审核列表-操作
		ggpEditCertainField: '/admin/star/editCertainField', //修改外站信息
		ggpImportSettlement: '/admin/project/ggp/importSettlement', //逛逛导入结算记录
		ggpSettlementList: '/admin/project/ggp/settlementList', //逛逛结算记录列表
		ggpFeedback: '/admin/project/ggp/feedback',
		ggpSettlementOpList: '/admin/project/ggp/settlementOpList', //逛逛结算记录列表-运营
		ggpSettlementFinished: '/admin/project/ggp/settlementFinished', //确认处理完成
		ggpImportWhite: '/admin/project/ggp/importWhite', //逛逛白名单导入

		// 逛逛自审
		ggpSelfAuditSubmit: '/admin/project/ggp/selfAuditSubmit', //逛逛白名单导入
		ggpSelfAuditList: '/admin/project/ggp/selfAuditList', //逛逛白名单导入
		ggpSelfAuditHandle: '/admin/project/ggp/selfAuditHandle', //逛逛白名单导入
		ggpGetLevelList: '/admin/project/ggp/getLevelList', //获取发文等级配置
		ggpSetLevelList: '/admin/project/ggp/setLevelList', //设置发文等级配置
		// 百家号
		bjhpInvite: '/admin/project/bjhp/invite', //邀请/重新邀请
		bjhpGain: '/admin/project/bjhp/gain', // 领取
		bjhpCheckAccount: '/admin/project/bjhp/checkAccount', // 检测淘宝
		// 点淘运营开放平台
		bjhpInfo: '/admin/project/bjhp/info', //详情
		bjhpOperSearchLink: '/admin/project/bjhp/oper/searchLink', //检测链接
		bjhpChangeResource: '/admin/project/bjhp/change/resource', // 更换链接
		bjhpChangeStaff: '/admin/project/bjhp/change/staff', // 转让达人

		bjhpSelectListByStep: '/admin/project/bjhp/selectListByStep', //进度选项列表
		bjhpList: '/admin/project/bjhp/list', //点淘列表
		bjhpManageList: '/admin/project/bjhp/manageList', //商务负责管理列表
		bjhpOperList: '/admin/project/bjhp/oper/list', //运营负责管理列表
		bjhpSteplogs: '/admin/project/bjhp/steplogs', //进度记录列表-点淘项目
		bjhpExportManageList: '/admin/project/bjhp/exportManageList', //商务负责管理列表导出数据
		bjhpOperExport: '/admin/project/bjhp/oper/export', //商务负责管理列表导出数据
		bjhpUntagLife: '/admin/project/bjhp/untagLife', // 解除
		bjhpCheck: '/admin/project/bjhp/check', // 检测资源
		bjhpResInvite: '/admin/project/bjhp/resInvite', // 邀请达人
		bjhpSubmit: '/admin/project/bjhp/submit', // 提报
		bjhpSubmitAudit: '/admin/project/bjhp/submitAudit', // 提报运营审核
		bjhpImportInvite: '/admin/project/bjhp/importInvite', // 导入官方审核结果
		bjhpSelectByReason: '/admin/project/bjhp/selectByReason', // 导入官方审核结果
		bjhpUpdate: '/admin/project/bjhp/update', // 导入官方审核结果
		bjhpReInvite: '/admin/project/bjhp/reInvite', // 导入官方审核结果
		bjhpSubmitBack: '/admin/project/bjhp/submitBack', // 撤回审核
		bjhpUnBind: '/admin/project/bjhp/unBind', // 解绑
		bjhpInviteCancel: '/admin/project/bjhp/inviteCancel', // 取消邀请
		bjhpOfficialExport: '/admin/project/bjhp/officialExport', // 官方导出
		bjhpImportPosts: '/admin/project/bjhp/importPosts', // 导入发文
		bjhpVipInvite: '/admin/project/bjhp/vipInvite', //  免审邀请
		bjhpSetRecycle: '/admin/project/bjhp/setRecycle', //  不回收
		ggdfpInvite: '/admin/project/ggdfp/invite', //邀请/重新邀请
		ggdfpGain: '/admin/project/ggdfp/gain', // 领取
		ggdfpCheckAccount: '/admin/project/ggdfp/checkAccount', // 检测淘宝
		// 点淘运营开放平台
		ggdfpInfo: '/admin/project/ggdfp/info', //详情
		ggdfpOperSearchLink: '/admin/project/ggdfp/oper/searchLink', //检测链接
		ggdfpChangeResource: '/admin/project/ggdfp/change/resource', // 更换链接
		ggdfpChangeStaff: '/admin/project/ggdfp/change/staff', // 转让达人

		ggdfpSelectListByStep: '/admin/project/ggdfp/selectListByStep', //进度选项列表
		ggdfpList: '/admin/project/ggdfp/list', //点淘列表
		ggdfpManageList: '/admin/project/ggdfp/manageList', //商务负责管理列表
		ggdfpOperList: '/admin/project/ggdfp/oper/list', //运营负责管理列表
		ggdfpSteplogs: '/admin/project/ggdfp/steplogs', //进度记录列表-点淘项目
		ggdfpExportManageList: '/admin/project/ggdfp/exportManageList', //商务负责管理列表导出数据
		ggdfpOperExport: '/admin/project/ggdfp/oper/export', //商务负责管理列表导出数据
		ggdfpUntagLife: '/admin/project/ggdfp/untagLife', // 解除
		ggdfpCheck: '/admin/project/ggdfp/check', // 检测资源
		ggdfpResInvite: '/admin/project/ggdfp/resInvite', // 邀请达人
		ggdfpSubmit: '/admin/project/ggdfp/submit', // 提报
		ggdfpSubmitAudit: '/admin/project/ggdfp/submitAudit', // 提报运营审核
		ggdfpImportInvite: '/admin/project/ggdfp/importInvite', // 导入官方审核结果
		ggdfpSelectByReason: '/admin/project/ggdfp/selectByReason', // 导入官方审核结果
		ggdfpUpdate: '/admin/project/ggdfp/update', // 导入官方审核结果
		ggdfpReInvite: '/admin/project/ggdfp/reInvite', // 导入官方审核结果
		ggdfpSubmitBack: '/admin/project/ggdfp/submitBack', // 撤回审核
		ggdfpUnBind: '/admin/project/ggdfp/unBind', // 解绑
		ggdfpInviteCancel: '/admin/project/ggdfp/inviteCancel', // 取消邀请
		ggdfpOfficialExport: '/admin/project/ggdfp/officialExport', // 官方导出
		ggdfpImportPosts: '/admin/project/ggdfp/importPosts', // 导入发文
		ggdfpVipInvite: '/admin/project/ggdfp/vipInvite', //  免审邀请
		ggdfpSetRecycle: '/admin/project/ggdfp/setRecycle', //  不回收
		ggpImportStarLayer: '/admin/project/ggp/importStarLayer', //达人分层导入
		//淘宝直播
		tbzbpInvite: '/admin/project/tbzbp/invite', //手动领取邀请
		tbzbpReInvite: '/admin/project/tbzbp/reInvite', //重新邀请
		tbzbpCheck: '/admin/project/tbzbp/check', //外站链接检测
		tbzbpSelectListByStep: '/admin/project/tbzbp/selectListByStep', //进度列表
		tbzbpCheckAccount: '/admin/project/tbzbp/checkAccount', //检测淘宝直播账号数据
		tbzbpList: '/admin/project/tbzbp/list', //淘宝直播列表
		tbzbpInfo: '/admin/project/tbzbp/info', //淘宝直播详情
		tbzbpSteplogs: '/admin/project/tbzbp/steplogs', //进度日志
		tbzbpSelectData: '/admin/project/tbzbp/selectData', //下拉数据
		tbzbpOperList: '/admin/project/tbzbp/oper/list', //运营查看列表
		tbzbpOperExport: '/admin/project/tbzbp/oper/export', //运营导出
		tbzbpImportOfficial: '/admin/project/tbzbp/oper/importOfficial', //运营审核结果导入
		tbzbpOfficialExport: '/admin/project/tbzbp/oper/officialExport', //待审导出
		tbzbpChangeStaff: '/admin/project/tbzbp/change/staff', //更换达人操作
		tbzbpUnBind: '/admin/project/tbzbp/unBind', //解绑达人
		tbzbpManageList: '/admin/project/tbzbp/manageList', //商务管理列表
		tbzbpExportManageList: '/admin/project/tbzbp/exportManageList', //导出
		tbzbpUpdate: '/admin/project/tbzbp/update', //导出
		tbzbpMatrixList: '/admin/project/tbzbp/matrixList', // 矩阵
		tbzbpInviteCancel: '/admin/project/tbzbp/inviteCancel', // 取消邀请
		tbzbpChangeResource: '/admin/project/tbzbp/change/resource', // 更换链接
		tbzbpOperSearchLink: '/admin/project/tbzbp/oper/searchLink', // 查询达人接口
		tbzbpAgentList: '/admin/agent/list', // 代理
		tbzbpAgentAdd: '/admin/agent/add', // 添加
		tbzbpOrgList: '/admin/org/list', // 机构
		tbzbpOrgAdd: '/admin/org/add', // 添加
		tbzbpOrgSelectList: '/admin/org/selectList', // 代理
		tbzbpAgentSelectList: '/admin/agent/selectList', // 代理
		tbzbpAuditSubmit: '/admin/project/tbzbp/auditSubmit', // 重新提交审核
		tbzbpreBindAudit: '/admin/project/tbzbp/reBindAudit',
		// 淘宝直播 直播数据
		tbzbpAnchorList: '/admin/project/tbzbp/anchor/list', // 主播数据列表
		tbzbpAnchorLiveList: '/admin/project/tbzbp/anchor/liveList', //主播直播数据列表
		tbzbpAnchorLiveRecord: '/admin/project/tbzbp/anchor/liveRecord', //主播直播数据列表
		tbzbpAnchorExport: '/admin/project/tbzbp/anchor/export', // 导出
		tbzbpAnchorExportDetail: '/admin/project/tbzbp/anchor/exportDetail', // 导出

		// 支付宝
		alipaypSelectListByStep: '/admin/project/alipayp/selectListByStep', // 下拉列表-进度统计
		alipaypList: '/admin/project/alipayp/list', // 下拉列表-进度统计
		alipaypManageList: '/admin/project/alipayp/manageList', // 下拉列表-进度统计
		alipaypOperList: '/admin/project/alipayp/oper/list', // 下拉列表-进度统计
		alipaypInvite: '/admin/project/alipayp/invite', // 下拉列表-进度统计
		alipaypSteplogs: '/admin/project/alipayp/steplogs', // 进度日志列表
		alipaypInfo: '/admin/project/alipayp/info', // 详情
		alipaypCheck: '/admin/project/alipayp/check', // 检测-达人资源
		alipaypUnBind: '/admin/project/alipayp/unbind', // 解绑
		alipaypOperExport: '/admin/project/alipayp/oper/export', // 解绑
		alipaypImportWhite: '/admin/project/alipayp/importWhite', // 解绑
		alipaypCheckAccount: '/admin/project/alipayp/checkAccount', // 解绑
		alipaypInviting: '/admin/project/alipayp/inviting', // 邀请
		alipaypInviteSucc: '/admin/project/alipayp/inviteSucc', // 成功
		alipaypInviteFail: '/admin/project/alipayp/inviteFail', // 失败
		alipaypInviteCancel: '/admin/project/alipayp/inviteCancel', // 取消邀请
		alipaypOperSearchLink: '/admin/project/alipayp/oper/searchLink', // 取消邀请
		alipaypChangeResource: '/admin/project/alipayp/change/resource', // 取消邀请
		alipaypReInvite: '/admin/project/alipayp/reInvite', // 取消邀请
		alipaypExportManageList: '/admin/project/alipayp/exportManageList', // 取消邀请
		alipaypImportOfficialDetail: '/admin/project/alipayp/importOfficialDetail', // 取消邀请
		alipaypCateList: '/admin/project/alipayp/cateList', // 取消邀请
		alipaypAnchorList: '/admin/project/alipayp/anchor/list', // 取消邀请
		alipaypCateUpdate: '/admin/project/alipayp/updateCate', // 取消邀请
		alipaypChangeStaff: '/admin/project/alipayp/change/staff', // 取消邀请
		chartsAlipayStepLine: '/admin/charts/alipayStepLine', // 支付宝达人数据漏斗图
		chartsAlipayStepLineInfo: '/admin/charts/alipayStepLineInfo', // 支付宝达人数据详情图
		alipaypOfficialList: '/admin/project/alipayp/officialList', //官方达标列表
		alipaypExportOfficialList: '/admin/project/alipayp/exportOfficialList', //官方达标列表导出

		// 京东
		jdpList: '/admin/project/jdp/list', //列表
		jdpSteplogs: '/admin/project/jdp/steplogs', //列表
		jdpInfo: '/admin/project/jdp/info', // 详情
		jdpContentTags: '/admin/project/jdp/contentTags', // 三级
		jdpSelectListByStep: '/admin/project/jdp/selectListByStep', // 三级
		jdpManageList: '/admin/project/jdp/manageList', // 三级
		jdpOperList: '/admin/project/jdp/oper/list', // 三级
		jdpCheck: '/admin/project/jdp/check', // 三级
		jdpSubmit: '/admin/project/jdp/submit', // 三级
		jdpMatrixList: '/admin/project/jdp/matrixList', // 三级
		jdpCheckAccount: '/admin/project/jdp/checkAccount', // 三级
		jdpUnBind: '/admin/project/jdp/unBind', //  解绑
		jdpOperImportOfficial: '/admin/project/jdp/oper/importOfficial', //  导入
		jdpOperOfficialExport: '/admin/project/jdp/oper/officialExport', //  导出
		jdpInvite: '/admin/project/jdp/invite', //  邀请
		jdpChangeStaff: '/admin/project/jdp/change/staff', //  邀请
		jdpOperSearchLink: '/admin/project/jdp/oper/searchLink', //  更换链接查询达人
		jdpChangeResource: '/admin/project/jdp/change/resource', //  更换链接
		// jdpOmChangeResource:'/admin/jdp/om/changeResource',
		jdpCancelInvite: '/admin/project/jdp/cancelInvite', //取消邀请
		jdpExportManageList: '/admin/project/jdp/exportManageList', // 商务管理批量导出
		jdpOperExport: '/admin/project/jdp/oper/export', // 运营批量导出
		jdpCateSelectList: '/admin/project/jdp/cateSelectList', //品类
		jdpPostList: '/admin/project/jdp/postList',
		jdpStartPost: '/admin/project/jdp/startPost',
		jdpEditorList: '/admin/project/jdp/editor/list',//京东剪辑列表
		jdpEditorManageList: '/admin/project/jdp/editor/manageList',//剪辑管理列表
		jdpEditorTransfer: '/admin/project/jdp/editor/transfer',//分配剪辑
		jdpReBindAudit: '/admin/project/jdp/reBindAudit',
		jdpKeywordList: "/admin/project/jdp/keywordList",//关键词列表
		jdpKeywordPostList: '/admin/project/jdp/keywordPostList',//关键词采集列表
		getPostCate: '/getPostCate',//品类列表
		jdpKeywordPostEdit: '/admin/project/jdp/keywordPostEdit',//关键词采集编辑
		jdpKeywordPostAudit: '/admin/project/jdp/keywordPostAudit',//关键词采集审核
		// 剪辑
		ggpMaterialCateList: '/admin/project/ggp/materialCateList', //  获取花字分类列表
		ggpMaterialList: '/admin/project/ggp/materialList', // 获取花字列表
		ggpSetCompoundFont: '/admin/project/ggp/setCompoundFont', // 生成花字图片
		ggpGetCompoundFont: '/admin/project/ggp/getCompoundFont', // 获取花字图片生成结果
		ggpSaveCover: '/admin/project/ggp/saveCover', // 保存封面图片
		ggpStarvideoSelectByData: '/admin/project/ggp/starvideo/selectByData', // 发文结果
		clipVideoList: '/admin/clip/videoList', // 发文结果
		clipVideoCache: '/admin/clip/videoCache', // 视频缓存
		clipVideoRelevancy: '/admin/clip/videoRelevancy', // 视频关联
		clipPostsList: '/admin/clip/postsList', // 视频关联
		clipTopicGroupList: '/admin/clip/topicGroupList', // 视频关联
		clipTopicList: '/admin/clip/topicList', // 视频关联
		clipItemSource: '/admin/clip/itemSource', // 商品来源
		clipItemList: '/admin/clip/itemList', // 商品列表
		clipRePublish: '/admin/clip/rePublish', // 重新发布
		clipPublish: '/admin/clip/publish', // 代发文
		clipVideoCacheRelevancy: '/admin/clip/videoCacheRelevancy', // 视频缓存/关联
		ggpClipList: '/admin/project/ggp/clip/list', // 列表
		clipPostsDelete: '/admin/clip/postsDelete', // 删除
		ggpCateList: '/admin/project/ggp/cateList', // 品类列表
		ggpAddCate: '/admin/project/ggp/addCate', // 品类列表
		ggpDelCate: '/admin/project/ggp/delCate', // 品类列表
		ggpEditorGain: '/admin/project/ggp/editor/gain', // 品类列表
		ggpEditorList: '/admin/project/ggp/editor/list', // 品类列表
		ggpEditorTransfer: '/admin/project/ggp/editor/transfer', // 品类列表
		ggpEditorManageList: '/admin/project/ggp/editor/manageList', // 品类列表
		ggpEditCate: '/admin/project/ggp/editCate', // 品类列表
		ggpEditStaffList: '/admin/project/ggp/editor/staffList', // 品类列表
		ggpEditGainOptions: '/admin/project/ggp/editor/gainOptions', // 品类列表
		ggpSubmitUnbind: '/admin/project/ggp/submitUnbind', // 品类列表
		ggpUnbindList: '/admin/project/ggp/unbindList', // 品类列表
		ggpRefuseUnbind: '/admin/project/ggp/refuseUnbind', // 品类列表
		ggpExportUnbindList: '/admin/project/ggp/exportUnbindList', // 品类列表

		// 支付宝发文
		alipaypReplacePublish: '/admin/project/alipayp/publish', // 代发文
		alipaypReplacePostsList: '/admin/project/alipayp/postsList', // 代发文
		alipaypReplaceRePublish: '/admin/project/alipayp/rePublish', // 重新发送
		alipaypReplaceRePostsDelete: '/admin/project/alipayp/postsDelete', // 删除
		alipaypEditorTransfer: '/admin/project/alipayp/editor/transfer', // 转让
		alipaypEditorManageList: '/admin/project/alipayp/editor/manageList', // 转让
		alipaypUpdatePost: '/admin/project/alipayp/updatePost', //修改发文
		alipaypStartPost: '/admin/project/alipayp/startPost', //启动发文
		alipaypEditCate: '/admin/project/alipayp/editCate', //启动发文
		alipaypAddCate: '/admin/project/alipayp/addCate', //启动发文
		alipaypEditorList: '/admin/project/alipayp/editor/list', //启动发文
		alipaypEditStaffList: '/admin/project/alipayp/editor/staffList', // 品类列表
		alipaypEditGain: '/admin/project/alipayp/editor/gain', // 品类列表
		alipaypCateManageList: '/admin/project/alipayp/cateManageList', // 品类列表
		alipaypDelCate: '/admin/project/alipayp/delCate', // 品类列表
		alipaypEditorGainOptions: '/admin/project/alipayp/editor/gainOptions', // 品类列表
		// 通知
		businessNotifyList: '/admin/businessNotify/list', // 列表
		businessNotifyDelete: '/admin/businessNotify/delete', // 删除
		businessNotifyPublish: '/admin/businessNotify/publish', // 发布通知
		businessNotifyProjectConf: '/admin/businessNotify/projectConf', // 项目列表
		businessNotifyInfo: '/admin/businessNotify/info', // 详情
		businessReadList: '/admin/businessNotify/readList', // 详情
		businessNotifyRead: '/admin/businessNotify/read', // 阅读
		businessConfirmList: '/admin/businessNotify/confirmList', // 阅读
		businessConfirm: '/admin/businessNotify/confirm', // 阅读

		// 发文
		alipayContentList: '/admin/project/alipayp/contentList', // zfb发文列表
		alipayContentAnalysis: '/admin/project/alipayp/contentAnalysis', // zfb30发文列表
		alipayContentSettle: '/admin/project/alipayp/contentSettle', // zfb30发文列表
		alipayExportContentList: '/admin/project/alipayp/exportContentList', // 导出内容(发文)列表
		alipayExportContentAnalysis: '/admin/project/alipayp/exportContentAnalysis', // 导出内容(发文)近30日分析数据
		alipayExportContentSettle: '/admin/project/alipayp/exportContentSettle', // 导出内容(发文)近30日账单数据

		// 美团
		mtpBusList: '/admin/mtp/bus/list', // 商务
		mtpBmsList: '/admin/mtp/bm/list', // 商务
		mtpOmsList: '/admin/mtp/om/list', // 商务
		mtpBusInfo: '/admin/mtp/bus/info', // 商务
		mtpBusInvite: '/admin/mtp/bus/invite', // 商务
		mtpBusReInvite: '/admin/mtp/bus/reInvite', // 商务
		mtpBusListTabs: '/admin/mtp/bus/listTabs', // 商务
		mtpBmListTabs: '/admin/mtp/bm/listTabs', // 商务
		mtpOmListTabs: '/admin/mtp/om/listTabs', // 商务
		starCheck: '/admin/star/check', // 商务
		mtpBusInviteStar: '/admin/mtp/bus/inviteStar', // 商务
		mtpOmInviteCancel: '/admin/mtp/om/inviteCancel', // 商务
		mtpUnbind: '/admin/mtp/om/unbind', // 商务
		mtpOmChangeOwner: '/admin/mtp/om/changeOwner', // 商务
		mtpOmImportOptions: '/admin/mtp/om/importOptions', // 商务
		mtpOmImport: '/admin/mtp/om/import', // 商务
		mtpBmListExport: '/admin/mtp/bm/listExport', // 商务管理 导出
		mtpOmListExport: '/admin/mtp/om/listExport', // 商务
		mtpOmChangeResource: '/admin/mtp/om/changeResource', // 商务
		mtpPubCateOptions: '/admin/mtp/pub/cateOptions', // 商务
		mtpBusSubmitStar: '/admin/mtp/bus/submitStar', // 商务
		mtpBusSubmit: '/admin/mtp/bus/submit', // 商务
		mtpOmExportOptions: '/admin/mtp/om/exportOptions', // 商务
		mtpOmExport: '/admin/mtp/om/export', // 商务
		mtpOmSubmitAudit: '/admin/mtp/om/submitAudit', // 商务
		mtpOmAudit: '/admin/mtp/om/audit', // 商务
		mtpSubmitApply: '/admin/mtp/bus/submitApply',
		mtpMatrixOptions: '/admin/mtp/pub/matrixOptions', //美团矩阵
		mtpOmUnbindHandle: '/admin/mtp/om/unbindHandle', //美团矩阵

		// 快手燎原
		kslySelectListByStep: '/admin/project/ksly/selectListByStep', // 商务
		kslyList: '/admin/project/ksly/list', // 商务
		kslyManageList: '/admin/project/ksly/manageList', // 商务
		kslyOperList: '/admin/project/ksly/oper/list', // 商务
		kslyInvite: '/admin/project/ksly/invite', // 商务
		kslyCheckAccount: '/admin/project/ksly/checkAccount', // 商务
		kslySteplogs: '/admin/project/ksly/steplogs', // 商务
		kslyInfo: '/admin/project/ksly/info', // 商务
		kslyReInvite: '/admin/project/ksly/reInvite', // 商务
		kslyChangeStaff: '/admin/project/ksly/change/staff', // 商务
		kslyExport: '/admin/project/ksly/oper/export', // 商务
		kslyImportInitial: '/admin/project/ksly/importInitial', // 商务
		kslyImportPosts: '/admin/project/ksly/importPosts', // 商务
		kslyOperImportOfficial: '/admin/project/ksly/oper/importOfficial', // 商务
		kslyOperOfficialExport: '/admin/project/kszx/oper/officialExport', // 商务
		kslyResInviteLink: '/admin/project/ksly/resInviteLink', // 商务
		kslyInviteLy: '/admin/project/ksly/inviteLy', // 商务
		kslyChangeResource: '/admin/project/ksly/change/resource', // 商务
		kslyExportManageList: '/admin/project/ksly/exportManageList', // 商务

		// 咪咕
		mgpBusList: '/admin/mgp/bus/list', // 商务
		mgpBmsList: '/admin/mgp/bm/list', // 商务
		mgpOmsList: '/admin/mgp/om/list', // 商务
		mgpBusInfo: '/admin/mgp/bus/info', // 商务
		mgpBusInvite: '/admin/mgp/bus/invite', // 商务
		mgpBusReInvite: '/admin/mgp/bus/reInvite', // 商务
		mgpBusListTabs: '/admin/mgp/bus/listTabs', // 商务
		mgpBmListTabs: '/admin/mgp/bm/listTabs', // 商务
		mgpOmListTabs: '/admin/mgp/om/listTabs', // 商务
		mgpBusInviteStar: '/admin/mgp/bus/inviteStar', // 商务
		mgpOmInviteCancel: '/admin/mgp/om/inviteCancel', // 商务
		mgpUnbind: '/admin/mgp/om/unbind', // 商务
		mgpOmChangeOwner: '/admin/mgp/om/changeOwner', // 商务
		mgpOmImportOptions: '/admin/mgp/om/importOptions', // 商务
		mgpOmImport: '/admin/mgp/om/import', // 商务
		mgpBmListExport: '/admin/mgp/bm/listExport', // 商务管理 导出
		mgpOmListExport: '/admin/mgp/om/listExport', // 商务
		mgpOmChangeResource: '/admin/mgp/om/changeResource', // 商务
		mgpPubCateOptions: '/admin/mgp/pub/cateOptions', // 商务
		mgpBusSubmitStar: '/admin/mgp/bus/submitStar', // 商务
		mgpBusSubmit: '/admin/mgp/bus/submit', // 商务
		mgpOmExportOptions: '/admin/mgp/om/exportOptions', // 商务
		mgpOmExport: '/admin/mgp/om/export', // 商务
		mgpOmSubmitAudit: '/admin/mgp/om/submitAudit', // 商务
		mgpOmAudit: '/admin/mgp/om/audit', // 商务
		mgpPubMatrixOptions: '/admin/mgp/pub/matrixOptions', // 商务

		// 美团剪辑
		mtpEditorList: '/admin/mtp/editor/list', // 商务
		mtpEditorManageList: '/admin/mtp/editor/manageList', // 商务
		mtpEditorStartPost: '/admin/mtp/editor/startPost', // 商务
		mtpEditorTransferEditor: '/admin/mtp/editor/transferEditor', // 商务
		mtpCateList: '/admin/mtp/cate/list', // 商务
		mtpCateAdd: '/admin/mtp/cate/add', // 商务
		mtpCateEdit: '/admin/mtp/cate/edit', // 商务
		mtpCateDel: '/admin/mtp/cate/del', // 商务

		// 优酷
		ukpBusList: '/admin/ukp/bus/list', // 商务
		ukpBmsList: '/admin/ukp/bm/list', // 商务
		ukpOmsList: '/admin/ukp/om/list', // 商务
		ukpBusInfo: '/admin/ukp/bus/info', // 商务
		ukpBusInvite: '/admin/ukp/bus/invite', // 商务
		ukpBusReInvite: '/admin/ukp/bus/reInvite', // 商务
		ukpBusListTabs: '/admin/ukp/bus/listTabs', // 商务
		ukpBmListTabs: '/admin/ukp/bm/listTabs', // 商务
		ukpOmListTabs: '/admin/ukp/om/listTabs', // 商务
		ukpBusInviteStar: '/admin/ukp/bus/inviteStar', // 商务
		ukpOmInviteCancel: '/admin/ukp/om/inviteCancel', // 商务
		ukpUnbind: '/admin/ukp/om/unbind', // 商务
		ukpOmChangeOwner: '/admin/ukp/om/changeOwner', // 商务
		ukpOmImportOptions: '/admin/ukp/om/importOptions', // 商务
		ukpOmImport: '/admin/ukp/om/import', // 商务
		ukpBmListExport: '/admin/ukp/bm/listExport', // 商务管理 导出
		ukpOmListExport: '/admin/ukp/om/listExport', // 商务
		ukpOmChangeResource: '/admin/ukp/om/changeResource', // 商务
		ukpPubCateOptions: '/admin/ukp/pub/cateOptions', // 商务
		ukpBusSubmitStar: '/admin/ukp/bus/submitStar', // 商务
		ukpBusSubmit: '/admin/ukp/bus/submit', // 商务
		ukpOmExportOptions: '/admin/ukp/om/exportOptions', // 商务
		ukpOmExport: '/admin/ukp/om/export', // 商务
		ukpOmSubmitAudit: '/admin/ukp/om/submitAudit', // 商务
		ukpOmAudit: '/admin/ukp/om/audit', // 商务
		ukpMatrixOptions: '/admin/ukp/pub/matrixOptions', //矩阵
		ukpCateEdit: '/admin/ukp/om/edit', //品类修改

		// 财务
		starSettleSpotCheckListTabs: '/admin/finance/starSettleSpotCheck/listTabs', // 财务
		starSettleSpotCheckList: '/admin/finance/starSettleSpotCheck/list', // 财务抽查
		starSettleCollate: '/admin/finance/starSettle/collate', // 财务确认核对
		starSettleSpotCheckCollate: '/admin/finance/starSettleSpotCheck/collate', // 财务抽查核对
		starSettleListTabs: '/admin/finance/starSettle/listTabs', // 财务抽查核对
		starSettleList: '/admin/finance/starSettle/list', // 财务抽查核对
		starSettleExport: '/admin/finance/starSettle/export', // 财务抽查核对
		starSettleImportOptions: '/admin/finance/starSettle/importOptions', // 财务抽查核对
		starSettleImport: '/admin/finance/starSettle/import', // 财务抽查核对
		starSettleShow: '/admin/finance/starSettle/show', // 财务抽查核对
		starSettleEdit: '/admin/finance/starSettle/edit', // 财务抽查核对

		starBillSpotCheckListTabs: '/admin/finance/starBillSpotCheck/listTabs', // 运营抽查tabs
		starBillSpotCheckList: '/admin/finance/starBillSpotCheck/list', // 运营抽查
		starBillSpotCheckCollate: '/admin/finance/starBillSpotCheck/collate', // 运营抽查核对
		starBillCollate: '/admin/finance/starBill/collate', // 运营确认核对
		pubStarLogs: '/admin/finance/pub/starLogs', // 运营确认核对
		pubProjectOptions: '/admin/finance/pub/projectOptions', // 运营确认核对
		starBillChargeOptions: '/admin/finance/starBill/chargeOptions', // 运营确认核对
		starBillListTabs: '/admin/finance/starBill/listTabs', // 运营确认核对
		starBillList: '/admin/finance/starBill/list', // 运营确认核对
		starBillImportOptions: '/admin/finance/starBill/importOptions', // 运营确认核对
		starBillImport: '/admin/finance/starBill/import', // 运营确认核对
		starBillSetOperator: '/admin/finance/starBill/setOperator', // 运营确认核对
		starBillShow: '/admin/finance/starBill/show', // 运营确认核对
		starBillEdit: '/admin/finance/starBill/edit', // 运营确认核对
		financePubBatchOptionst: '/admin/finance/pub/batchOptions', // 运营确认核对

		financeIncomeTypeList: '/admin/finance/incomeType/list', // 运营确认核对
		financeIncomeTypeEdit: '/admin/finance/incomeType/edit', // 运营确认核对
		financeIncomeTypeDel: '/admin/finance/incomeType/del', // 运营确认核对
		financeIncomeTypeAdd: '/admin/finance/incomeType/add', // 运营确认核对
		financeStarSettleListExport: '/admin/finance/starSettle/listExport', // 运营确认核对
		financeincomeTypeOptions: '/admin/finance/pub/incomeTypeOptions', // 运营确认核对

		// 商务
		starBillBusListTabs: '/admin/finance/starBillBus/listTabs', // 运营确认核对
		starBillBusList: '/admin/finance/starBillBus/list', // 运营确认核对
		// 主管
		starBillBmListTabs: '/admin/finance/starBillBm/listTabs', // 运营确认核对
		starBillBmList: '/admin/finance/starBillBm/list', // 运营确认核对
		updateAttachImgs: '/admin/finance/pub/updateAttachImgs', // 运营确认核对
		starBillDelete: '/admin/finance/starBill/delete', // 运营确认核对
		starSettleExportOptions: '/admin/finance/starSettle/exportOptions', // 运营确认核对

		// 淘宝直播-申诉
		tbzbpLiveContentListBd: '/admin/project/tbzbp/liveContentListBd', // 运营确认核对
		tbzbpLiveContentList: '/admin/project/tbzbp/liveContentList', // 运营确认核对
		tbzbpManageLiveContentList: '/admin/project/tbzbp/manageLiveContentList', // 运营确认核对
		tbzbpGetAppealOption: '/admin/project/tbzbp/getAppealOption', // 运营确认核对
		tbzbpApplyAppeal: '/admin/project/tbzbp/applyAppeal', // 运营确认核对
		tbzbpAppealAudit: '/admin/project/tbzbp/appealAudit', // 运营确认核对
		tbzbpExportLiveContentList: '/admin/project/tbzbp/exportLiveContentList', // 运营确认核对

		// 淘宝直播- 跟播
		tbliveLiveList: '/admin/project/tbzbp/tbliveLiveList', // 运营确认核对
		tbliveLiveInfo: '/admin/project/tbzbp/tbliveLiveInfo', // 运营确认核对

		// 达人档案
		starInfo: '/admin/star/info', // 运营确认核对
		starUpdate: '/admin/star/update', // 运营确认核对
		starCoolLogSelectList: '/admin/star/coolLogSelectList', // 运营确认核对
		starCoolLogs: '/admin/star/coolLogs', // 运营确认核对

		// 多平台
		multiBusPubProjectOptions: '/admin/multiBus/pub/projectOptions', // 运营确认核对
		multiBussalesList: '/admin/multiBus/sales/list', // 运营确认核对
		multiBusauditStarsList: '/admin/multiBus/auditStars/list', // 运营确认核对
		multiBusPubBusCateOptions: '/admin/multiBus/pub/busCateOptions', // 运营确认核对
		multiBusAuditStarsSubmit: '/admin/multiBus/auditStars/submit', // 运营确认核对
		multiBusAuditStarsLog: '/admin/multiBus/auditStars/log', // 运营确认核对
		multiBusAuditStarsCancel: '/admin/multiBus/auditStars/cancel', // 运营确认核对
		multiBusSalesGainOptions: '/admin/multiBus/sales/gainOptions', // 运营确认核对
		multiBusSalesGain: '/admin/multiBus/sales/gain', // 运营确认核对
		multiBusAuditStarsExportLink: '/admin/multiBus/auditStars/exportLink', // 运营确认核对
		multiBusAuditStarsImportResult: '/admin/multiBus/auditStars/importResult', // 运营确认核对
		// 交接管理
		busHandover: '/admin/busHandover/list',
		busHandoverSubmit: '/admin/busHandover/submit',
		// 系统
		sendCode: '/admin/auth/code', // 发送验证码s
		check: '/admin/auth/check', // 登录校验
		updatePass: '/admin/auth/updatePass', // 修改密码
		staffUnbind: '/admin/staff/unbind', // 解绑机器人
		login: '/admin/auth/login', // 登录
		codeLogin: '/admin/auth/codeLogin', // 验证码登录
		teamList: '/admin/team/list', // 团队列表
		forgetPass: '/admin/auth/forgetPass', // 团队列表
	},
}
