import { useCallback, useState } from 'react'

let __assign =
	(this && this.__assign) ||
	function() {
		__assign =
			Object.assign ||
			function(t) {
				for (let s, i = 1, n = arguments.length; i < n; i++) {
					s = arguments[i]
					for (let p in s) {
						if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p]
					}
				}

				return t
			}

		return __assign.apply(this, arguments)
	}

let __read =
	(this && this.__read) ||
	function(o, n) {
		let m = typeof Symbol === 'function' && o[Symbol.iterator]
		if (!m) return o
		let i = m.call(o),
			r,
			ar = [],
			e

		try {
			while ((n === void 0 || n-- > 0) && !(r = i.next()).done) {
				ar.push(r.value)
			}
		} catch (error) {
			e = {
				error: error,
			}
		} finally {
			try {
				if (r && !r.done && (m = i['return'])) m.call(i)
			} finally {
				if (e) throw e.error
			}
		}

		return ar
	}

let useSetState = function useSetState(initialState) {
	let _a = __read(useState(initialState), 2),
		state = _a[0],
		setState = _a[1]
	let setMergeState = useCallback(function(patch) {
		setState(function(prevState) {
			let newState = typeof patch === 'function' ? patch(prevState) : patch
			return newState ? __assign(__assign({}, prevState), newState) : prevState
		})
	}, [])
	// let setMergeState = function(patch) {
	// 	setTimeout(() => {
	// 		setState(function(prevState) {
	// 			let newState = typeof patch === 'function' ? patch(prevState) : patch
	// 			return newState
	// 				? __assign(__assign({}, prevState), newState)
	// 				: prevState
	// 		})
	// 	})
	// }

	return [state, setMergeState]
}

export default useSetState
