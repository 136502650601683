import { useState, useRef, useCallback, useEffect } from 'react'
const useStateBandCallback = initialValue => {
	const callbackRef = useRef(null)
	const [value, setValue] = useState(initialValue)
	const dataRef = useRef(initialValue)
	useEffect(() => {
		if (callbackRef.current) {
			callbackRef.current(value)
		}
		dataRef.current = value
	}, [value])
	const setValueBandCallback = useCallback((newValue, callback) => {
		let data = typeof newValue == 'function' ? newValue() : newValue
		callbackRef.current = callback
		dataRef.current = data
		return setValue(data)
	}, [])
	const getValue = _ => dataRef.current
	return [value, setValueBandCallback, getValue]
}
export default useStateBandCallback
