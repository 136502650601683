import { useLayoutEffect, MutableRefObject } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import useRafState from './useRafState'
import { getTargetElement, createEffectWithTarget } from './utils'
// const _ResizeObserver = window.ResizeObserver
const useEffectWithTarget = createEffectWithTarget(useLayoutEffect)

type Size = { width: number; height: number }
type TargetValue<T> = T | undefined | null
type TargetType = HTMLElement | Element | Window | Document
export type BasicTarget<T extends TargetType = Element> =
	| (() => TargetValue<T>)
	| TargetValue<T>
	| MutableRefObject<TargetValue<T>>
function useSize(target: BasicTarget): Size | undefined {
	const [state, setState] = useRafState(() => {
		const el = getTargetElement(target)
		return el ? { width: el.clientWidth, height: el.clientHeight } : undefined
	})

	useEffectWithTarget(
		() => {
			const el = getTargetElement(target)
			if (!el) {
				return
			}

			const resizeObserver = new ResizeObserver(entries => {
				entries.forEach(entry => {
					const { clientWidth, clientHeight } = entry.target
					setState({ width: clientWidth, height: clientHeight })
				})
			})
			resizeObserver.observe(el)
			return () => {
				resizeObserver.disconnect()
			}
		},
		[],
		target
	)

	return state
}

export default useSize
