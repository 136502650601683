/**
 * @module 处理各种数据的函数和过滤器
 */

import { message } from 'antd'
import copy from 'copy-to-clipboard'

/**
 * @function                   copy
 * @param {string|number} text 需要copy的数据
 * @param {string}        str  copy成功之后的提示语
 */
export const copyTextFun = (text: string, str: string = '复制成功') => {
	copy(text)
	message.success(str)
}

/**
 * @function      处理大数字
 * @param {number} num
 * @returns
 */
export const numWanCode = (num: number) => {
	let param = <{ num: string | number; uit: string }>{}
	let k = 10000,
		size = ['', '万', '亿', '万亿'],
		i: number
	if (num < k) {
		param.num = num
		param.uit = ''
	} else {
		i = Math.floor(Math.log(num) / Math.log(k))
		param.num = (num / Math.pow(k, i)).toFixed(2)
		param.uit = size[i]
	}
	let newNnum = param.num + param.uit
	if (newNnum == 'NaNundefined') return 0
	return newNnum
}

/**
 * @function  isArr       判断数组函数
 * @param {Array} arr     需要判断的数组
 * @returns {boolean}     是否为数组
 */
export const isArr = (arr: string | any[]): boolean => {
	return !!(Array.isArray(arr) && arr.length && arr.length > 0)
}
// 深拷贝
export const cloneDeep = (target: object | null, hash = new WeakMap()) => {
	// 对于传入参数处理
	if (typeof target !== 'object' || target === null) {
		return target
	}
	// 哈希表中存在直接返回
	if (hash.has(target)) return hash.get(target)
	const cloneTarget = Array.isArray(target) ? [] : {}
	hash.set(target, cloneTarget)
	// 针对Symbol属性
	const symKeys = Object.getOwnPropertySymbols(target)
	if (symKeys.length) {
		symKeys.forEach(symKey => {
			if (typeof target[symKey] === 'object' && !!target[symKey]) {
				cloneTarget[symKey] = cloneDeep(target[symKey])
			} else {
				cloneTarget[symKey] = target[symKey]
			}
		})
	}
	for (const i in target) {
		if (Object.prototype.hasOwnProperty.call(target, i)) {
			cloneTarget[i] =
				typeof target[i] === 'object' && target[i] !== null
					? cloneDeep(target[i], hash)
					: target[i]
		}
	}
	return cloneTarget
}
// 算出字数
export function strlen(str: string) {
	var len = 0
	for (var i = 0; i < str.length; i++) {
		var c = str.charCodeAt(i)
		//单字节加1
		if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
			len++
		} else {
			len += 2
		}
	}
	return len
}

// 发起推送消息()
export const pubCustomEvent = (detail = {}, name = 'download') => {
	let ele = new CustomEvent(name, { detail })
	dispatchEvent(ele)
}
