/*
 * 时间戳转换为页面显示形式
 */

/*
 *时间戳转时间格式
 *返回年月日时分秒
 */
function timepaDate(val: string) {
	const date = new Date(Date.parse(val))
	const y = date.getFullYear()
	let m: string | number = date.getMonth() + 1
	m = m < 10 ? `0${m}` : m
	let d: string | number = date.getDate()
	d = d < 10 ? `0${d}` : d
	const h = date.getHours()
	let minute: string | number = date.getMinutes()
	let second: string | number = date.getSeconds()
	minute = minute < 10 ? `0${minute}` : minute
	second = second < 10 ? `0${second}` : second
	return `${y}-${m}-${d} ${h}:${minute}:${second}`
}

/*
 *时间戳转时间格式
 *返回年月日
 */

function time(val, type = false) {
	const date = new Date(val * 1000)
	const y = date.getFullYear()
	let m: string | number = date.getMonth() + 1
	m = m < 10 ? `0${m}` : m
	let d: string | number = date.getDate()
	d = d < 10 ? `0${d}` : d
	let value = `${y}-${m}-${d}`
	if (type) {
		value = `${y}${m}${d}`
	}
	return value
}
// 获取当前年月日
function getNewDate() {
	var nowDate = new Date()
	var year = nowDate.getFullYear()
	var month =
		nowDate.getMonth() + 1 < 10
			? '0' + (nowDate.getMonth() + 1)
			: nowDate.getMonth() + 1
	var day = nowDate.getDate() < 10 ? '0' + nowDate.getDate() : nowDate.getDate()
	return year + '-' + month + '-' + day
}
// 获取当前年月
function getNewMonth() {
	var nowDate = new Date()
	var year = nowDate.getFullYear()
	var month =
		nowDate.getMonth() + 1 < 10
			? '0' + (nowDate.getMonth() + 1)
			: nowDate.getMonth() + 1
	return year + '-' + month
}
/*
 *时间戳转时间格式
 *返回年月日时分秒
 */
function date(val) {
	const date = new Date(val * 1000)
	const y = date.getFullYear()
	let m: string | number = date.getMonth() + 1
	m = m < 10 ? `0${m}` : m
	let d: string | number = date.getDate()
	d = d < 10 ? `0${d}` : d
	const h = date.getHours()
	let minute: string | number = date.getMinutes()
	let second: string | number = date.getSeconds()
	minute = minute < 10 ? `0${minute}` : minute
	second = second < 10 ? `0${second}` : second
	return `${y}-${m}-${d} ${h}:${minute}:${second}`
}
/*
 *时间戳转时间格式
 *返回年月日时分
 */
function date2(val) {
	const date = new Date(val * 1000)
	const y = date.getFullYear()
	let m: string | number = date.getMonth() + 1
	m = m < 10 ? `0${m}` : m
	let d: string | number = date.getDate()
	d = d < 10 ? `0${d}` : d
	const h = date.getHours()
	let minute: string | number = date.getMinutes()
	minute = minute < 10 ? `0${minute}` : minute
	return `${y}-${m}-${d} ${h}:${minute}`
}

// 返回时分
function getHourse(val) {
	const date = new Date(val * 1000)
	let m: string | number = date.getMonth() + 1
	m = m < 10 ? `0${m}` : m
	let d: string | number = date.getDate()
	d = d < 10 ? `0${d}` : d
	const h = date.getHours()
	let minute: string | number = date.getMinutes()
	minute = minute < 10 ? `0${minute}` : minute
	return `${h}:${minute}`
}
/*
 *时间戳转时间格式
 *返回年
 */
function year(val) {
	const date = new Date(val * 1000)
	const y = date.getFullYear()
	return y
}
/*
 *时间戳转时间格式
 *返回月
 */
function month(val) {
	const date = new Date(val * 1000)
	let m: string | number = date.getMonth() + 1
	m = m < 10 ? `0${m}` : m
	return m
}
/*
 *时间戳转时间格式
 *返回月日
 */
function monthDay(val) {
	const date = new Date(val * 1000)
	let m: string | number = date.getMonth() + 1
	m = m < 10 ? `0${m}` : m
	let d: string | number = date.getDate()
	d = d < 10 ? `0${d}` : d
	return `${m}月${d}日`
}

// /*
//  * 剩余天数
//  * 返回日
//  *
//  */
// function getRemainingDay(val:any) {
// 	const today = new Date()
// 	const todayTime= today.getTime()
// 	return parseInt((val - todayTime / 1000) / 24 / 60 / 60)
// }

/*
 *时间戳转时间格式
 *返回日
 */
function day(val) {
	const date = new Date(val * 1000)
	let d: string | number = date.getDate()
	d = d < 10 ? `0${d}` : d
	return d
}
// 时间格式转时间戳
function timeStamp(val) {
	val = val.split('-')
	val = val.join('/')

	let date = new Date(`${val} 00:00:00`)
	const time1 = date.getTime()
	return time1
}
// 时间格式转时间戳日期
function DataStamp(val) {
	const date = new Date(val)
	const time1 = date.getTime()
	return time1 / 1000
}
// 友好提示

// function dateStr(date) {
// 	// 获取js 时间戳
// 	let time = new Date().getTime()
// 	// 去掉 js 时间戳后三位，与php 时间戳保持一致
// 	time= parseInt((time - date * 1000) / 1000)

// 	// 存储转换值
// 	let s
// 	if (time < 60 * 10) {
// 		// 十分钟内
// 		return '刚刚'
// 	}
// 	if (time < 60 * 60 && time >= 60 * 10) {
// 		// 超过十分钟少于1小时
// 		s = Math.floor(time / 60)
// 		return `${s}分钟前`
// 	}
// 	if (time < 60 * 60 * 24 && time >= 60 * 60) {
// 		// 超过1小时少于24小时
// 		s = Math.floor(time / 60 / 60)
// 		return `${s}小时前`
// 	}
// 	if (time < 60 * 60 * 24 * 3 && time >= 60 * 60 * 24) {
// 		// 超过1天少于3天内
// 		s = Math.floor(time / 60 / 60 / 24)
// 		return `${s}天前`
// 	}
// 	// 超过3天
// 	date = new Date(parseInt(date) * 1000)
// 	return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
// }
// age
function getAge(time) {
	const birthDay = time * 1000
	const now = new Date().getTime()
	let hours = (now - birthDay) / 1000 / 60 / 60
	const year = Math.floor(hours / (24 * 30 * 12))
	hours %= 24 * 30 * 12
	const months = Math.floor(hours / (24 * 30))
	hours %= 24 * 30
	const days = Math.floor(hours / 24)
	return year
}

// week
function getWeek(val) {
	// let week;
	const date = new Date(val * 1000)
	let weekOBJ = {
		0: '周日',
		1: '周一',
		2: '周二',
		3: '周三',
		4: '周四',
		5: '周五',
		6: '周六',
	}
	// if (date.getDay() == 0) week = "周日";
	// if (date.getDay() == 1) week = "周一";
	// if (date.getDay() == 2) week = "周二";
	// if (date.getDay() == 3) week = "周三";
	// if (date.getDay() == 4) week = "周四";
	// if (date.getDay() == 5) week = "周五";
	// if (date.getDay() == 6) week = "周六";
	// return week;
	return weekOBJ[date.getDate()]
}

// 制保留2位小数
function toDecimal2(x) {
	let f = parseFloat(x)
	if (Number.isNaN(f)) {
		return false
	}
	f = Math.round(x * 100) / 100
	let s = f.toString()
	let rs = s.indexOf('.')
	if (rs < 0) {
		rs = s.length
		s += '.'
	}
	while (s.length <= rs + 2) {
		s += '0'
	}
	return s
}

// function getCurrentMonthLast(date) {
// 	var endDate = new Date(date) //date 是需要传递的时间如：2018-08
// 	var month = endDate.getMonth()
// 	var nextMonth = ++month
// 	var nextMonthFirstDay = new Date(endDate.getFullYear(), nextMonth, 1)
// 	var oneDay = 1000 * 60 * 60 * 24
// 	var dateString = new Date(nextMonthFirstDay - oneDay)
// 	return dateString
// 		.toLocaleDateString()
// 		.split('/')
// 		.join('-') //toLocaleDateString() 返回 如：2018/8/31
// }

const store = {
	getTime: time,
	getStamp: timeStamp,
	getDate: date,
	getDate2: date2,
	getyear: year,
	getmonth: month,
	getMonthDay: monthDay,
	getday: day,
	// getRemainingDay,
	// dateStr,
	getAge,
	getWeek,
	getDataStamp: DataStamp,
	getHourse,
	timepaDate,
	getNewDate,
	getNewMonth,
	// getCurrentMonthLast,
}

export default store
