/**
 * @module 处理icon相关的函数
 */

import React from 'react'
import { IconFont } from '@/config/settting'
import grilsex from '@/static/assets/grilsex.svg'
import boysex from '@/static/assets/boysex.svg'
import shuang from '@/static/assets/shuang.svg'

export const getPassIcon = (e: string | number, style = {}) => {
	return <IconFont type={`iconp${e}`} style={style} />
}

export const getSexIcon = (e: number) => {
	return e === 1 ? boysex : e === 2 ? grilsex : shuang
}
