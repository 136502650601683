import React, { Suspense, useMemo } from 'react'
import { Route, Switch } from 'react-router-dom'
// import LoadingPage from '@/components/system/Loading'
import { getStore } from '@/utils/store'
import RouterTab from '@/components/system/routerTab'
import NoPage from '@/pages/404.js'

const RouteDom = ({ onUpdate = () => {} }) => {
	// 渲染路由
	const renderRouterDom = useMemo(() => {
		let RouterConfig = JSON.parse(getStore('routerData') || JSON.stringify([]))
		// 路由递归渲染
		const renderRoute = (data = []) => {
			let ds = []
			data.forEach(item => {
				if (Number(item.type) !== 3) {
					let renderDom = {}
					if (item.children && item.children.length !== 0) {
						renderDom = {
							render: () => {
								return <Switch>{renderRoute(item.children, item.path)}</Switch>
							},
						}
						ds.push(<Route key={item.path} path={item.path} {...renderDom} />)
					} else {
						let componentUrl = item.component
						const Componentsds = React.lazy(() =>
							import(`@/pages${componentUrl}`)
						)
						const consss = () => <Componentsds onUpdate={onUpdate} />
						ds.push(
							<Route key={item.path} path={item.path} component={consss} />
						)
					}
				}
			})
			return ds
		}
		const dom = renderRoute(RouterConfig)
		return dom
	}, [])

	return (
		<>
			{/* <Suspense fallback={<LoadingPage type='layout' />}> */}
			<RouterTab onUpdate={onUpdate} />
			{/* {renderRouterDom} */}
			{/* <Route path='/home/404' component={NoPage} /> */}
			{/* </Suspense> */}
		</>
	)
}

export default RouteDom
