import { useRef, useState, useCallback } from 'react'
import read from '../utils'
function depsAreSame(old, deps) {
	if (old === deps) return true
	for (var i = 0; i < old.length; i++) {
		if (!Object.is(old[i], deps[i])) return false
	}
	return true
}
function useCreation(factory, deps) {
	var current = useRef({
		deps: deps,
		obj: undefined,
		initialized: false,
	}).current

	if (current.initialized === false || !depsAreSame(current.deps, deps)) {
		current.deps = deps
		current.obj = factory()
		current.initialized = true
	}

	return current.obj
}

// 用于数据的更新
var useUpdate = function useUpdate() {
	var _a = read(useState({}), 2),
		setState = _a[1]

	return useCallback(function() {
		return setState({})
	}, [])
}
var proxyMap = new WeakMap() // k:v 代理过的对象:原对象
var rawMap = new WeakMap()
function isObject(val) {
	return typeof val === 'object' && val !== null
}
function observer(initialVal, cb) {
	var existingProxy = proxyMap.get(initialVal) // 添加缓存 防止重新构建proxy

	if (existingProxy) {
		return existingProxy
	}
	// 防止代理已经代理过的对象
	if (rawMap.has(initialVal)) {
		return initialVal
	}

	var proxy = new Proxy(initialVal, {
		get: function get(target, key, receiver) {
			var res = Reflect.get(target, key, receiver)
			return isObject(res) ? observer(res, cb) : Reflect.get(target, key)
		},
		set: function set(target, key, val) {
			var ret = Reflect.set(target, key, val)
			cb()
			return ret
		},
		deleteProperty: function deleteProperty(target, key) {
			var ret = Reflect.deleteProperty(target, key)
			cb()
			return ret
		},
	})
	proxyMap.set(initialVal, proxy)
	rawMap.set(proxy, initialVal)
	return proxy
}

function useReactive(initialState) {
	var update = useUpdate()
	var stateRef = useRef(initialState)
	var state = useCreation(function() {
		return observer(stateRef.current, function() {
			update()
		})
	}, [])
	return state
}

export default useReactive
