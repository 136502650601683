/**
 * @module 检测浏览器版本
 */

import React, { useEffect, useState } from 'react'
import { Modal, Popover } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
const urls = 'https://www.cyek.com/tools/cyektools/'
const qqWindow = 'https://dldir1.qq.com/invc/tt/QQBrowser_Setup_qb11.exe'
const qqMacIntel =
	'https://dldir1v6.qq.com/invc/tt/QB/Public/pcUpgrade/QQBrowser_intel_2023_06_30_135354.dmg'
const getExplore = () => {
	var Sys: any = {}
	var ua = navigator.userAgent.toLowerCase()
	var s
	;(s = ua.match(/rv:([\d.]+)\) like gecko/))
		? (Sys.ie = s[1])
		: (s = ua.match(/msie ([\d\.]+)/))
		? (Sys.ie = s[1])
		: (s = ua.match(/edge\/([\d\.]+)/))
		? (Sys.edge = s[1])
		: (s = ua.match(/firefox\/([\d\.]+)/))
		? (Sys.firefox = s[1])
		: (s = ua.match(/(?:opera|opr).([\d\.]+)/))
		? (Sys.opera = s[1])
		: (s = ua.match(/chrome\/([\d\.]+)/))
		? (Sys.chrome = s[1])
		: (s = ua.match(/version\/([\d\.]+).*safari/))
		? (Sys.safari = s[1])
		: 0
	if (Sys.ie) return ['IE', Sys.ie]
	if (Sys.edge) return ['EDGE', Sys.edge]
	if (Sys.firefox) return ['Firefox', Sys.firefox]
	if (Sys.chrome) return ['Chrome', Sys.chrome]
	if (Sys.opera) return ['Opera', Sys.opera]
	if (Sys.safari) return ['Safari', Sys.safari]
	return 'Unkonwn'
}

const browsers = () => {
	const os = navigator.userAgent
	if (~os.indexOf('Window')) {
		// 如果发现 qq 浏览器 下载链接替换成 qq 浏览器下载链接
		if (~os.indexOf('QQBrowser')) return qqWindow
		if (~os.indexOf('Chrome')) return `${urls}ChromeSetup.exe`
	} else if (~os.indexOf('Mac OS X')) {
		if (~os.indexOf('QQBrowser')) return qqMacIntel
		if (~os.indexOf('Chrome')) return `${urls}googlechrome2.dmg`
	}
}

const getOs = () => {
	const os = navigator.userAgent
	if (~os.indexOf('Window')) return 'windows'
	if (~os.indexOf('Mac OS X')) return 'mac'
	if (~os.indexOf('Linux')) return 'linux'
}
const os = getOs()
const CheckVersion = () => {
	const [visible, setVisible] = useState(false)
	useEffect(() => {
		try {
			const [type, version] = getExplore()
			if (!!version) {
				const versionNum = Number(version.substring(0, version.indexOf('.')))
				if (type === 'Chrome' && versionNum < 94) {
					setVisible(true)
				}
			}
		} catch (error) {
			console.log('检测版本失败', error)
		}
	}, [])

	const modalProps = {
		title: <span style={{ color: 'red' }}>通知 ！！</span>,
		visible,
		footer: null,
		onCancel: () => setVisible(false),
	}
	return (
		<>
			{visible && (
				<Modal {...modalProps}>
					<div>
						<span style={{ color: 'red' }}>
							你的浏览器版本过低，请立即下载新版本浏览器
						</span>
						{os !== 'linux' ? (
							<>
								<a
									style={{ display: 'inline-block', marginLeft: 8 }}
									target='_blank'
									href={browsers()}
									download
								>
									点击下载最新版本{' '}
								</a>
								{os === 'mac' && (
									<Popover content='（当前下载版本为 Intel芯片版 如当前操作系统为 Apple 芯片版请另行下载）'>
										<QuestionCircleOutlined style={{ marginLeft: 4 }} />
									</Popover>
								)}
							</>
						) : (
							<span>暂时不支持 linux 系统</span>
						)}
					</div>
				</Modal>
			)}
		</>
	)
}

export default CheckVersion
