/**
 * @module 下载队列
 */
import React, { useState, useEffect, useRef } from 'react'
import { Spin, message, Modal, Popover, Empty, Pagination } from 'antd'
import { DownOutlined, UpOutlined, LoadingOutlined } from '@ant-design/icons'
import { req } from '@/utils/request'
import { useUpdateEeffect } from '@/hooks'
import { IconFont } from '@/config/settting'
import { isArr } from '@/utils/tools'
import { getStore } from '@/utils/store'
import './index.less'
const { confirm } = Modal
const initParmas = {
	p: 1,
	num: 10,
}

export default _ => {
	// const scrollRef = useRef()
	const [loading, setLoading] = useState(false)
	const [visible, setVisible] = useState(false)
	const [dataList, setDataList] = useState([])
	const [count, setCount] = useState(0)
	const [total, setTotal] = useState(0)
	const [params, setParams] = useState(initParmas)
	useEffect(() => {
		let localData = JSON.parse(getStore('userInfo'))
		setCount(localData?.current_task ?? 0)
		setTotal(localData?.total_task ?? 0)
		window.addEventListener('download', downloadChange)
		return () => {
			window.removeEventListener('download', () => {})
			setVisible(false)
		}
	}, [])
	useUpdateEeffect(() => {
		reqExportTaskList()
	}, [params])
	// 获取数据
	const reqExportTaskList = () => {
		setLoading(true)
		req('exportTaskList', params, (res, isFlag, msg) => {
			if (isFlag) {
				const { result } = res
				setDataList(result?.data ?? [])
				setTotal(result?.total)
				setCount(result?.current)
				// if (isArr(dataList) && dataList.length == result.total) {
				// 	setHasMore(false)
				// }
				setLoading(false)
			} else {
				message.error(msg)
				setLoading(false)
			}
		})
	}
	// 监听发送事件
	const downloadChange = e => {
		const { detail } = e
		// 发送的数据储存
		if (isArr(Object.keys(detail))) {
			setTotal(detail?.d?.total ?? 0)
			setCount(detail?.d?.current ?? 0)
			setParams({ p: 1, num: 10 })
			// setVisible(true)
		} else {
			setParams({ p: 1, num: 10 })
			// todo 获取不到最新的total 无法进行自增操作 只能重新调用接口
			// let cp = JSON.parse(JSON.stringify(total))
			// cp = cp + 1
			// setTotal(cp)
		}
	}
	// 打开
	const openClick = () => {
		reqExportTaskList()
		setVisible(true)
	}
	// 删除
	const delClick = data => {
		// 请求
		const requestExportDelTask = () => {
			req('exportDelTask', { task_id: data?.id }, (res, isFlag, msg) => {
				if (isFlag) {
					message.success('删除成功')
					// let cp = JSON.parse(JSON.stringify(dataList))
					// cp.splice(
					// 	cp.findIndex(v => v.id === data.id),
					// 	1
					// )
					// setDataList(cp)
					reqExportTaskList()
					setVisible(true)
				} else {
					message.error(msg)
				}
			})
		}
		if (data?.state == 0) {
			confirm({
				title: `是否删除缓存任务${data.title}？`,
				content: '此操作不可逆，请谨慎操作',
				zIndex: 9999,
				onOk: () => {
					requestExportDelTask()
				},
			})
		} else {
			requestExportDelTask()
		}
	}
	// // 监听滚动
	// const popScrollCapture = () => {
	// 	const scrollTop = scrollRef.current.scrollTop
	// 	const clientHeight = scrollRef.current.clientHeight
	// 	const scrollHeight = scrollRef.current.scrollHeight
	// 	const isBottom = scrollTop + clientHeight == scrollHeight
	// 	if (isBottom && hasMore) {
	// 		let CpParams = JSON.parse(JSON.stringify(params))
	// 		CpParams.p++
	// 		setParams(CpParams)
	// 	}
	// }
	// 渲染 状态
	const renderTableStatus = data => {
		let STATUSOBJ = {
			// 0 = 导出中 1 = 成功 2 = 失败
			0: (
				<>
					<Spin
						style={{ width: '40%' }}
						indicator={<LoadingOutlined style={{ fontSize: 10 }} spin />}
					/>
					<span style={{ marginTop: 4, width: '60%' }}> 导出中...</span>
				</>
			),
			// 成功
			2: (
				<>
					<IconFont type='iconerror1' />
					<span style={{ marginLeft: 8, color: 'rgb(235, 84, 81)' }}>失败</span>
				</>
			),
			// 失败
			1: (
				<>
					<IconFont type='iconcolor-success' />
					<span style={{ marginLeft: 8, color: 'rgb(7, 193, 96)' }}>成功</span>
				</>
			),
			// 导入
			4: (
				<>
					<Spin
						style={{ width: '40%' }}
						indicator={<LoadingOutlined style={{ fontSize: 10 }} spin />}
					/>
					<span style={{ marginTop: 4, width: '60%' }}> 导入中...</span>
				</>
			),
		}
		return STATUSOBJ[data.state] && STATUSOBJ[data.state]
	}
	// 下载
	const downloadClick = data => {
		let link = document.createElement('a')
		link.href = data?.link
		link.download = `${data?.title}导出`
		link.click()
	}
	// 渲染 列表数据
	const renderTable = () => {
		return (
			<>
				<div
					// ref={scrollRef}
					// onScrollCapture={popScrollCapture}
					style={{
						// height: '300px',
						maxHeight: 300,
						overflow: 'hidden',
						overflowY: 'auto',
						width: 740,
					}}
				>
					<Spin spinning={loading}>
						{isArr(dataList) ? (
							dataList.map((item, index) => {
								return (
									<div
										className='table_box'
										key={index}
										style={{
											borderTop: index == 0 ? '1px solid #e8e8e8' : '',
										}}
									>
										<div className='t_b_item_1' onClick={() => delClick(item)}>
											<IconFont type='iconcolor-error' />
										</div>
										<div className='t_b_item_2'>{item.id}</div>
										<div className='t_b_item_3'>{item.title}</div>
										<div className='t_b_item_4'>{item.add_time}</div>
										<div className='t_b_item_5'>{renderTableStatus(item)}</div>
										<div className='t_b_item_6'>
											{item.state != 0 &&
												(!!!item.reason && !!item.link ? (
													<span
														style={{ color: '#1890ff' }}
														onClick={() => downloadClick(item)}
													>
														下载
													</span>
												) : (
													<Popover placement='right' content={item.reason}>
														失败原因
													</Popover>
												))}
										</div>
									</div>
								)
							})
						) : (
							<Empty
								image={Empty.PRESENTED_IMAGE_SIMPLE}
								// description='去导出哦~~~'
							/>
						)}
					</Spin>
				</div>

				<Pagination
					size='small'
					onChange={(e, size) => setParams({ num: size, p: e })}
					// onShowSizeChange={e => console.log('e', e)}
					pageSize={params.num}
					current={params.p}
					total={total}
					showSizeChanger
					showQuickJumper
					style={{
						marginTop: 16,
						display: 'flex',
						justifyContent: 'flex-end',
					}}
				/>
			</>
		)
	}
	return (
		<>
			{/* 外层 */}
			<div className='down_box'>
				<Popover
					trigger='click'
					open={visible}
					content={renderTable()}
					placement='bottom'
					overlayStyle={{
						width: 750,
					}}
					onOpenChange={e => {
						setVisible(e)
						// !e && setDataList([])
					}}
				>
					<span
						className='d_b_exothecium dark_card_bg dark_font_white'
						onClick={openClick}
					>
						缓存任务 {count}/{total}{' '}
						{!visible ? (
							<DownOutlined style={{ fontSize: 10 }} />
						) : (
							<UpOutlined style={{ fontSize: 10 }} />
						)}
					</span>
				</Popover>
			</div>
		</>
	)
}
