import { Modal, Button } from 'antd'
import { copyTextFun } from '@/utils/tools'
import { useState } from 'react'
import { IconFont } from '@/config/settting'
import './index.less'

const Index = ({ data }) => {
	const [visible, setVisible] = useState(false)
	return (
		<>
			<div
				className='robootAll_page'
				id='robot_modal_header'
				onClick={() => setVisible(true)}
			>
				<IconFont type='icona-jiqirenzhineng-04' style={{ fontSize: 16 }} />
			</div>
			<Modal
				title='绑定机器人'
				footer={null}
				visible={visible}
				width={295}
				onCancel={() => setVisible(false)}
			>
				<div
					style={{
						display: 'flex',
						alginItems: 'center',
						alignItems: 'center',
						justifyContent: 'center',
						flexDirection: 'column',
					}}
				>
					<img
						src={data.robot_url}
						className='dark_card_content_white'
						style={{ width: 160, height: 160, border: '1px solid #fff' }}
					/>
					<div style={{ color: '#ccc', fontSize: 12, margin: '10px 0' }}>
						扫上边的二维码，加我微信
					</div>
					<div
						className='unselectable'
						onClick={() => copyTextFun(data.robot_code)}
					>
						邀请码：{data.robot_code}
					</div>
					<div>
						请
						<Button
							type='link'
							style={{ padding: 5 }}
							onClick={() => copyTextFun(data.robot_code)}
						>
							复制
						</Button>
						后发给机器人
					</div>
				</div>
			</Modal>
		</>
	)
}

export default Index
