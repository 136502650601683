/**
 * @module 全局响应式需要做的处理
 */

import { useState, useEffect } from 'react'

export default () => {
	const useWindowSize = () => {
		const [size, setSize] = useState(window.innerHeight)
		useEffect(() => {
			const handler = e => {
				setSize(e?.target?.innerHeight)
			}
			window.addEventListener('resize', handler)
			return () => {
				window.removeEventListener('resize', handler)
			}
		}, [])
		return size
	}
	return useWindowSize()
}
